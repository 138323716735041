import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PlayEarn from '../views/PlayEarn.vue'
import MarKeting from '../views/MarKeting.vue'
import CoinView from '../views/CoinView.vue'
import EnterPrise from '../views/EnterPrise.vue'
import IcoPage from '../views/IcoPage.vue'
import SmartContract from '../views/SmartContract.vue'
import CryptoExchange from '../views/CryptoExchange.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermCondition from '../views/TermCondition.vue'
import DappPage from '../views/DappPage.vue'
import GalleryPage from '../views/GalleryPage.vue'
import AboutUs from '../views/AboutUs.vue'
import IoT from '../views/IoT.vue'
import TokenPage from '../views/TokenPage.vue'
import ContactUs from '../views/ContactUs.vue'
import NFTView from '../views/NFTView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/playearn',
    name: 'playearn',
    component: PlayEarn
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: MarKeting
  },
  {
    path: '/coin',
    name: 'coin',
    component:CoinView
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component:EnterPrise
  },
  {
    path: '/IcoPage',
    name: 'IcoPage',
    component:IcoPage
  },
  {
    path: '/SmartContract',
    name: 'SmartContract',
    component:SmartContract
  },
  {
    path: '/CryptoExchange',
    name: 'CryptoExchange',
    component:CryptoExchange
  },
 
  {
    path: '/DappPage',
    name: 'DappPage',
    component: DappPage
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component:PrivacyPolicy
  },
  {
    path: '/terms',
    name: 'terms',
    component:TermCondition
  },
  {
    path: '/gallery',
    name: 'gallery',
    component:GalleryPage
  },
 
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component:AboutUs
  },
  {
    path: '/IoT',
    name: 'IoT',
    component:IoT
  },
  {
    path: '/token',
    name: 'token',
    component:TokenPage
  },
  {
    path: '/contact',
    name: 'contact',
    component:ContactUs
  },
  {
    path: '/NFTView',
    name: 'NFTView',
    component:NFTView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
},
})

export default router
