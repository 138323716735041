<template>
  <div>
    <div class="heading_text">
      <h1 class="text-white">{{ text1 }} <span class="blue_color">{{ text2 }}</span></h1>
      <p class="fs-5 para fw-semibold Orbitron text-white my-5">{{ para_line }}</p>
      <div class="text_center">
        <a href="javascript:void(0)" class="main_button_c fw-semibold"><span class="Orbitron">{{ btn }}</span></a>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'PlayEarnHeading',
  props: {
    text1: String,
    text2: String,
    para_line: String,
    btn: String
  }
}
</script>

  <style scoped>
  .heading_text h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 52px !important;
    line-height: 140%;
    letter-spacing: 0.045em;
  }
  
  p.para {
    white-space: nowrap;
  }
  
  
  @media(min-width:320px) and (max-width: 480px) {
    .heading_text h1 {
      font-size: 34px !important;
    }
  }
  
  @media(min-width:481px) and (max-width: 767px) {
    .heading_text h1 {
      font-size: 40px !important;
    }
  }
  @media(min-width:768px) and (max-width: 991px) {
    .heading_text h1 {
      font-size: 30px !important;
    }
  }
  @media(min-width:992px) and (max-width: 1199px) {
    .heading_text h1 {
      font-size: 43px !important;
    }
  }
  
  </style>