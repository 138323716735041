<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(prise, index) in prise_data" :key="index">
          <div class="prise_slide">
     
        <div class="text-center prise_head my-3">
          <span class="fs-5 text-white fw-bold">{{prise.head}}</span>
       
        </div>
        <div class="text-center"><img :src="require('@/assets/IoT/'+prise.img+'.png')" alt="" class="img-fluid aaa"></div>
        <div>
          <p class="text-white mt-3 text-center">{{prise.para}}</p>
        </div>
        </div>
      </swiper-slide>
    </swiper>

  
  </template>
  <style scoped >
  .prise_slide:hover{
      background-color: #191d25;
      border-bottom: 4px solid #0964F3;
      padding: 45px 10px;
  
  }
  .prise_slide{
      border-bottom: 4px solid transparent;
      height: 517px;
      padding: 45px 10px;
  }
  .prise_head p{
      font-size:14px;
  }
  </style>
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  
  
  import "swiper/css/free-mode";
  import "swiper/css/pagination";
  
  
  
  // import required modules
  import {Autoplay, FreeMode, Pagination, Navigation } from "swiper";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, FreeMode, Pagination, Navigation],
      };
    },
    data(){
              return{
                  prise_data:[
                      {
                          img:"1",
                          head:"Blockchain- Supply Chain",
                          para:"Deliver consistent and relevant information to everyone in your supply chain with  blockchain-based supply chain security. It is  a low-cost system for providing better  service quality."
                      },
                      {
                          img:"2",
                          head:"Blockchain Retail",
                          para:"Blockchain technology enables smooth and error-free operations of your retail platform, from payment management to automated system creation. Our advanced system also allows you to keep track of your data."
                      },
                      {
                          img:"3",
                          head:"Blockchain Banking and Fintech",
                          para:"Blockchain technology is based on a distributed and immutable public ledger which makes all financial transactions safe, secure, and transparent. Blockchain technology makes business operations efficient in a whole new secure ecosystem."
                      },
                      {
                          img:"4",
                          head:"Biometrics Authentication",
                          para:"Solana Network helps you manage your home better than ever. Secure your biometric identity with an encrypted blockchain ecosystem."
                      },



                      {
                          img:"5",
                          head:"IoT in Automotive Industry",
                          para:"The automotive industry is the weakest when it comes to complex databases. A system crash means you sell your business to someone. Secure your auto business enterprise with the IOT-Blockchain ecosystem."
                      },
                      
                      
                  ]
              }
    }
  };
  </script>
  