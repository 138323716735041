<template>
  <router-view/>
</template>
<script>
</script>
<style>
#app {
 font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Orbitron', sans-serif !important;
}
h1{
  font-size: 50px !important;
}

html, body{
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none !important;
}
li{
  list-style: none;
}
ul{
  padding: 0 !important;
  margin: 0 !important;
}
.main_head{
  z-index: 99;
}
body{
  background-color: #0E0C0C !important;
}
header.fade_in {
    background: #262222eb!important;
    top:0;
    position: fixed !important;
    z-index: 99;
}

 section.about .swiper-slide p {

    font-size: 12px;
}
section.enterprise_block .first_slider .swiper-horizontal>.swiper-pagination-bullets{
  display: none;
}
section.about .swiper-slide.swiper-slide-active p{
  display: block;
  font-size: 12px !important;
}
section.about .swiper-slide img.icon_hover {
    display: none !important;
}


section.about .swiper-slide img.icon_hover{
  display: none;
}

.blue_color{
  color:#0964F3 !important;
}


section.about p.para,
section.enterprise_block p.para,
section.technology p.para,
section.work p.para,
section.blog p.para,
section.faq p.para,
section.activity p.para,
section.marketing_service p.para,
section.marketing_faq p.para,
section.traits p.para,
section.coin_types p.para,
section.coin_faq p.para,
section.consent p.para,
section.log_files p.para,
section.disclaimer p.para,
section.iframe p.para,
section.about_term p.para,
section.license p.para,
section.cookies p.para,
section.gallery_videos p.para,
section.gallery_award p.para,
section.gallery_culture p.para,
section.enterprise_industry p.para,
section.enterprise_faq p.para,
section.enterprise_technology p.para,
section.privacy_term p.para
{
  display:none !important;
}
section.marketing_service .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    background: #7f8585 !important ;
    opacity:unset !important
}
section.marketing_service .swiper-pagination-bullet-active{
  background: #0964f3!important;
}

@media(max-width:991px) {
  section.about .swiper-slide.swiper-slide-active img {
    display: block;
    width: 60%;
    margin: 20px auto;
}



section.about .swiper-slide .about_img img{
  width: 34%;
  margin: 20px auto;
}

section.about .swiper-slide p{
  display: block;
  font-size: 10px;
}

.set_pb_padding {
    padding-bottom: 45px;
}
.row_reverse{
  flex-direction: column-reverse;
}
section#seller .custom_slider .swiper-button-prev,section#seller .custom_slider .swiper-button-next {
display: none;
}
 



}

@media(min-width: 320px) and (max-width: 1400px) {

.set_padding_play_earn {
  padding-top: 139px !important;
}


.set_padding_in_app .exchange_b {
    padding-top: 5rem;
}
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122,153,217)), color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148)));
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122,153,217)), color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148)));
}

/* custom form css */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
}

/* custom form end */
.p_none p {
    display: none;
}

/* Dapp page slider */
section.platform .slider_b .swiper-wrapper {
    align-items: center;
}
/* Dapp page slider */

/* iot */
.iot_work_a .heading p.para{
  display: none;
}

/* faq */
section.coin_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}
div#collapseOne, div#collapseTwo, div#collapseThree, div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 15px 15px;
}


section.coin_faq .accordion-button:focus {
    box-shadow: unset !important;
}
section.coin_faq .accordion-collapse p{
  font-size: 14px !important;
}
section.coin_faq .accordion-item {
    color: var(--bs-accordion-color);
   
}
.accordion{
  position: relative;
}
.accordion:before {
    content: "";
    height: 96%;
    width: 2px;
    background: #464343;
    position: absolute;
    left: -27px;
}
div#collapseOne{
  position: relative;
}
div#collapseOne:after, div#collapseTwo:after, div#collapseThree:after, div#collapseFour:after {
  content: "";
    height: 156%;
    width: 2px;
    background: #fff;
    position: absolute;
    left: -27px;
    top: -57px;
}
div#collapseTwo:after{
  height: 36%;
  top: 79px;
}
div#collapseThree:after{
  height: 37%;
  top: 136px;
}
div#collapseFour:after{
  height: 41%;
  top: 205px;
}
section.coin_faq .accordion-item, section.coin_faq button.accordion-button.collapsed, section.coin_faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
section.coin_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}
section.coin_faq .accordion-item, section.coin_faq button.accordion-button.collapsed{
  background-color: #0e0c0c;
}
section.coin_faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}

.set_platform .heading p.para{
  display:none;
}


/* / using the nft page css / */
section .custom_slider .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px;
  left: 74px !important;
}

section .custom_slider .swiper-button-next:after,
section .custom_slider .swiper-button-prev:after {
  color: #0964f3 !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

/* / using the nft page css / */


/* what can't NFT START  */
section.ntf_used .heading .para {
  display: none;
}

 /* what can't NFT START  */



</style>
