<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(prise, index) in prise_data" :key="index">
          <div class="prise_slide">
     
       
        <div class="text-center"><img :src="require('@/assets/IoT/'+prise.img+'.png')" alt="" class="img-fluid aaa"></div>
        <div class="text-center prise_head mt-3">
          <span class="fs-5 text-dark fw-bold">{{prise.head}}</span>
       
        </div>
        <div>
          <p class="text-dark mt-3 text-center">{{prise.para}}</p>
        </div>
        </div>
      </swiper-slide>
    </swiper>

  
  </template>
  <style scoped >
 
  .prise_slide{
     background-color: white;
     height: 485px;
      padding: 45px 10px;
      border-radius: 10px;
  }
  .prise_head p{
      font-size:14px;
  }
  </style>
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  
  
  import "swiper/css/free-mode";
  import "swiper/css/pagination";
  
  
  
  // import required modules
  import {Autoplay, FreeMode, Pagination, Navigation } from "swiper";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, FreeMode, Pagination, Navigation],
      };
    },
    data(){
              return{
                  prise_data:[
                      {
                          img:"a",
                          head:"Smart Homes",
                          para:"Blockchain-enabled IoT devices have improved the security of the smart homes with its decentralized and innovative smart technology."
                      },
                      {
                          img:"b",
                          head:"Pharmaceutical Industry",
                          para:"The IoT blockchain creates a seamless environment where anyone can access and control their entire healthcare system from their connected device."
                      },
                      {
                          img:"c",
                          head:"Fintech industry",
                          para:" The fintech industry uses IoT blockchain applications to protect and personalize financial services"
                      },
                      {
                          img:"d",
                          head:"Blockchain IoT Real Estate",
                          para:"Through IoT blockchain applications, the real estate industry provides users with any information related to the property, such as its condition, aging process, valuation, documentation, etc."
                      },



                      {
                          img:"e",
                          head:"Automotive Industry",
                          para:"The automotive industry uses blockchain-IoT applications for autonomous traffic management, intelligent parking and the development of autonomous self driving vehicles."
                      },
                      {
                          img:"f",
                          head:"  Supply chain and logistics",
                          para:"Blockchain-IoT applications increase trust and transparency of listed stake-holders through smart contracts by tracking logistics and shipments at every step of the way."
                      }
                      
                  ]
              }
    }
  };
  </script>
