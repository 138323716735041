<template>
<div>
  <NavBar />

  <!-- home -->
  <section class="home">
    <!-- <div class="main-banner">
      <video autoplay="" muted="" loop="" id="bg-video" playsinline>
        <source src="@/assets/IoT/iot.mp4" type="video/mp4" loading="lazy">
      </video>
    </div> -->
    <div class="video_caption">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-6">
          <div class="home_header">
            <Section_Heading text1=" The Distributed Ledger Technology " text2="MeetsThe Distributed " text="Internet of Things">
            </Section_Heading>
            <AllButton text="Get Started" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- home  end-->

  <section class="iot_work">
    <div class="container">
      <div class="row justify-content-center align-items-center row_reverse">
        <div class="col-lg-7">
          <div class="iot_work_a">
            <AllHeadings text3="How does IoT Work With" text4=" Blockchain ? " />

            <div class="iot_work_ab">
              <p class="text-white">IoT and blockchain will change the way we live forever. The combination of these two
                technologies, when used together can be a powerful tool for businesses or
                individuals to have more control over their data while simultaneously protecting
                against tampering with any records that may exist on this new Blockchain-IoT hybrid
                system created by the LBM solution.
              </p>
              <p class="text-white">
                In today’s world where every detail about your daily life is frankly recorded - from
                what you buy at the grocery store to every single digital transaction being recorded.
                LBM Blockchain Solutions offers the right kind of tech, i.e Blockchain-IoT
                applications, that give power to offer security in an increasingly insecure world!.

              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-5 pb-4">
          <div class="text-center">
            <img src="../assets/IoT/iot_work.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>

  </section>



<section class="blockchain_service">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-10 text-center">
      <AllHeadings text3="Blockchain IoT" text4=" Development Services We Offer " />
    </div>
  </div>
  <div class="row">
    <div class="col-12">


<Iot_A></Iot_A>
    </div>
  </div>
</div>

</section>



<section class="enterprise_service">
            <div class="container">
                <div class="row align-items-center coin_bg p-lg-5 p-4 flex-column-reverse flex-md-row text-center text-md-start">
                        <div class="col-lg-7 col-md-6">
                            <h2 class="text-white form_heading">Always enter the blockchain world only after consulting a trusted IoT blockchain consulting company</h2>
                           <p class="text-white mb-0">Hire Our Blockchain IoT Developers To Build </p>
                           <p class="text-white">Your Mobile or Web Solutions!</p>
                            <div class="enter_btn"><AllButton text="Get A Quote"/></div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-10">
                            <div class="text-lg-end text-center mb-5 mb-lg-0">
                                <img src="@/assets/img/service.png" class="img-fluid" loading="lazy">
                            </div>
                        </div>

                    
                </div>
            </div>
        </section>


      

<section class="blockchain_iot">
  <div class="container">
    <div class="row align-items-center justify-content-between">
 
 <div class="col-lg-6">
   <AllHeadings text3="Blockchain-IoT " text4="   Use Cases " />
<p class="text-white">Industries Taking Advantage Of Blockchain And IoT.</p>
   
</div>
<div class="col-lg-5">
<Iot_b></Iot_b>


</div>
</div>
  </div>



</section>


    <section class="why_use">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-6 text-center">
      <AllHeadings text3=" WHY " text4="    CHOOSE US? " />
     
    </div>
  </div>
  <div class="row align-items-center justify-content-center mt-5">
                    <div class="col-lg-3 col-md-6 col-sm-9" v-for="(industry, index) in industry_data" :key="index">
                        <div class="industry_data position-relative text-center mb-3 p-4">
                            <div><img :src="require(`@/assets/IoT/${industry.img}.png`)" alt="" class="img-fluid aa" loading="lazy"/></div>
                            <span class="text-white fs-6 fw-semibold mt-2 d-block">{{industry.content}}</span>
                            <p class="text-white">{{industry.detail}}</p>
                        </div>
                    </div>
                </div>
</div>



    </section>




    <section class="coin_invest bg-light">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="coin_content position-relative d-flex align-items-center justify-content-between flex-wrap">
                        <div class="col-lg-4 ">
                       
                           
<BlackHeading head="FEATURES" span_text="  BLOCKCHAIN IOT"></BlackHeading>
 
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-6">
                            <div class="text-start coin-circle_img"><img src="@/assets/IoT/aarrow.png" class="img-fluid" loading="lazy"></div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row justify-content-center">
                            <div class="col-lg-4 " v-for="(invest, index) in invest_data" :key="index">
                            <div class="invest_data">
                                <h1 class="blue_color fw-bold">{{invest.head}}</h1>
                                <span class="fs-6 text-dark fw-semibold">{{invest.text}}</span>
                             
                            </div>
                        </div>
                          </div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </section>




<section class="why_choose_us">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-6">
      <AllHeadings text3="Blockchain-IoT " text4="   Use Cases " />
    </div>
    
    <div class="col-lg-6 text-center">
      <AllButton text="Join US" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 mb-3" v-for="(data_a, index) in data_a" :key="index">
       <div class="choose_box text-center">
        <div class="text-center">
          <img :src="require(`@/assets/IoT/${data_a.img}.png`)" alt="" class="img-fluid img_a my-3" loading="lazy"/>
          <img :src="require(`@/assets/IoT/${data_a.img_b}.png`)" alt="" class="img-fluid img_b my-3" loading="lazy"/>
        </div>
        <h6 class="text-white">{{data_a.head}}</h6>
        <p class="text-white">{{data_a.text}}</p>
       </div>
        </div>
  
  </div>
</div>
</section>

<section class="enterprise_service">
            <div class="container">
                <div class="row align-items-center coin_bg p-lg-5 p-4 flex-column-reverse flex-md-row text-center text-md-start">
                        <div class="col-lg-7 col-md-6">
                            <h2 class="text-white form_heading"> Transform Your Digital Business </h2>
                            <h2 class="text-white form_heading">With The Power Of Blockchain</h2>
                            <h2 class="text-white form_heading"> And IoT </h2>
                    
                            <div class="enter_btn mt-4"><AllButton text="Call Us Now"/></div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-10">
                            <div class="text-lg-end text-center mb-5 mb-lg-0">
                                <img src="@/assets/IoT/call.png" class="img-fluid" loading="lazy">
                            </div>
                        </div>

                    
                </div>
            </div>
        </section>

        <section class="coin_faq">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-12">
          <AllHeadings text3="Frequently Asked" text4=" Questions"/>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-lg-7">
          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
      <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        What is blockchain-IoT application development?
      </button>
    </span>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Blockchain IoT Application development refers to the process of integrating blockchain technology into IoT systems to improve efficiency, speed and security.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Why should I integrate blockchain-IoT technology into my system?
      </button>
    </span>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">	Blockchain embedded IoT technology in systems improves system efficiency, speed, transparency and quality. Therefore, it is recommended to include this technology in your vast digital ecosystem.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        What’s the scope of blockchain-IoT applications?
      </button>
    </span>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Blockchain technology is the future of application development. Integration of IoT with blockchain applications also helps to bridge gaps in the whole ecosystem. It makes the management of data safe, secure and sound.</p> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        What is the development plan for blockchain IoT applications?
      </button>
    </span>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">IoT blockchain application development varies according to its complexity and other requirements. It is best to get in touch with our blockchain-IoT experts to get an idea about the overall development time and process.</p> 
      </div>
    </div>
    
  </div>
</div>
        </div>
      </div>
    </section>

  <LbmFooter />


</div>
</template>

<style scoped>
/* home css */
section.home {
  position: relative;
}

section.home::before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background-color: #000000b3;
  z-index: 9;
}

section.home .main-banner {
  position: relative;
}

section.home #bg-video {
  width: 100%;
  min-height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}

.home_header {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100%;
}

/* home css end*/

/* iot work */

.iot_work_a .heading p.para {
  display: none;
}

/* iot work end*/

/* enterprise_service */
.coin_bg {
    background: linear-gradient(90deg, rgba(3,36,88,1) 0%, rgba(0,0,0,1) 100%, rgba(0,212,255,0) 100%);
    border-radius: 15px;
}
/* enterprise_service */
/* why_use */
section.why_use .industry_data span{
  white-space: break-spaces;
}
.industry_data {
    height: 315px;
    background-color: #252525;
}
.choose_box .img_b{
  display: none;
}

.choose_box:hover .img_b{
  display: block;
  margin: 0 auto;
}
.choose_box:hover .img_a{
display: none;
}
.choose_box:hover{
  border-bottom: 4px solid #0964F3;
  background-color: #191d25;
}
.choose_box {
    padding: 25px 10px;
    height: 370px;
}
.choose_box h6 {
    white-space: break-spaces;
}
section{
  padding: 60px 0;
}
section.home{
  background-image: url(../assets/IoT/iot.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
    padding: 0;
}
</style>

<script>
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import Section_Heading from '@/components/Section_Heading.vue';
import AllButton from '@/components/AllButton.vue';
import AllHeadings from '@/components/AllHeadings.vue';
import Iot_A from '../components/Iot_A.vue';
import Iot_b from '@/components/Iot_b.vue';
import BlackHeading from '@/components/BlackHeading.vue';

export default {
  name: 'HomeView',
  components: {
    NavBar,
    LbmFooter,
    Section_Heading,
    AllButton,
    AllHeadings,
    Iot_A,
    Iot_b,
    BlackHeading
}
,
data(){
            return{
              industry_data:[
                    {
                        img:"icon_a",
                        content:"Handle Security \n Breaches",
                        detail:"With blockchain-IoT solutions, companies around the world are effectively managing cyber security and data protection."
                    },
                    {
                        img:"icon_b",
                        content:"Privacy \n Concerns",
                        detail:"A lot of business is done online, and even a small mistake can reveal information to people you don't know. Blockchain-IoT applications solve this data security problem."
                    },
                    
                    {
                        img:"icon_c",
                        content:"Decentralized \n System",
                        detail:"Blockchain IoT technology due to its decentralized nature helps businesses protect themselves from unauthorized data corruption."
                    },
                 
                    {
                        img:"icon_d",
                        content:"Increased \n Performance",
                        detail:"Decentralizing data transfer with blockchain-IoT eliminates middlemen, resulting in faster data transfers."
                    },
                    {
                        img:"icon_e",
                        content:"Scalability",
                        detail:"Blockchain IoT applications solve scalability problems for organizations around the whole wide world, by handling multiple endpoints without any latency"
                    },
                    {
                        img:"icon_f",
                        content:"Trustworthy",
                        detail:"Blockchain IoT solutions allow users with smart contracts to track data and that too in real time, thereby increasing the reliability of the entire ecosystem."
                    },
                  
                    

                ],
                invest_data: [
      {
          head: "01.",
          text:"No Cap on Transactions",
         

        },
        {
          head: "02.",
          text:"Low Cost & Saves Time",
  

        },
        {
          head: "03.",
          text:"Avail Scalable Infrastructure",
     

        },
        {
          head: "04.",
          text:"Tailored Innovative Designs",


        },
        {
          head: "05.",
          text:"Custom Project-Based Model",
      
        },
      ],
      data_a:[
        {
          img:"a11",
          img_b:"a12",
          head:"We Are Highly \n Reputed",
          text:"We pride ourselves on a global reputation as a highly reputed Ethereum consulting and development company with a 5 Star global rating."
        },
        {
          img:"b11",
          img_b:"b12",
          head:"We Are Very \n Reliable",
          text:"We are a trusted Ethereum consulting company and will guide you through every step of the Ethereum blockchain consulting process"
        },
        {
          img:"c11",
          img_b:"c12",
          head:"We Are 100% Transparent \n & Trustworthy ",
          text:"We provide regular project updates from project implementation to delivery, to provide transparent services."
        },
        {
          img:"d11",
          img_b:"d12",
          head:"We Have A Team Of Highly \n Skilled Developers",
          text:"From simple Ethereum blockchain applications to big enterprise-level applications, our expert developers can help you find the perfect solution for your business needs"
        },
        {
          img:"e11",
          img_b:"e12",
          head:"We Use Most Advanced \n Technology",
          text:"We use our innovative technology to make the most advanced and most scalable applications for Ethereum Blockchain applications."
        },
        {
          img:"f11",
          img_b:"f12",
          head:"We Provide 24/7 technical \n support",
          text:"At LBM Solutions we provide 24/7 support to our customers and resolve technical issues in about no time."
        }
      ]
            }


}
}
</script>
