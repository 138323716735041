<template>
<div>
 <footer class="position-relative pt-5 mt-5">
   
      <div class="container">
       <div class="footer_item d-flex gap-4"> 
        <div class="footer-main">
             <div class="footer_layer">
            <div class="footer_data text-center">
               <img
              class="img-fluid footer_logo"
              alt="Vue logo"
              src="@/assets/img/logo_lbm.png"
            />
              <p class="m-0 letter-spacing text-white mt-2">
                We provide the leading blockchain development Solutions in the world of digitization. Our goal is to identify
                and grab  disrupitive opportunities to provide the best results for the new decentralized digital world.
              </p>
            </div>
            </div>
            <div class="social_head mt-4 text-white">
              <h4 class="mb-4">Follow Us On:</h4>
              <div class="social_link d-flex gap-3 align-items-center mb-5">
                <a href="https://www.facebook.com/lbmsolution/" target="_blank"><img src="@/assets/img/facebook.png" class="img-fluid set_footer_img"></a>
                <a href="https://www.linkedin.com/company/lbm-solutions-chandigarh/" target="_blank"><img src="@/assets/img/linkedin.png" class="img-fluid set_footer_img"></a>
                <a href="https://twitter.com/lbm_solutions" target="_blank"><img src="@/assets/img/twitter.png" class="img-fluid set_footer_img"></a>
                <a href="https://www.instagram.com/lbmsolutions/" target="_blank"><img src="@/assets/img/insta.png" class="img-fluid set_footer_img"></a>
            </div>
            </div>
            </div>

          <div class="row justify-content-center">
 
          
              <div class="col-lg-2 col-md-4 ms-lg-4">
                <div class="footer_content text-start">
                  <h5 class="fw-light text-white">Services</h5>
                  <ul class="mt-3">
                    <li class="mb-2">
                      <router-link to="/" class="footer_item">Home</router-link>
                    </li>
                    <li class="mb-2">
                      <router-link to="/SmartContract" class="footer_item"
                        >Smart Contract</router-link>
                    </li>
                    <li class="mb-2">
                      <router-link to="/CryptoExchange_b" class="footer_item"
                        >Exchange</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="/IcoPage" class="footer_item"
                        >ICO</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="/coin" class="footer_item"
                        >Coin</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >Token</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="/marketing" class="footer_item"
                        >Marketing</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >NFT</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >Blogs</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              
              <div class="col-lg-2 col-md-4">
                <div class="footer_content  text-start">
                  <h5 class="fw-light text-white">Policy</h5>
                  <ul class="mt-4">
                    <li class="mb-2">
                      <router-link to="/terms" class="footer_item"
                        >Term & Condition</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="/privacypolicy" class="footer_item"
                        >Privacy Policy</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2 ms-lg-5 col-md-4">
                <div class="footer_content  text-start">
                  <h5 class="fw-light text-white">Support</h5>
                  <ul class="mt-4">
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >Help Desk</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >Documentation</router-link
                      >
                    </li>
                    <li class="mb-2">
                      <router-link to="" class="footer_item"
                        >Contact Us</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="footer_content text-start">
                  <h5 class="fw-light text-white mt-md-5 mt-lg-0">Contact Us</h5>
                  <ul class="mt-4">
                    <li class="mb-3">
                      <router-link to="" class="footer_item"
                        ><i class="fa-solid fa-location-dot text-white pe-3"></i>GR Tower, 3rd Floor, Phase 8-A, Industrial Area,
                        Sahibzada Ajit Singh Nagar,(Mohali) Punjab 140308</router-link>
                    </li>
                    <li class="mb-3">
                        <router-link to="" class="footer_item"
                        ><i class="fa-solid fa-phone text-white pe-2"></i>+91-8448443318</router-link>
                    </li>
                    <li class="mb-3">
                        <router-link to="" class="footer_item "
                        ><i class="fa-solid fa-envelope text-white pe-2"></i>info@lbmsolutions.in</router-link>
                    </li>
                    <li class="mb-3">
                        <router-link to="" class="footer_item"
                        ><i class="fa-solid fa-envelope text-white pe-2"></i>support@lbmsolutions.in</router-link>
                    </li>
                  </ul>
                </div>
              </div>
        
      </div>
      </div>
      </div>
      <div class="footer_copyright py-3">
          <div class="row align-items-center justify-content-center text-center ">
            <div class="col-lg-12">
              <div class="footer_copyright_data ">
                  <span class="fs-6 fw-light">© 2022 LBM Solutions Blockchain. All
                  Rights Reserved
                </span>
              </div>
            </div>
          </div>
      </div>

    </footer>
</div>
</template>
<script>
export default {
  name: "NavBar"
}
</script>
<style scoped>
footer {
  background-color: #111111;
}
.footer_content ul {
  padding-left: unset !important;
}
.footer_link ul {
  margin-inline: auto;
  width: fit-content;
}
.footer_item, .footer_copyright{
    color:#ffffff59;
}
.footer_copyright {
  border-top:1px solid #ffffff59;
}
.footer_data{
    position: relative;
    z-index: 99999;
}
.footer_layer {
    background-color: #23232300;
    padding: 60px 25px 35px;
    width: 400px;
    margin-top: -110px;
    border: 1px solid #0964F3;
    border-radius: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}
.footer_layer:before {
    position: absolute;
    content: '';
    background-color: #0964F3;
    width: 55%;
    height: 55%;
    border-radius: 50%;
    filter: blur(70px);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
}
.set_footer_img {
    max-width: 70%;
}
@media (max-width:991px) {
.footer_layer {
    padding: 20px 10px 14px !important;
    width: 295px !important;
    margin: 0 auto !important;
}
.footer_item{
  display: block !important;
}
.social_head.mt-4.text-white {
    text-align: center !important;
}
.social_link {
  justify-content: center;
}
}

@media(min-width: 992px) and (max-width: 1199px) {
.footer_layer {
    padding: 25px 10px 15px;
    width: 255px;
    margin-top:unset !important;
  }
  .footer_data p {
    font-size: 13px;
}
.footer_content li a {
    font-size: 13px;
}
}

@media(min-width: 1200px) and (max-width: 1399px){
  .footer_content ul li a {
    font-size: 13px;
}
}
</style>