<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true"   :autoplay="{
        delay: 2500
        ,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        '1400': {
          slidesPerView: 2,
          spaceBetween: 50,
        }
      }" :modules="modules" class="mySwiper">
    
     

<swiper-slide><img src="@/assets/img/award.jpg" /></swiper-slide>
<swiper-slide><img src="@/assets/img/award1.jpg" /></swiper-slide>

<swiper-slide><img src="@/assets/img/award.jpg" /></swiper-slide>
<swiper-slide><img src="@/assets/img/award1.jpg" /></swiper-slide>
   
    
   
     
    
    
    

    
  
 

    </swiper>
    </template>
    
    <script>
      // Import Swiper Vue.js components
      import {
        Swiper,
        SwiperSlide
      } from "swiper/vue";
      
      // Import Swiper styles
      import "swiper/css";
      
      import "swiper/css/pagination";
      
      // import required modules
      import {
        Autoplay,
        Pagination
      } from "swiper";
      
      export default {
        components: {
          Swiper,
          SwiperSlide,
        },
        setup() {
          return {
            modules: [Pagination, Autoplay],
          };
        },
      };
      </script>
    <style scoped>
      .parent_div {
        border: 1px solid #464646;
      height: 400px;
        padding: 30px 10px;
        border-radius: 5px;
    }
    .set_svg {
      filter: invert(1);
      height: 100px;
      width: auto;
  }
 
  @media screen and (min-device-width: 320px) and (max-device-width: 425px){
    .parent_div {
   
      height: 448px !important;
    } 
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 639px){
    .swiper-slide.swiper-slide-active {
    display: flex;
    justify-content: center;
}
  }
    </style>
