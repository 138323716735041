<template>
    <div>
        <NavBar />


        <section class="dapp position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 text-center text-lg-start">
                        <div class="home_content  position-relative">
                            <div class="mt-md-5 pt-md-5">
                                <Section_Heading text1=" With Custom DApps, Businesses Can" text2="  Grow!"
                                    text3="LBM Blockchain Solutions Builds Robust, Scalable, And Extensible Decentralized Apps For Global Clients Using Cutting-Edge Technology.">
                                </Section_Heading>
                                <div class="mt-5">
                                    <AllButton text="Explore More" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>






        <section class="enterprise-dapp">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-12 col-lg-6 text-center text-lg-start">
                        <AllHeadings text3="Developing DApps" text4=" Effectively!"
                            text5="Our Dapp Development Services Cater To A Wide Range Of Needs." />
                    </div>
                    <div class="col-lg-6 text-end d-none d-lg-block">
                        <div><img src="@/assets/Dapp_page/img/drag.png" class="img-fluid" loading="lazy"></div>
                    </div>
                </div>

                <div class="row align-items-center justify-content-center mt-5">
                    <div class="col-sm-9 col-md-12">
                        <swiper :slidesPerView="3" :spaceBetween="30" :freeMode="true" :centeredSlides="true" :autoplay="{
                        delay:2500,
                        disableOnInteraction: false,
                        }" :loop="true" :pagination="{
                            clickable: true,
                        }" :modules="modules" :breakpoints="{
                        
                        
                                                    '320': {
                            slidesPerView: 1,
                            spaceBetween: 40,
                        },
                        
                        '640': {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        '768': {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        '1024': {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        }" class="mySwiper">
                            <swiper-slide v-for="(slide_data1, index) in slide_data1" :key="index">
                                <div class="app_slide">
                                    <div class="text-center app_img ">

                                        <div class="position-relative">
                                            <img :src="require(`@/assets/Dapp_page/img/${slide_data1.img}.png`)" alt=""
                                                class="img-fluid" loading="lazy"/>
                                        </div>

                                        <img :src="require(`@/assets/playearn_elements/svg_files/${slide_data1.icons}.svg`)"
                                            alt="" class="img-fluid slide_icon" loading="lazy"/>

                                        <img :src="require(`@/assets/playearn_elements/svg_files/${slide_data1.icons_h}.svg`)"
                                            alt="" class="img-fluid slide_icon_hover" loading="lazy"/>

                                    </div>

                                    <div class="text-center app_content mt-3">
                                        <span class=" text-black fw-bold fs-5">{{slide_data1.content}}</span>
                                        <p class="text-black mt-2 fs-6">{{slide_data1.item}}</p>
                                    </div>

                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </section>







        <section class="enterprise_work">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <AllHeadings text3="Our Speciality" text4=" Work" text5="" />
                    </div>
                    <div class="col-sm-9  col-lg-4 col-md-6 custom_col mb-4" v-for="(work, index) in work_data"
                        :key="index">
                        <div class="enterprise_data position-relative">
                            <!-- <h3 class="text-muted work_head">{{work.head}}</h3> -->
                            <div class="text-muted ">
                                <img :src="require(`@/assets/playearn_elements/svg_files/${work.head}.svg`)" alt=""
                                    class="img-fluid slide_icon_hover work_head" loading="lazy"/>
                            </div>

                            <span class="fw-semibold text-white fs-5 work_para">{{work.data}}</span>
                            <p class="text-white mt-3">{{work.para}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="foundations">
            <div class="container">
                <div
                    class="row justify-content-center align-items-center text-center text-md-start flex-column-reverse flex-md-row">
                    <div class="col-md-7 col-lg-6">
                        <AllHeadings text3="Foundations Of " text4="Technology" text5="In Every Creation We Build, We Utilize Blockchain's Most Advanced Capabilities To
                             Meet The Requirements Of Cross-Industry Clients And The Scope Of The Project. Our Dynamic Approach To Managing Project Demands And Engaging The Innovative
                             Factor Has Allowed Us To Shine Brighter As A Leading Blockchain Development Company.">
                        </AllHeadings>


                    </div>
                    <div class="col-md-2 col-lg-2 text-center d-none d-md-block">
                        <img src="@/assets/Dapp_page/img/circle_arrow.png" alt="" class="img-fluid" loading="lazy">
                    </div>
                    <div class="col-8 col-sm-7 col-md-3 col-lg-3 text-center mb-5 mb-md-start">
                        <div class="mobile_after position-relative">
                            <img src="@/assets/Dapp_page/img/phone.png" alt="" class="img-fluid" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section class="platform">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center ">
                        <AllHeadings text3="Blockchain " text4=" Technology Platforms" text5="">
                        </AllHeadings>
                    </div>
                </div>

                <div class="slider_contant">
                    <div class="row bg_color">
                        <div class="col-lg-12">
                            <div class="slider_b">
                                <platformSlider />
                            </div>
                        </div>
                    </div>

                    <div class="app_development">
                        <div class="container">
                            <div class="row justify-content-center mt-5">
                                <div class=" col-lg-10 text-center ">
                                    <AllHeadings text3="What Do We Do During Our " text4=" DApp Development Process? "
                                        text5="">
                                    </AllHeadings>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-9 col-lg-6">
                                    <img src="@/assets/Dapp_page/img/hand_card.png" alt="" class="img-fluid" loading="lazy">
                                </div>
                                <div class="col-md-10 col-lg-6">
                                    <div class="d-flex text-white">
                                        <div class="me-3">
                                            <img src="@/assets/playearn_elements/svg_files/dapp_start_svg.svg" alt="" loading="lazy">
                                        </div>
                                        <div class="">
                                            <p class="fw-bold para_heading">Discovery</p>
                                            <p class="para">Our First Step Is To Gather The Client's Requirements,
                                                Conduct
                                                Competitive Research, Discover Business Objectives, And More In Order To
                                                Determine The Feasibility Of Your Project.</p>
                                        </div>
                                    </div>


                                    <div class="d-flex text-white">
                                        <div class="me-3">
                                            <img src="@/assets/playearn_elements/svg_files/dapp_start_svg.svg" alt="" loading="lazy">
                                        </div>
                                        <div class="">
                                            <p class="fw-bold para_heading">Design</p>
                                            <p class="para">Depending On Your Website Design Needs, We Construct
                                                High-Fidelity
                                                Wireframes And Prototypes.</p>
                                        </div>
                                    </div>

                                    <div class="d-flex text-white">
                                        <div class="me-3">
                                            <img src="@/assets/playearn_elements/svg_files/dapp_start_svg.svg" alt="" loading="lazy">
                                        </div>
                                        <div class="">
                                            <p class="fw-bold para_heading">Development</p>
                                            <p class="para">Your Site's Pages And Scope Are Enforced By Our CMS. Each
                                                Web Page And
                                                Another Characteristic Of The Website Has Its Own Design.</p>
                                        </div>
                                    </div>

                                    <div class="d-flex text-white">
                                        <div class="me-3">
                                            <img src="@/assets/playearn_elements/svg_files/dapp_start_svg.svg" alt="" loading="lazy">
                                        </div>
                                        <div class="">
                                            <p class="fw-bold para_heading">Testing</p>
                                            <p class="para">The Performance And Appearance Of Your Website Are Tested
                                                Rigorously
                                                During The Testing Process.</p>
                                        </div>
                                    </div>
                                    <div class="d-flex text-white">
                                        <div class="me-3">
                                            <img src="@/assets/playearn_elements/svg_files/dapp_start_svg.svg" alt="" loading="lazy">
                                        </div>
                                        <div class="">
                                            <p class="fw-bold para_heading">Development</p>
                                            <p class="para">We Enable You To Deploy Your Website On Any Chosen Medium
                                                After Building
                                                And Testing The Website Successfully.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>






        <section class="coin_types">
            <div class="container">
                <div class="row text-center align-items-center justify-content-center">
                    <div class="col-lg-8">
                        <AllHeadings text3="Blockchain " text4=" Technology
Platforms" />
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-4">
                    <div class="col-lg-12 ">
                        <div class="coin_type_main d-flex justify-content-center flex-wrap gap-4 ">
                            <div class="typemain_content d-flex  align-items-center gap-2"
                                v-for="(coin, index) in coin_data" :key="index">
                                <div class="cointype_img"><img :src="require(`@/assets/Dapp_page/img/${coin.img}.png`)"
                                        alt="" class="img-fluid" loading="lazy">
                                </div>
                                <span class="text-white fw-semibold fs-5 pe-2">{{coin.head}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




        <section class="faq custom_p">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-12">
                        <AllHeadings text3="Frequently Asked" text4=" Questions" />
                    </div>
                </div>
                <div class="row align-items-center  justify-content-center  mt-5 flex-column-reverse flex-lg-row">
                    <div class="col-md-11 col-lg-8">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
                                    <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        1. What is a DApp in blockchain?
                                    </button>
                                </span>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">Rather than running on a single computer, a decentralized
                                            application (DApp) uses a peer-to-peer (P2P) blockchain network to
                                            distribute open source software applications. As a result, DApps are very
                                            similar to other software applications available on a website or mobile
                                            device, but are P2P-supported.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        2. Is Ethereum a DApp?
                                    </button>
                                </span>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">With Ethereum, dapps are disrupting or inventing new
                                            business models.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        3. Are DApps Just Websites?
                                    </button>
                                </span>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">A decentralised application, or DApp, is a new model for
                                            web applications that is significantly more secure. Unlike traditional web
                                            apps, DApps encrypt their protocols and data on blockchains.</p>
                                    </div>
                                </div>

                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                        4. What Problems do DApps Solve?
                                    </button>
                                </span>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">The faster transaction speed of DApps translates into
                                            lower costs. Unlike centralized systems, a decentralized system does not
                                            require heavy servers and expert maintenance.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-sm-6 col-md-6 col-lg-4">
                        <img src="@/assets/Dapp_page/img/accordion_img.png" alt="" class="img-fluid" loading="lazy">
                    </div>
                </div>
            </div>

        </section>

        <LbmFooter />
    </div>
</template>







<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/pagination";

import NavBar from "@/components/NavBar.vue";
import Section_Heading from "@/components/Section_Heading.vue";
import LbmFooter from "@/components/LbmFooter.vue";
import AllButton from "@/components/AllButton.vue";
import AllHeadings from "@/components/AllHeadings.vue";
import platformSlider from "@/components/platformSlider.vue";
// import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, FreeMode, } from "swiper";



export default {
    name: "DappPage.vue",
    components: {
        // Swiper,
        Section_Heading,
        // SwiperSlide,
        NavBar,
        LbmFooter,
        AllButton,
        AllHeadings,
        platformSlider,
        Swiper,
        SwiperSlide,

    },
    setup() {
        return {
            modules: [Autoplay, FreeMode,],
        };
    },
    data() {
        return {
            slide_data1: [
                {
                    img: "slide_shape",
                    icons: "dapp_svg_a",
                    icons_h: "dapp_svg_a",
                    content: "MVP Consulting",
                    item: "We identify potential stakeholders, define technical components, and suggest the right blockchain platform according to the client's requirements. We keep up to date with the emerging DApp market trends and can help you analyze whether your idea will succeed or fail."
                },
                {
                    img: "slide_shape",
                    icons: "dapp_svg_b",
                    icons_h: "dapp_svg_a",
                    content: "User Interface Design",
                    item: "The user interface design process follows a structured and systematic approach, from the concept phase to the creation of interactive prototypes and the final product. We have a proven track record of delivering award-winning apps to our clients."
                },
                {
                    img: "slide_shape",
                    icons: "dapp_svg_c",
                    icons_h: "dapp_svg_a",
                    content: "DApp Consultation",
                    item: "Using our DApp development experts' extensive knowledge of emerging dApp market trends, we help you assess your idea's chances for success. We advise you on the right blockchain platform to build your DApp."
                },

            ],
            work_data: [
                {
                    head: "Speciality _svg_a",
                    data: "Security",
                    para: "We create DApps that deliver a maximum level of security during trades. ",
                },
                {
                    head: "Speciality _svg_b",
                    data: "Speed & Trustworthiness",
                    para: "We build DApps that deliver swift and more frugal transactions.",
                },
                {
                    head: "Speciality _svg_c",
                    data: "Exquisite UI/UX",
                    para: "Our UI/UX designers design superficial and easy-to-use DApps.",
                },
                {
                    head: "Speciality _svg_d",
                    data: "Robust Experience",
                    para: "Our developers have expertise in developing a robust experience with DApps.",
                },
                {
                    head: "Speciality _svg_e",
                    data: "More elevated Happiness",
                    para: "We create technically advanced DApps to induce more lavish delight.",
                },


            ],
            coin_data: [
                {
                    img: "coin1",
                    head: "Solidity"
                },
                {
                    img: "coin2",
                    head: "C++"
                },
                {
                    img: "coin3",
                    head: "Angular"
                },
                {
                    img: "coin4",
                    head: "C#"
                },
                {
                    img: "coin5",
                    head: "Python"
                },
                {
                    img: "coin6",
                    head: "Kotlin"
                },
                {
                    img: "coin7",
                    head: "React"
                },
                {
                    img: "coin8",
                    head: "Next JS"
                },
                {
                    img: "coin9",
                    head: "Javascript ",
                },
                {
                    img: "coin10",
                    head: " Swift"
                },
                {
                    img: "coin11",
                    head: "Node JS  "
                },

            ],
        }
    }
}
</script>




<style scoped>
section.dapp {
    background-image: url(@/assets/Dapp_page/img/main_bg.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height: 100vh;
}

section.dapp .home_content {
    max-width: 874px;
    padding: 110px 0;
}



img.slide_icon {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 73px;
    left: 10px;
    right: 0;
    margin: 0 auto;
}

img.slide_icon_hover {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 73px;
    left: 10px;
    right: 0;
    margin: 0 auto;
}

section.enterprise-dapp .swiper-slide.swiper-slide-active img.slide_icon {
    display: none;
}

section.enterprise-dapp .swiper-slide.swiper-slide-active img.slide_icon_hover {
    display: block;
    margin: 0 auto;
}

section.enterprise-dapp .swiper-slide img.slide_icon_hover {
    display: none;
}

.app_slide {
    background: #fff;
    height: 380px;
    border-radius: 15px;
    padding: 30px;
}

.app_content p {
    font-size: 13px;
}

.app_content span {
    font-size: 15px;
}

section.enterprise-dapp .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-free-mode.mySwiper {
    height: 450px;
}

.enterprise_data {
    padding: 45px 30px 20px 30px;
    border: 2px solid #fff;
    margin-top: 30px;
    height: 220px;
    cursor: pointer;
}

.enterprise_data:hover {

    border: 2px solid #6c757d;
}

.enterprise_data:hover .work_head,
.enterprise_data:hover span.work_para {
    color: #0964F3 !important;
}

.enterprise_data .work_head[data-v-d5b448b4] {
    position: absolute;
    top: -24px;
    /* padding: 0 10px; */
    background: #0e0c0c;
    /* width: 120px; */
    left: -60%;
}

.enterprise_data p {
    font-size: 14px;
}

.work_main {
    background-color: #ECF3FC;
    padding: 60px 140px;
}

.work_items p {
    font-size: 14px;
}

span.work_data[data-v-1a80270a] {
    position: absolute;
    border: 10px solid #0964f3;
    height: 100%;
    right: 0;
    left: 0;
    width: 55%;
    margin: auto;
    top: 0;
    bottom: 0;
}

.unleash_data {
    background: transparent;
    height: 300px;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 5px;

}

.unleash_data:hover {
    background: #D9EAFE;
    border: 2px solid #1774EF;

}

.unleash_data:hover img.unleash_icon_hover {
    display: block !important;
    margin: 0 auto;
}

.unleash_data:hover img.unleash_icon {
    display: none !important;
}

img.ind_img {
    width: 40%;
}

.industry_data {
    background: #222020;
    height: 220px;
}

.industry_data:after {
    content: "";
    position: absolute;
    border-width: 1px !important;
    border-top-style: solid;
    border-left-style: solid;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    top: 0;
    left: 0;
    border-right: transparent;
    -o-border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image-slice: 1;
}

.enterprise_service .coin_bg {
    background: #03193C;
    border-radius: 15px;
}

section.enterprise_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 15px 15px;
}


section.enterprise_faq .accordion-button:focus {
    box-shadow: unset !important;
}

section.enterprise_faq .accordion-collapse p {
    font-size: 14px !important;
}

section.enterprise_faq .accordion-item {
    color: var(--bs-accordion-color);

}

.accordion {
    position: relative;
}

.accordion:before {
    content: "";
    height: 96%;
    width: 2px;
    background: #464343;
    position: absolute;
    left: -27px;
}

div#collapseOne {
    position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after {
    content: "";
    height: 156%;
    width: 1px;
    background: #fff;
    position: absolute;
    left: -27px;
    top: -57px;
}

div#collapseTwo:after {
    height: 36%;
    top: 79px;
}

div#collapseThree:after {
    height: 37%;
    top: 136px;
}

div#collapseFour:after {
    height: 41%;
    top: 205px;
}

section.enterprise_faq .accordion-item,
section.enterprise_faq button.accordion-button.collapsed,
section.enterprise_faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

section.enterprise_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}

section.enterprise_faq .accordion-item,
section.enterprise_faq button.accordion-button.collapsed {
    background-color: #0e0c0c;
}

section.enterprise_faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}




/* languages contant */
.typemain_content {
    background: #222020;
    padding: 8px;
    position: relative;
}

.typemain_content:after {
    content: "";
    position: absolute;
    border-width: 1px !important;
    border-top-style: solid;
    border-left-style: solid;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    top: 0;
    left: 0;
    border-right: transparent;
    -o-border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image-slice: 1;
}

/* languages contant */

/* development section css */

.app_development .para_heading {
    font-size: 22px;
}

/* development section css */



/* accordion css */
section.faq .accordion-button:focus {
    box-shadow: unset !important;
}

section.faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 15px 15px;
}


section.faq .accordion-collapse p {
    font-size: 14px !important;
}

section.faq .accordion-item {
    color: var(--bs-accordion-color);

}

.accordion {
    position: relative;
}

.accordion:before {
    content: "";
    height: 96%;
    width: 2px;
    background: #464343;
    position: absolute;
    left: -27px;
}

div#collapseOne {
    position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after {
    content: "";
    height: 156%;
    width: 1px;
    background: #fff;
    position: absolute;
    left: -27px;
    top: -57px;
}

div#collapseTwo:after {
    height: 36%;
    top: 79px;
}

div#collapseThree:after {
    height: 37%;
    top: 136px;
}

div#collapseFour:after {
    height: 41%;
    top: 205px;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed,
section.faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

section.faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed {
    background-color: #0e0c0c;
}

section.faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}

/* accordion css */


.platform .slider_contant .bg_color {
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    background: linear-gradient(184deg, rgba(33, 34, 42, 1) 0%, rgba(0, 0, 0, 0.3561625333727241) 100%);
}

/* slider dapp */
.slider_b {
    padding: 60px 0;
}

/* slider dapp */


section.foundations .mobile_after:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: -22%;
    background-image:url(@/assets/Dapp_page/img/cilre_img.png) ;
    right: -57%;
    background-repeat: no-repeat;
    z-index: -1;
}



@media(min-width:320px) and (max-width:408px) {

    .app_development .para_heading {
        font-size: 18px;
    }

}

@media(min-width:409px) and (max-width:576px) {
    .app_development .para_heading {
        font-size: 20px;
    }
}

@media(max-width:991px) {
    .app_development p.para {
        font-size: 14px !important;
    }

    section.dapp {
        background-size: cover !important;
        height: unset !important;
    }

    section.dapp:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: #00000094;
    }
    section.foundations .mobile_after:after {
        background-image: unset;
    }
}
section{
  padding:  60px 0 ;
}
</style>