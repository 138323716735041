<template>
  <div>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
    <swiper-slide class="p_first" v-for="(prise, index) in prise_data" :key="index">
        <div class="prise_slide mb-5">
      <div class="text-center"><img :src="require('@/assets/img/'+prise.img+'.png')" alt="" class="img-fluid"></div>
      <div class="text-center prise_head mt-3">
        <span class="fs-5 text-white fw-bold">{{prise.head}}</span>
        <p class="text-white mt-3">{{prise.para}}</p>
      </div>
      </div>
    </swiper-slide>
  </swiper>
</div>

</template>
<style scoped >
.prise_slide:hover{
    background-color: #191d25;
    border-bottom: 4px solid #0964F3;
    padding: 10px;

}
.prise_slide{
    border-bottom: 1px solid transparent;
    height: 240px;
    padding: 10px;
}
.prise_head p{
    font-size:14px;
}
</style>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles


import "swiper/css/free-mode";
import "swiper/css/pagination";



// import required modules
import {Autoplay, FreeMode, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, FreeMode, Pagination, Navigation],
    };
  },
  data(){
            return{
                prise_data:[
                    {
                        img:"mask",
                        head:"Fintech",
                        para:"Simplify the banks' operations and ensure that every transaction is accurate and secure."
                    },
                    {
                        img:"mask4",
                        head:"Healthcare",
                        para:"Connect hospitals with patients and ensure privacy in the healthcare ecosystem."
                    },
                    {
                        img:"mask1",
                        head:"Supply Chain",
                        para:"The flow of goods and services between distant parts of the world can be made more efficient by connecting remote parts of the world."
                    },
                    
                    
                ],
            }
  }
};
</script>
