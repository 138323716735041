<template>
    <div>
      <NavBar />
  
      <section class="terms_home">
        <div class="container">
          <div class="row justify-content-center align-items-center text-center">
            <div class="col-lg-10">
              <div class="inner_data">
                <PrivacyHead data="Our" data2="Terms And Conditions" />
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <section class="about_term">
          <div class="container">
              <div class="row align-items-center justify-content-center text-center">
                  <div class="col-lg-12">
                      <AllHeadings text3="Terms And" text4=" Conditions"/>
                  </div>
              </div>
              <div class="row">
                  <div class="col-lg-12">
                      <ul class="text-white term-data">
                          <li>Welcome to LBM Blockchain Solutions!</li>
                         <li>These terms and conditions outline the rules and regulations for the use of LBM Blockchain Solutions's Website, located at LBM BLOCKHAIN SOLUTION.</li>
                         <li>By accessing this website we assume you accept these terms and conditions. Do not continue to use LBM Blockchain Solutions if you do not agree to take all of the terms and conditions stated on this page.</li>
                        <li>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</li>
                      </ul>
                  </div>
              </div>
          </div>
      </section>
  
      <section class="cookies py-4">
          <div class="container">
          <div class="row">
              <div class="col-lg-12">
                <Terms_Heading data="Cookies"/>
                  <div>
                      <ul class="text-white term-data mt-4">
                          <li>We employ the use of cookies. By accessing LBM Blockchain Solutions, you agreed to use cookies in agreement with the LBM Blockchain Solutions's Privacy Policy.</li>
                          <li>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</li>
                      </ul>
                  </div>
                  </div>
              </div>
          </div>
      </section>
  
      <section class="license pt-3 pb-4">
          <div class="container">
          <div class="row">
              <div class="col-lg-12">
                <Terms_Heading data="License"/>
                  <div>
                      <ul class="text-white term-data mt-4">
                          <li>Unless otherwise stated, LBM Blockchain Solutions and/or its licensors own the intellectual property rights for all material on LBM Blockchain Solutions. All intellectual property rights are reserved. You may access this from LBM Blockchain Solutions for your own personal use subjected to restrictions set in these terms and conditions. </li>
                          <li>You must not:</li>                 
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Republish material from LBM Blockchain Solutions</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Sell, rent or sub-license material from LBM Blockchain Solutions</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Reproduce, duplicate or copy material from LBM Blockchain Solutions</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Redistribute content from LBM Blockchain Solutions</li>
                          <li>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Template.</li>
                          <li>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. LBM Blockchain Solutions does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of LBM Blockchain Solutions,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, LBM Blockchain Solutions shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</li>
                          <li>LBM Blockchain Solutions reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</li>
                          <li>You warrant and represent that:</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                          <li>You hereby grant LBM Blockchain Solutions a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</li>
                          <li>Hyperlinking to our Content</li> 
                          <li>The following organizations may link to our Website without prior written approval:</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Government agencies;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Search engines;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">News organizations;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and </li> 
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                          <li>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</li>
                          <li>We may consider and approve other link requests from the following types of organizations:</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">commonly-known consumer and/or business information sources;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">dot.com community sites;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">associations or other groups representing charities;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">online directory distributors;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">internet portals;</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">accounting, law and consulting firms; and</li> 
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">educational institutions and trade associations.</li>
                          <li>We will approve link requests from these organizations if we decide that:</li>
                          <li><span class="blue_color fw-semibold">(a)</span> the link would not make us look unfavorably to ourselves or to our accredited businesses;</li>
                          <li><span class="blue_color fw-semibold">(b)</span> the organization does not have any negative records with us;</li>
                          <li><span class="blue_color fw-semibold">(c)</span> the benefit to us from the visibility of the hyperlink compensates the absence of LBM Blockchain Solutions; and</li>
                          <li><span class="blue_color fw-semibold">(d)</span> the link is in the context of general resource information.</li>
                          <li>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</li>
                          <li>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to LBM Blockchain Solutions. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</li>
                          <li>Approved organizations may hyperlink to our Website as follows:</li> 
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">By use of our corporate name; or</li>
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">By use of the uniform resource locator being linked to; or</li> 
                          <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                          <li>No use of LBM Blockchain Solutions's logo or other artwork will be allowed for linking absent a trademark license agreement.</li>                   
                        </ul>
                  </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="iframe pt-3 pb-4">
          <div class="container">
          <div class="row">
              <div class="col-lg-12">
                <Terms_Heading data="Iframes"/>
                  <div>
                    <ul class="text-white term-data mt-4">
                        <li>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</li>
                        <li>Content Liability</li>
                        <li>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</li>
                        <li>Your Privacy</li>
                        <li>Please read Privacy Policy</li>
                        <li>Reservation of Rights</li>
                        <li>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</li>
                        <li>Removal of links from our website </li>
                        <li>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</li>
                        <li>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</li>
                    </ul>
                  </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="disclaimer pt-3 py-5">
          <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <Terms_Heading data="Disclaimer"/>
                  <div>
                      <ul class="text-white term-data mt-4">
                        <li>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</li>
                        <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">limit or exclude our or your liability for death or personal injury;</li>
                        <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                        <li><img src="/img/star.625089c0.svg" class="img-fluid pe-2" loading="lazy">exclude any of our or your liabilities that may not be excluded under applicable law.</li>              
                        <li>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</li>
                        <li>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature. </li>
                                    
                      </ul>
                  </div>
                  </div>
              </div>
          </div>
      </section>
  
      <LbmFooter />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import NavBar from "@/components/NavBar.vue";
  import LbmFooter from "@/components/LbmFooter.vue";
  
  import PrivacyHead from "@/components/PrivacyHead.vue";
  import AllHeadings from "@/components/AllHeadings.vue";
  import Terms_Heading from "@/components/Terms_Heading.vue";
  
  // import AllButton from '@/components/AllButton.vue';
  // import Section_Heading from '@/components/Section_Heading.vue';
  // import AllHeadings from '@/components/AllHeadings.vue';
  
  export default {
    name: "TermCondition",
    components: {
      NavBar,
      LbmFooter,
      PrivacyHead,
      AllHeadings,
      Terms_Heading  

  },
  };
  </script>
  
  <style scoped>
  section.terms_home {
    background-image: url(@/assets/img/term.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  
  
  
  .inner_data {
      padding: 40px 0;
  }
  ul.term-data li{
      margin-bottom: 1rem;
      font-weight: 300;
      font-size: 14px;
  }
  </style>
  