<template>
  <div>

    <NavBar />

    <section class="hero bg_dark " id="home">
      <div class="main-banner" id="top">
        <!-- <video autoplay="" muted="" loop="" id="bg-video" playsinline>
          <source src="@/assets/video/smart_contract.mp4" type="video/mp4" loading="lazy">
        </video> -->
        <div class="container">
          <div class="caption">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="news_badges mb-5">
                  <span class="badge_title text-white fw-bold fs-6 rounded-pill">Transparency <span
                      class="text-muted">is a key to a</span> Successful Business <span
                      class="text-muted">Relationship</span></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="main_content">
                  <h1 class="text-white">Smart contract</h1>
                  <h1>development</h1>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <p class="text-white fs-5 p-3 mb-0">LBM Blockchain Solutions specializes in creating blockchain-enabled
                  smart
                  contracts that are efficient, hack-proof, and easy to amend. </p>
                <div>
                  <AllButton text="Get Started"></AllButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="technology bg_dark" id="technology">
      <div class="container">
        <div class="row py-5">
          <div class="col-lg-8">
            <AllHeadings text3="In-depth Knowledge
Of " text4=" Blockchain Technology "></AllHeadings>
          </div>
        </div>
        <div class="row  justify-content-center">
          <div class="col-lg-4 col-md-6 my-3" v-for="(data_a, index) in data_a" :key="index">
            <div class="box_technology">
              <h4>{{ data_a.heading }}</h4>
              <p>{{ data_a.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg_dark lbm_solution position-relative">
      <div class="container">
        <div class="row justify-content-lg-end mt-5 mt-sm-start">
          <div class="col-lg-6 text-lg-end position-relative">
            <div class="parent_a">
              <span class="line_a"></span>
              <div class="box_a">
                <p class="text-white mb-0">Enlightened quality management system and data security enactment</p>
              </div>
            </div>

          </div>
        </div>
        <div class="row justify-content-lg-start">
          <div class="col-lg-6 text-lg-start position-relative">
            <div class="parent_a">
              <span class="line_b"></span>
              <div class="box_a">
                <p class="text-white mb-0">Matured and proficient coders with efficiency in languages</p>
              </div>
            </div>

          </div>
        </div>
        <div class="row justify-content-center Smart_cont">
          <div class="col-lg-6 text-center">
            <div class="box_b">
              <h3 class="text-white">Why <span style="color: #0964F3;">LBM</span><br>
                Blockchain Solutions?</h3>
            </div>
          </div>
        </div>
        <div class="row justify-content-lg-end">
          <div class="col-lg-4 text-lg-end position-relative">
            <div class="parent_a">
              <span class="line_c"></span>
              <div class="box_a">
                <p class="text-white mb-0">Expertise in industries</p>
              </div>
            </div>

          </div>
        </div>
        <div class="row justify-content-lg-start">
          <div class="col-lg-6 text-lg-start position-relative">
            <div class="parent_a">
              <span class="line_d"></span>
              <div class="box_a">
                <p class="text-white mb-0">Immense knowledge in the IT field,
                  skilled IT experts on board.</p>
              </div>
            </div>

          </div>
        </div>
        <div class="row justify-content-lg-end">
          <div class="col-lg-5 text-lg-end position-relative">
            <div class="parent_a">
              <span class="line_e"></span>
              <div class="box_a">
                <p class="text-white mb-0">Working experience with paramount blockchain frameworks and networks</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>

    <section class="bg_dark" id="Specializations">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <AllHeadings text3="Our  " text4=" Specializations: "></AllHeadings>

          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <SmartContract_a></SmartContract_a>
          </div>
          <div class="col-12">
            <SmartContract_b></SmartContract_b>
          </div>
        </div>

      </div>

    </section>

    <section class="services bg_dark" id="Services">

      <div class="container">
        <div class="row pb-3">
          <div class="col-lg-9 pt-5">
            <AllHeadings text3="  Services   " text4=" We Offer for DeFi Smart  Contract Audits"></AllHeadings>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-6   col-md-6 mb-4" v-for="(data_b, index) in data_b" :key="index">
            <div class="sercices_box">
              <div class="services_icon my-3">
                <span> <img :src="require(`@/assets/images/${data_b.path}`)" alt="" class="img-fluid ss_img" loading="lazy"></span>

              </div>
              <div class="services_content">
                <h1>{{ data_b.heading }}</h1>
                <p>{{ data_b.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg_dark" id="Benefits">
      <div class="container">
        <div class="row align-items-center justify-content-center row_reverse">
          <div class="col-lg-6">
            <div>
              <AllHeadings text3="  A Variety Of " text4="  Benefits For 
Customers"></AllHeadings>

              <ul>

                <ListDetail text_a="Mistakes are no longer an issue"
                  text_b="Early testing of smart contracts leaves a marketplace and solutions free of errors.">
                </ListDetail>
                <ListDetail text_a="Untraceable and difficult to attack"
                  text_b="As part of our testing management process, we take precautions to ensure the safety of our platforms and reduce the possibility of hacking.">
                </ListDetail>
                <ListDetail text_a="Performing automatic security scans" text_b="Keeping systems updated and malware-free requires automatic security systems.
  "></ListDetail>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <MainImage path="first_img.png" loading="lazy"></MainImage>
          </div>
        </div>

        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 text-center">
            <MainImage path="second_img.png" loading="lazy"></MainImage>
          </div>
          <div class="col-lg-6">
            <div>
              <ul>

                <ListDetail text_a="Review by experts"
                  text_b="Your contract details and content is reviewed by experts, so errors and omissions are eliminated.">
                </ListDetail>
                <ListDetail text_a="An in-depth analysis report"
                  text_b="Your customers can trust your platform and make decisions based on reports that you check and analyze in advance.">
                </ListDetail>
                <ListDetail text_a="Backup"
                  text_b="In the event of loss or duplication of data, blockchain allows you to restore your original documents and data.">
                </ListDetail>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class=" pathway bg_dark" id="Pathway">
      <div class="container">

        <div class="row">
          <div class="col-lg-6">
            <div class="py-4">
              <TitleA heading_title="" heading_title_b="
  "></TitleA>
              <AllHeadings text3=" Creating a DeFi Smart Contract:" text4="  A Pathway"></AllHeadings>
            </div>

            <p class="text-white">LBM Blockchain Solutions specializes in creating blockchain-enabled smart contracts
              that are efficient, hack-proof, and easy to amend. </p>

            <p class="text-white">As part of its development services, LBM develops, tests, and implements smart
              contract logic. It ensures cyber resilience by reducing gas consumption in its smart contracts.
            </p>

          </div>

          <div class="col-lg-6 position-relative">
            <div class="set_bg_color d-none d-lg-block">
              <ul>
                <li>
                  <span class="first_box text-white ps-3">Analyze</span>

                  <div class="chat_bubble">
                    <p class=" chat_bubble_text text-white">To keep our customers informed, our team conducted in-depth
                      market research and understood the purpose of creating a smart contract.</p>
                  </div>


                </li>
                <li>
                  <span class="first_box text-white ps-3">Design & Develop</span>

                  <div class="chat_bubble">
                    <p class=" chat_bubble_text text-white">DeFi smart contracts are built on the solidity of
                      programming platforms once our team understands your business needs.</p>
                  </div>


                </li>
                <li>
                  <span class="first_box text-white ps-3">Testing & Auditing</span>

                  <div class="chat_bubble">
                    <p class=" chat_bubble_text text-white">We test smart contracts simultaneously at different stages
                      of their development. Our experts never give up until all the bugs and vulnerabilities are fixed.
                    </p>
                  </div>


                </li>
                <li>
                  <span class="first_box text-white ps-3">Launch</span>

                  <div class="chat_bubble">
                    <p class=" chat_bubble_text text-white">We strive to launch the platform within the specified
                      timeframe once our testing and project manager have cleared all suspects on the platform.</p>
                  </div>


                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </section>

    <section class="bg_dark " id="faq">
      <div class="container">
        <div class="row align-items-center justify-content-center row_reverse">
          <div class="col-lg-5">
            <div>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      1. Which language is used for smart contracts ?
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Using C#, contracts are wrapped in web-based front ends</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      2. When learning smart contracts, how long
                      does it take ?
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>The course can be completed in two months if you put in 10 hours a week. You will learn how to
                        design and implement smart contracts as well as how to develop decentralized applications on the
                        blockchain.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      3. Does blockchain work best with a particular
                      language ?
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Globally, Solidity is the most popular and stable Blockchain Programming language.</p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      4. Is it possible to learn blockchain without coding ?
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>If you want to work in non-technical roles in the blockchain industry, you can learn blockchain
                        without coding. This does not imply you need to grasp advanced maths behind cryptography or
                        cryptology, but it implies you need to understand the various technologies that make use of
                        blockchain.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 px-lg-4">
            <div class="py-4">

              <AllHeadings text3="Frequently Asked " text4="Questions"></AllHeadings>
            </div>

            <p class="text-white">LBM Blockchain Solutions specializes in creating blockchain-enabled smart contracts
              that are efficient, hack-proof, and easy to amend. </p>
            <p class="text-white">As part of its development services, LBM develops, tests, and implements smart
              contract logic. It ensures cyber resilience by reducing gas consumption in its smart contracts.
            </p>
          </div>
        </div>
      </div>
    </section>

    <LbmFooter />

  </div>
</template>
  
<script>


import MainImage from '../components/MainImage.vue'
import ListDetail from '../components/ListDetail.vue'
import AllHeadings from '@/components/AllHeadings.vue';
import AllButton from '@/components/AllButton.vue';
import SmartContract_a from '@/components/SmartContract_a.vue';
import SmartContract_b from '@/components/SmartContract_b.vue';
import LbmFooter from '@/components/LbmFooter.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'HomeView',
  components: {
    MainImage,
    ListDetail,
    AllHeadings,
    AllButton,
    SmartContract_a,
    SmartContract_b,
    LbmFooter,
    NavBar
  },

  data() {
    return {
      data_a: [{
        heading: "NFT Platform",
        text: "We make NFT Platform that is responsive to DeFi smart contracts to enjoy unrivaled market growth."
      },
      {
        heading: "P2E Platform Development",
        text: "By combining the experience of experienced game developers with blockchain technology, we offer full-cycle P2E game development."
      },
      {
        heading: "Centralized Trading Platform",
        text: "Boost your centralized trading platform's performance with our advanced ERC-20 DeFi smart contract development services."
      },
      {
        heading: "Robinhood Clone",
        text: "Use our tailored smart contract services to streamline your Robinhood Clone's buying and selling processes."
      },
      {
        heading: "Cash App Clone",
        text: "We ensure that your digital contracts are executed automatically and error-free by our team of blockchain tech experts."
      },
      {
        heading: "Crypto Exchange",
        text: "Our blockchain and crypto experts will work closely with you to eliminate fraud risk through the use of smart contracts."
      }
      ],

      data_b: [{
        path: "services_a.png",
        heading: "Crowdsale/Token Contract",
        text: "Before delivering your platform, we test it on different platforms, protocols, and tools to ensure that it is 100% secure."
      },
      {
        path: "services_b.png",
        heading: "Custom Smart Contract",
        text: "In addition to designing, developing, and auditing complex smart contracts, our team also makes sure they meet various industry norms and are easy to implement."
      },
      {
        path: "services_c.png",
        heading: "Blockchain wallet and Dapps",
        text: "To safeguard your wallet and Dapps from hackers, our blockchain and cyber-security team assesses them against all cybersecurity norms."
      },
      {
        path: "services_d.png",
        heading: "Blockchain Protocol",
        text: "The blockchain confirming configuration & consensus algorithm ensures security and correct implementations in one place."
      }
      ]

    }
  }

}
</script>
  
<style scoped>





.main-banner .caption {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
}

span.badge_title {
  background-color: #020f1f;
  ;
  padding: 10px 20px;
  border: 1px solid #0964F3;
}

.main_content h1 {

  font-size: 70px !important;
}

.main_content h1:nth-child(2) {
  color: #5ef0ff6e;
  -webkit-text-stroke: 1px white;
  -webkit-text-stroke: 1px white;
}

.box_technology {
  background-color: #121212;
  padding: 10px 10px;
  text-align: center;
  height: 100%;
  border-bottom: 4px solid #B2B2B2;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  cursor: pointer;
}

.box_technology h4 {
  margin: 15px 0;
  color: white;
}

.box_technology p {
  color: #B2B2B2;

}

.box_technology:hover p {
  color: black;
}

.box_technology:hover {
  background-color: #FFFFFF;
  border-bottom: 4px solid #FFFFFF;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.box_technology:hover h4 {
  color: #0964F3;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.sercices_box .services_content h1 {
  color: white;
  font-size: 25px !important;
}

.sercices_box .services_content p {
  color: #B2B2B2;
}

.sercices_box {
  background-color: #0d0d0d;
  padding: 20px 35px;
  height: 100%;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;

}

.services_icon span {
  display: inline-block;
  border: 1px solid white;
  width: 200px;
  height: 200px;
  border-radius: 5px;

}

.services_icon span img {
  margin: 30px auto;
  display: block;
}

.sercices_box {
  border: 1px solid #0d0d0d;
}

.sercices_box:hover {
  border: 1px solid #0964F3;
}

.sercices_box:hover .services_icon span {
  border: 1px solid transparent;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.sercices_box:hover .services_content h1 {
  color: #0964F3;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

/* *****faq******* */
.accordion-item {
  color: white;
  background-color: #0d0d0d;
  border: none;
  margin: 15px 0;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/images/plus.png) !important;

}

.accordion-button::after {
  background-image: url(../assets/images/minus.png);
}

.accordion-button {
  color: white;
  background-color: transparent;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

/* *****faq end******* */

/* chat bubble */
p.chat_bubble_text {
  text-align: justify;
  padding: 10px 7px;
}

.chat_bubble {
  background-image: url(../assets/images/shape_bubble1.png);
  background-size: contain;
  padding: 16px 12px;
  background-repeat: no-repeat;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;

}

.pathway ul li:first-child .first_box {
  position: relative;
  background-color: #0C1B26;
  padding: 15px 10px;
  display: inline-block;
  width: 75%;

}

.pathway ul li span.first_box::before {
  position: absolute;
  content: '';
  background-color: #4A565E;
  height: 26px;
  width: 4px;
  left: 0;
  top: 13px;
  border-radius: 5px;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}


.pathway ul li:first-child .chat_bubble,
.pathway ul li:nth-child(3) .chat_bubble,
.pathway ul li:nth-child(4) .chat_bubble {
  z-index: 999;
  display: none;
  position: absolute;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:first-child {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 53%;
  z-index: 10;
  top: 15%;
}

.pathway ul li:first-child:hover .chat_bubble,
.pathway ul li:nth-child(3):hover .chat_bubble {
  display: block !important;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:first-child:hover span.first_box::before,
.pathway ul li:nth-child(3):hover span.first_box::before {
  background-color: #0964F3;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:nth-child(3) {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 54%;
  z-index: 8;
  top: 60%;

}

.pathway ul li:nth-child(3) .first_box {
  position: relative;
  background-color: #0C1B26;
  padding: 15px 10px;
  display: inline-block;
  width: 75%;
}







.pathway ul li:nth-child(2) .first_box {
  position: relative;
  background-color: #0C1B26;
  padding: 15px 10px;
  display: inline-block;
  width: 75%;

}




.pathway ul li:nth-child(2) .chat_bubble {
  z-index: 999;
  display: none;
  position: absolute;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:nth-child(2) {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 50%;
  z-index: 9;
  left: 10%;
  top: 40%;
}

.pathway ul li:nth-child(2):hover .chat_bubble,
.pathway ul li:nth-child(4):hover .chat_bubble {
  display: block !important;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:nth-child(2):hover span.first_box::before,
.pathway ul li:nth-child(4):hover span.first_box::before {
  background-color: #0964F3;
  transition: .5s ease-in-out;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.pathway ul li:nth-child(4) {
  display: inline-block;
  position: absolute;
  right: 0;
  width: 53%;
  z-index: 7;
  left: 10%;
  top: 85%;
}

.pathway ul li:nth-child(4) .first_box {
  position: relative;
  background-color: #0C1B26;
  padding: 15px 10px;
  display: inline-block;
  width: 75%;
}

.set_bg_color {
  background-color: #141414;
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

.box_a {
  display: inline-block;
  background-color: #13191A;
  padding: 10px 15px;
  width: 65%;
  border-radius: 25px;
  text-align: center;
  border: 1px solid white;
  margin: 15px 0;
}


.line_a {
  background-image: url(@/assets/images/shape_d.png);
  height: 113px;
  width: 153px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 1%;
  top: 44%;
}

.line_b {
  background-image: url(@/assets/images/shape_a.png);
  height: 221px;
  width: 390px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -72%;
  right: 7%;
}

.line_c {
  background-image: url(@/assets/images/shape_c.png);
  height: 143px;
  width: 327px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -198%;
  right: 43%;
}

.line_d {
  background-image: url(@/assets/images/shape_e.png);
  height: 128px;
  width: 265px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -136%;
  right: 29%;
}

.line_e {
  background-image: url(@/assets/images/shape_b.png);
  height: 233px;
  width: 116px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -178%;
  right: 66%;
}

.lbm_solution {
  background-image: url(../assets/images/bg_lbm.png);
  background-size: 100% 100%;
  padding: 25px 0px;
}

/* media query */

@media only screen and (max-width: 425px) and (min-width: 320px) {



  .main_content h1 {
    font-size: 25px !important;
  }

  .row.justify-content-center.Smart_cont[data-v-24d504b1] {
    position: absolute;
    top: 0;
    /* margin: 0 auto; */
    /* text-align: center; */
    left: 0;
    right: 0;
  }

}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .main_content h1 {
    font-size: 35px;
  }

  a.navbar-brand {
    width: 300px;
  }

  .row.justify-content-center.Smart_cont[data-v-24d504b1] {
    position: absolute;
    top: 0;
    /* margin: 0 auto; */
    /* text-align: center; */
    left: 0;
    right: 0;
  }
}

@media (max-width: 768px) {


  span.badge_title {
    all: unset;
  }

  .row.justify-content-center.Smart_cont[data-v-24d504b1] {
    position: absolute;
    top: 0;
    /* margin: 0 auto; */
    /* text-align: center; */
    left: 0;
    right: 0;
  }



}

@media only screen and (max-width: 991px) and (min-width: 320px) {

  span.line_b,
  span.line_a,
  span.line_c,
  span.line_e,
  span.line_d {
    display: none !important;
  }

  .parent_a {
    text-align: center;
  }

  .row_reverse {
    flex-direction: column-reverse;
  }

  .sercices_box {
    text-align: center;
  }

  .pathway ul li:first-child {
    all: unset;
    z-index: 10;
  }

  .pathway ul li:nth-child(2) {
    all: unset;
    z-index: 9;

  }

  .pathway ul li:nth-child(3) {
    all: unset;
    z-index: 8;

  }

  .pathway ul li:nth-child(4) {
    all: unset;
    z-index: 7;

  }


  .chat_bubble {
    width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }


  .row.justify-content-center.Smart_cont[data-v-24d504b1] {
    position: absolute;
    top: 0;
    /* margin: 0 auto; */
    /* text-align: center; */
    left: 0;
    right: 0;
  }



}



.img_show {
  display: none;
}

.img_hide,
.img_show {
  width: 100px;
  height: 100px;
}

.contract_box:hover .text_show p {
  visibility: visible;
}

.contract_box .text_show {
  visibility: hidden;
  position: absolute;
}

.contract_box {
  position: relative;
  background-color: #26262c;

}

.srt_size {
  white-space: pre-line;
}

section {
  padding: 45px 0;
}

.accordion-item {
  color: white;
  background-color: #181616;
  border: none;
  margin: 15px 0;
}
.ss_img {
    max-width: 70%;
}
@media screen and (min-device-width: 992px) and (max-device-width: 1105px) {

  span.badge_title {
    font-size: 13px !important;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1024px){
  .line_c {

    top: -147%;
    right: 31%;
}
.line_d {
  top: -130%;
    right: 38%;
    width: 215px;
}
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1399px){
  .line_e{
    top: -175%;
    right: 56%;
}
}

@media screen and (max-width: 1399px) {

  .pathway ul li:first-child:hover .chat_bubble,
  .pathway ul li:nth-child(3):hover .chat_bubble,
  .pathway ul li:nth-child(2):hover .chat_bubble,
  .pathway ul li:nth-child(4):hover .chat_bubble {
    display: none !important;
  }






}
section#home{
  background-image: url(../assets/video/smart_contract.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    padding: 0;
}
section#home::after {
    position: absolute;
    background-color: #0000006e;
    content: '';
    height: 100%;
    width: 100%;
}
</style>
  