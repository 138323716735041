<template>
<div>
    <div class="main_section">
        <h1 class="fw-bold text-white mb-2"><span class="main_sec_head">{{text1}}</span> {{text2}}</h1>
        <span class="fs-5  fw-semibold text-white">{{text}}</span>
        <p class="fs-5 text-white pt-2">{{text3}}</p>           
    </div>
</div>
</template>

<script>
export default {
  name: 'Section_Heading',
  props:{
    text1:String,
    text2:String,
    text3:String,
    text:String
  }
}
</script>
<style scoped>
.main_section h1{
  font-size: 80px !important;
}
.main_section span.main_sec_head{
  color:#081A38;
  -webkit-text-fill-color: #081A38;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

/* Media Query */
@media (max-width:767px) {

.main_section h1{
  font-size: 27px !important;
}
.main_section p {
  font-size: 16px !important;
}
}
@media(min-width: 768px) and (max-width: 991px) {
  .main_section h1{
  font-size: 45px !important;
}
.main_section p {
  font-size: 18px !important;
}
}
@media(min-width: 992px) and (max-width: 1199px) {
  .main_section h1 {
    font-size: 47px !important;
}
}
@media(min-width: 1200px) and (max-width: 1399px) {
  .main_section h1{
    font-size: 56px !important;
}
}
@media(min-width: 1400px) and (max-width: 1600px) {
  .main_section h1{
    font-size: 63px !important;
}
}
</style>