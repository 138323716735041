<template>
    <div>
        <div class="heading">
            <h3 class="fw-bold text-white">{{data}}</h3>          
        </div>
    </div>
    </template>
    <script>
    export default {
      name: 'Terms_heading',
      props:{
        data:String,
      },
     
    }
    </script>
    <style scoped>
    .heading h2{
        font-size: 43px;
    }
    
    .heading h3 {
    border-bottom: 1px solid #fff;
    display: inline-block;
    padding-bottom: 5px;
}
    
    /* Media Query */
    
    @media (max-width:767px) {
    
    .heading{
      text-align: center !important;
    }
    }
    @media(min-width: 320px) and (max-width: 575px) {
      .heading h3{
        font-size: 24px !important;
    }
    
    }
    @media(min-width: 576px) and (max-width: 767px) {
      .heading h3{
        font-size: 26px !important;
    }
    }
    @media(min-width: 768px) and (max-width: 991px) {
      .heading h3{
        font-size: 28px !important;
    }
    }
    @media(min-width: 992px) and (max-width: 1199px) {
      .heading h3{
        font-size: 38px !important;
    }
    }
    </style>