<template>
<div>
  <div class="bg_img">
    <NavBar />

    <section class="home set_padding_play_earn" id="home">
      <div class="container">
        <div class="row align-items-center row_reverse flex-column-reverse flex-md-row">
          <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="home_text text-center text-md-start">
              <PlayEarnHeading text1="THE FUTURE OF" text2="GAMING IS HERE" paraline="Free, Fun & Fair Rewards For Everyone">
              </PlayEarnHeading>
              <AllButton text="PLAY NOW" />
            </div>
          </div>

          <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="image">
              <video id="myVideo" autoplay="" loop="" muted="" playsinline="">
                <source src="@/assets/video/play_earn.mp4" loading="lazy"/>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gaming_options py-5">
      <div class="container p-lg-0">
        <div class="row justify-content-center align-items-center">
          <div class="col-sm-12 col-md-6 col-lg-4 text-center text-md-start" v-for="(data_a, index) in data_a" :key="index">
            <div class="card mb-3 mb-md-0 bg-transparent align-items-center border_right no_border_right" style="max-width: 540px">
              <div class="row g-0 align-items-center">
                <div class="col-md-3">
                  <div class="img_a">
                    <img :src="
                          require(`@/assets/playearn_elements/img/${data_a.images}`)
                        " alt="" class="img-fluid" loading="lazy"/>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="card-body p-0">
                    <h3 class="mb-4 blue_color p-0 fw-bold">
                      {{ data_a.heading }}
                    </h3>
                    <p class="m-0 para_b text-white montserrat">
                      {{ data_a.para }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="gaming_features">
    <section class="gaming_sections_a">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 text-center text-md-start mb-5 mb-md-start">
            <div class="innter_text pe-lg-5">
              <AllHeadings text3="Seamless Intergrations " text4="With Web3 Apps" text5="Our Expert Team Will integrate all the Games to Web3
                             Apps and wallets like Metamask, Tronlink pro, trust wallet etc. with the integration your user can easily pay and Earn
                              Rewards, Where the tokens and NFTs are Directly Credited To User Wallet. Users can also 
                              Use Their NFTs from the Dapps On your Platform" btn="Play Now">
              </AllHeadings>
              <AllButton text="PLAY NOW" />
            </div>
          </div>
          <div class="col-md-6 col-lg-6 text-center">
            <img src="@/assets/playearn_elements/img/gaming_img_a.png" alt="" class="img-fluid" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>

    <section class="gaming_sections_b">
      <div class="container">
        <div class="row align-items-center justify-content-between flex-column-reverse flex-md-row">
          <div class="col-md-6 col-lg-6 text-center">
            <img src="@/assets/playearn_elements/img/gaming_img_b.png" alt="" class="img-fluid" loading="lazy"/>
            <!-- <video id="myVideo" autoplay="" loop="" muted="" playsinline="">
                                <source src="@/assets/playearn_elements/video/rocket_a.mp4">
                            </video> -->
          </div>
          <div class="col-md-6 col-lg-5 text-center text-md-start">
            <AllHeadings text3="Why " text4="Choose Us?" text5="If you are looking to build a metaverse gaming platform and want to help develop your own world-class video game, 
                                then LBM Solutions is the right place for that! Whether it is Theme selection, NFT creation, Avatar building, or anything in 
                                between, our team of experts will provide comprehensive consulting services tailored to each client's unique specifications. In addition, we provide 
                                strategic advice which can lead toward launching an exceptional metaverse game.">
            </AllHeadings>
          </div>
        </div>
      </div>
    </section>

    <section class="gaming_sections_c">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6 col-lg-5 text-center text-md-start">
            <AllHeadings text3="GameFi " text4="Metaverse" text5="The Game is based on lands and real estate. This is the most popular Game in the metaverse, where users can buy a piece of land in the form of NFTs. and He can Build their own city , home or building which can be used by other users. 

                              Use Their NFTs from the Dapps On your Platform"></AllHeadings>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 text-center">
            <img src="@/assets/playearn_elements/img/gaming_img_c.png" alt="" class="img-fluid" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>
    <section class="gaming_sections_d">
      <div class="container">
        <div class="row align-items-center justify-content-between flex-column-reverse flex-md-row">
          <div class="col-md-6 col-lg-6 text-center">
            <img src="@/assets/playearn_elements/img/gaming_img_d.png" alt="" class="img-fluid" loading="lazy"/>
          </div>
          <div class="col-md-6 col-lg-5 text-center text-md-start">
            <AllHeadings text3="LandFi " text4="Metaverse" text5="You can Launch your own move to earn based project, Where users Have to do some kind of physical activities and play the games like running, cycling walking etc and users can earn rewards in the form of Tokens .">
            </AllHeadings>
          </div>
        </div>
      </div>
    </section>

    <!-- check section (&) sign -->
    <section class="gaming_sections_e">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6 col-lg-5 text-center text-md-start">
            <AllHeadings text3="Move " text4="Earn Project" text5="The Game is based on lands and real estate. This is the most& popular Game in the metaverse, where users can buy a piece of land in the form of NFTs. and He can Build their own city , home or building which can be used by other users ">
            </AllHeadings>
          </div>
          <div class="col-md-6 col-lg-6 text-center">
            <img src="@/assets/playearn_elements/img/gaming_img_e.png" alt="" class="img-fluid" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- slider_main_bg -->
  <div class="slider_main_bg">
    <section class="slider_section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <AllHeadings text3="Featured " text4="Games" text5="To meet today's challenges & earn cryptocurrency">
            </AllHeadings>
          </div>
        </div>
        <div class="row mt-md-5">
          <div class="col-lg-12">
            <!-- using the swiper slider  -->
            <SwiperSlider />
          </div>
        </div>
      </div>
    </section>

    <section class="abailable_gaming">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <AllHeadings text3="
Available " text4="Games" text5="To meet today's challenges & earn cryptocurrency">
            </AllHeadings>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-9 col-md-4 col-lg-4 my-4 text-center text-md-start" v-for="(data_b, index) in data_b" :key="index">
            <div class="inner_img">
              <img :src="
                    require(`@/assets/playearn_elements/img/${data_b.images}`)
                  " alt="" class="img-fluid" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- slider_main_bg -->

  <!-- section_working -->
  <section class="working">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <AllHeadings text3="How We " text4="Works?" text5="It's easier than you think. Follow 4 simple easy steps">
          </AllHeadings>
        </div>
      </div>
      <div class="row justify-content-center pt-4">
        <div class="col-sm-6 col-md-3 col-lg-3 mb-3 mb-md-start" v-for="(data_c, index) in data_c" :key="index">
          <div class="innter_text position-relative text-center">
            <h1 class="text-white fw-bold d-inline opacity-50">
              {{ data_c.number }}
            </h1>
            <div class="layer_a">
              <img :src="
                    require(`@/assets/playearn_elements/svg_files/${data_c.svg}`)
                  " alt="" class="img-fluid svg_img p-3" loading="lazy"/>
            </div>
            <h4 class="text-white fw-bold mt-4">{{ data_c.text }}</h4>
          </div>
        </div>
      </div>

      <div class="row mt-5 pt-md-5">
        <div class="col-md-6 col-lg-6 text-center text-md-start">
          <AllHeadings text3="Level!Up Get " text4="Rewards" text5="Each time you reach a new level you'll get a reward">
          </AllHeadings>

          <div class="row reward_section align-items-center justify-content-center mt-4">
            <div class="col-sm-4 col-md-4 col-lg-4 text-center mb-4 mb-md-start" v-for="(data_d, index) in data_d" :key="index">
              <img :src="require(`@/assets/playearn_elements/img/${data_d.img}`)" alt="" class="img-fluid reward_img" loading="lazy"/>
              <h4 class="text-white fw-bold mt-4">{{ data_d.text }}</h4>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 text-center mt-5 mt-md-0">
          <!-- <video autoplay="" loop="" muted="" playsinline="" id="myVideo_a">
                            <source
                                src="@/assets/playearn_elements/video/gun_animation.mp4"
                                type="video/mp4" class="img-fluid">
                            Your browser does not support the video tag.
                        </video> -->
          <img src="@/assets/playearn_elements/img/gun_man.png" alt="" class="img-fluid" loading="lazy"/>
        </div>
      </div>
    </div>
  </section>
  <!-- section_working -->

  <div class="blockchain_bg">
    <section class="scan_optoins">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <AllHeadings text3="Supported " text4="Blockchains" text5="">
            </AllHeadings>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-10 col-sm-6 col-md-3 col-lg-3 text-center mb-4 mb-md-start" v-for="(data_e, index) in data_e" :key="index">
            <div class="text_layer text-center rounded-3 p-4">
              <img :src="
                    require(`@/assets/playearn_elements/img/${data_e.images}`)
                  " alt="" class="img-fluid w-75" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="accordion">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <AllHeadings text3="Our Most Common " text4="Questions" text5="Do you have a question about LBM? Please contact us! We would love to answer your questions.">
            </AllHeadings>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-12">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item mb-4">
                <p class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <div class="d-flex">
                      <div>
                        <span class="iconify text-white bg-white bg-opacity-25 rounded-5 p-1 fs-2 me-2" data-icon="ci:help-questionmark"></span>
                      </div>
                      <div>
                        <p class="text-white fw-semibold p_heading m-0">
                          What is a metaverse gaming ?
                        </p>
                      </div>
                    </div>
                  </button>
                </p>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-white px-md-5">
                      It's no secret that the world of gaming is evolving.
                      With advances in technology, we're seeing more and more
                      lifelike experiences. And one recent development is the
                      rise of metaverse gaming. Metaverse games are virtual
                      reality (VR) applications that allow players to interact
                      with each other in a shared online space, known as the
                      virtual worlds. This new genre has the potential to
                      change the way we play games forever.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-4">
                <p class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <div class="d-flex">
                      <div>
                        <span class="iconify text-white bg-white bg-opacity-25 rounded-5 p-1 fs-2 me-2" data-icon="ci:help-questionmark"></span>
                      </div>
                      <div>
                        <p class="text-white fw-semibold p_heading m-0">
                          How can i earn money by playing games in metaverse?
                        </p>
                      </div>
                    </div>
                  </button>
                </p>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-white px-md-5">
                      Players can earn money by playing games in a variety of
                      ways. For example, they may be awarded prizes for
                      reaching certain levels or completing tasks within the
                      game. They may also be able to sell virtual assets that
                      they have acquired within the game. In addition, some
                      games allow players to convert their winnings into
                      real-world currency.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item mb-4">
                <p class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <div class="d-flex">
                      <div>
                        <span class="iconify text-white bg-white bg-opacity-25 rounded-5 p-1 fs-2 me-2" data-icon="ci:help-questionmark"></span>
                      </div>
                      <div>
                        <p class="text-white fw-semibold p_heading m-0">
                          What are the other benefits, other than earning
                          money in play-to-earn games?
                        </p>
                      </div>
                    </div>
                  </button>
                </p>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-white px-md-5">
                      First, it allows players to inhabit real-time, 3D worlds
                      that are convincingly lifelike. Second, metaverse games
                      provide a sense of presence and community that
                      traditional gamers often crave. In-game interactions
                      with other players will be more natural and fulfilling
                      as an experience to the gamers.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <p class="accordion-header" id="headingfour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                    <div class="d-flex">
                      <div>
                        <span class="iconify text-white bg-white bg-opacity-25 rounded-5 p-1 fs-2 me-2" data-icon="ci:help-questionmark"></span>
                      </div>
                      <div>
                        <p class="text-white fw-semibold p_heading m-0">
                          Why is it called as the future of gaming?
                        </p>
                      </div>
                    </div>
                  </button>
                </p>
                <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p class="text-white px-md-5">
                      Metaverse gaming is the future of online gaming. It
                      refers to a virtual world that players can explore and
                      interact with. The benefits of metaverse gaming are
                      numerous. For one, it allows gamers to be immersed in a
                      completely customizable and interactive world. They can
                      choose to play as themselves or create an alter ego, and
                      engage with other players in a variety of ways. There
                      are no limits to what you can do or where you can go in
                      a metaverse – the sky is literally the limit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="subscribe pt-5">
      <div class="container">
        <div class="row inner_layer align-items-center justify-content-center p-3 px-md-4 py-md-5">
          <div class="col-md-12 col-lg-5 text-center text-lg-start">
            <div class="inner_text">
              <div class="d-lg-flex align-items-center justify-content-between">
                <div class="">
                  <img src="@/assets/playearn_elements/img/subs_logo.png" alt="" class="img-fluid news_logo" loading="lazy"/>
                </div>
                <div class="ms-4">
                  <h2 class="text-white fw-bold fs-1 lh-base">
                    Subscribe To Our News Letter
                  </h2>
                  <p class="text-white">
                    Receive news, stay updated and special offers
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-lg-6 text-center text-lg-start">
            <div class="input_bg d-sm-flex justify-content-between align-items-center mb-3 mb-sm-start m-0">
              <div class="input">
                <input type="text" placeholder="Enter email address" class="ms-3 bg-transparent border-0 text-white py-3 pe-md-4" />
              </div>
              <div class="input_btn">
                <button class="bg-transparent border-0 text-white">
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <LbmFooter />
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import LbmFooter from "@/components/LbmFooter.vue";
import PlayEarnHeading from "@/components/PlayEarnHeading.vue";
import AllButton from "@/components/AllButton.vue";
import AllHeadings from "@/components/AllHeadings.vue";
import SwiperSlider from "@/components/SwiperSlider.vue";

export default {
  name: "PlayEarn",
  components: {
    NavBar,
    LbmFooter,
    PlayEarnHeading,
    AllButton,
    AllHeadings,
    SwiperSlider,
  },

  data() {
    return {
      data_a: [{
          images: "opt_img_a.png",
          heading: "Pay-to-Play Games",
          para: "Or this type of game, you need first to purchase at least a gaming item, such as a character or a weapon.",
        },
        {
          images: "opt_img_b.png",
          heading: "Free-to-Play or Play-to-Earn Games",
          para: "For this type, you dont need to make kany purchase or make an initial Payment Before Starting the Gamepad.",
        },
        {
          images: "opt_img_c.png",
          heading: "Mining Games",
          para: "You are rewarded with coins for this type of blockchain game when you complete certain tasks.",
        },
      ],
      data_b: [{
          images: "abailable_img_a.png",
        },
        {
          images: "abailable_img_b.png",
        },
        {
          images: "abailable_img_c.png",
        },
        {
          images: "abailable_img_d.png",
        },
        {
          images: "abailable_img_e.png",
        },
        {
          images: "abailable_img_f.png",
        },
      ],
      data_c: [{
          number: "01",
          svg: "icon_a.svg",
          text: "SIGNUP",
        },
        {
          number: "02",
          svg: "icon_b.svg",
          text: "DEPOSIT",
        },
        {
          number: "03",
          svg: "icon_c.svg",
          text: "COMPETE",
        },
        {
          number: "04",
          svg: "icon_d.svg",
          text: "GET PAID",
        },
      ],
      data_d: [{
          img: "reward_img_a.png",
          text: "FREESPINS",
        },
        {
          img: "reward_img_b.png",
          text: "BONUSES",
        },
        {
          img: "reward_img_c.png",
          text: "TOURNAMENTS",
        },
      ],
      data_e: [{
          images: "blockchain_img_a.png",
        },
        {
          images: "blockchain_img_b.png",
        },
        {
          images: "blockchain_img_c.png",
        },
        {
          images: "blockchain_img_d.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
section {
  padding: 60px 0;
}

section.home p {
  margin: 30px 0 50px;
}

/* 
.montserrat {
    font-size: 16px;
} */

.bg_img {
  background-image: url("@/assets/playearn_elements/img/main_bg.png");
  background-size: 100% 100%;
}

section#home {
  padding-top: 139px;
}

video#myVideo {
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
}

section.gaming_options .col-lg-4:nth-child(2) {
  padding: 0;
}

/* gaming-option main section bg_img */
.gaming_features {
  background-image: url("@/assets/playearn_elements/img/gaming_img_bg.png");
}

/* section.gaming-option */
section.gaming_options {
  background-image: url("@/assets/playearn_elements/img/list_prizebg.png");
  background-size: 100% 100%;
}

section.gaming_options h3 {
  color: #fff;
}

section.gaming_options .col-lg-4:nth-child(1),
section.gaming_options .col-lg-4:nth-child(2) {
  border-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-image: linear-gradient(#060606, #a1a1a1, #050505);
  border-image-slice: 100%;
  border-image-slice: 1;
  border-left: transparent;
  border-top: transparent;
  border-bottom: transparent;
}

/* gaming-option main section bg_img */

/* next-slider_main_bg */

.slider_main_bg {
  background-image: url("@/assets/playearn_elements/img/feature_imb_bg.png");
}

/* next-slider_main_bg */

/* section working */
section.working {
  background-size: 100% 100%;
  background-image: url(@/assets/playearn_elements/img/working_img.png);
}

section.working .innter_text .layer_a {
  background-image: url(@/assets/playearn_elements/img/logo_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

section.working .innter_text h1 {
  background: linear-gradient(180deg, #d19606 0%, #e9cc32 26.3%, #d19606 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  top: 20px;
  left: -106px;
}

section.working .innter_text .svg_img {
  max-width: 120px;
}

.working .col-lg-3:nth-child(odd) .innter_text:before {
  content: "";
  position: absolute;
  top: 0;
  width: 120px;
  height: 40px;
  right: -64px;
  margin: auto 0;
  bottom: 0;
  background-image: url(@/assets/playearn_elements/img/arrow_b.png);
  background-repeat: no-repeat;
}

.working .col-lg-3:nth-child(2) .innter_text:before {
  content: "";
  position: absolute;
  top: 0;
  width: 120px;
  height: 40px;
  right: -64px;
  margin: auto 0;
  bottom: 0;
  background-image: url(@/assets/playearn_elements/img/arrow_a.png);
  background-repeat: no-repeat;
}

/*start reward_section */
.reward_section .reward_img {
  width: 60%;
}

/*End reward_section */

/* section working */

/* blockchain_bg */

.blockchain_bg {
  background-image: url("@/assets/playearn_elements/img/blockchain_bg.png");
}

.scan_optoins .text_layer {
  background-color: #171f3e;
  border: 1px solid #323b57;
}

/* .section.accordion */
.accordion-button .p_heading {
  font-size: 22px;
}

section.accordion .para {
  padding: 0 175px !important;
}

.accordion-item {
  background: rgba(9, 100, 243, 0.18) !important;
  border-radius: 7px !important;
  border: 1px solid #152f60 !important;
}

.accordion-button {
  background: rgba(9, 100, 243, 0) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(@/assets/playearn_elements/img/acc_btn_a.png) !important;
}

.accordion-button::after {
  background-image: url(@/assets/playearn_elements/img/acc_btn_b.png) !important;
}

/* .accordion-item:first-of-type .accordion-button {

background: yellow;
} */
.accordion-button:not(.collapsed) {
  box-shadow: unset !important;
}

section.subscribe .inner_layer {
  background-image: url(@/assets/playearn_elements/img/subs_bgimg.png);
  background-size: 100% 100%;
}

section.subscribe .input_bg {
  background-image: url(@/assets/playearn_elements/img/input_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

input {
  outline: none;
}

.input_btn {

  background-image: url(../assets/playearn_elements/img/input_btn.png);
  background-size: 100% 100%;
  padding: 15px 34px;
  background-repeat: no-repeat;
}

/* .section.accordion */
/* blockchain_bg */

@media (min-width: 320px) and (max-width: 575px) {

  section.gaming_options .col-lg-4:nth-child(1),
  section.gaming_options .col-lg-4:nth-child(2) {
    border: none;
  }

  .working .col-lg-3:nth-child(2n + 1) .innter_text::before,
  .working .col-lg-3:nth-child(2) .innter_text::before {
    background-image: unset !important;
  }

  /* section.working */
  section.working .innter_text h1 {
    top: unset;
    left: unset;
  }

  /* section.accordion .para */
  section.accordion .para {
    padding: 0 !important;
  }

  /* section.accordion .para */

  /* section.working */

  /* reward_section  */
  .reward_section .reward_img {
    width: 31%;
  }

  /* reward_section  */

  /* section.subscribe */
  section.subscribe .inner_text h2 {
    font-size: 20px !important;
  }

  section.subscribe .inner_text p {
    font-size: 12px !important;
  }

  section.subscribe .inner_text .news_logo {
    max-width: 50px;
  }

  /* section.subscribe */

  .input_btn[data-v-4c027534] {
    background-image: url(http://192.168.11.78:8080/img/input_btn.b60946c5.png);
    background-size: 80% 74%;
    padding: 12px 31px;
    background-repeat: no-repeat;
    position: relative;
    bottom: -10px;
    display: flex;
    justify-content: center;
    background-position: center;
    text-align: center;
  }

  section.subscribe .input_bg[data-v-4c027534] {
    background-size: 100% 46%;
  }

  .accordion-button .p_heading[data-v-4c027534] {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  section.gaming_options .col-lg-4:nth-child(1),
  section.gaming_options .col-lg-4:nth-child(2) {
    border: none;
  }

  .working .col-lg-3:nth-child(2n + 1) .innter_text::before,
  .working .col-lg-3:nth-child(2) .innter_text::before {
    background-image: unset !important;
  }

  /* section.working */
  section.working .innter_text h1 {
    top: unset;
    left: unset;
  }

  /* section.accordion .para */
  section.accordion .para {
    padding: 0 !important;
  }

  /* section.accordion .para */

  /* section.working */

  /* reward_section  */
  .reward_section .reward_img {
    width: 57%;
  }

  .reward_section .reward_img h4 {
    font-size: 19px;
  }

  /* reward_section  */

  /* section.subscribe */
  section.subscribe .inner_text h2 {
    font-size: 20px !important;
  }

  section.subscribe .inner_text p {
    font-size: 12px !important;
  }

  section.subscribe .inner_text .news_logo {
    max-width: 50px;
  }

  /* section.subscribe */
}

@media (min-width: 768px) and (max-width: 991px) {
  section.gaming_options .col-lg-4:nth-child(2) {
    border: none !important;
  }

  section.working .innter_text h1 {
    top: unset;
    left: unset;
  }

  .working .col-lg-3:nth-child(2n + 1) .innter_text::before,
  .working .col-lg-3:nth-child(2) .innter_text::before {
    background-image: unset !important;
  }

  .reward_section h4 {
    font-size: 15px !important;
  }

  /* sectoin.accordion */
  .accordion-button .p_heading {
    font-size: 18px;
  }

  /* sectoin.accordion */
  section.subscribe .inner_text .news_logo[data-v-4c027534] {
    max-width: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .working .col-lg-3:nth-child(2) .innter_text::before {
    background-size: 64% 75%;
    right: -91px;
  }

  .working .col-lg-3:nth-child(2n + 1) .innter_text::before {
    background-size: 64% 75%;
    right: -91px;
  }

  /* sectoin.accordion */
  .accordion-button .p_heading {
    font-size: 20px;
  }

  /* sectoin.accordion */
  section.subscribe .inner_text h2[data-v-4c027534] {
    font-size: 28px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  section.subscribe .inner_text h2[data-v-4c027534] {
    font-size: 26px !important;
  }
}

video#myVideo_a {
  height: 100%;
  width: 100%;
  mix-blend-mode: lighten;
}

@media (min-width: 1400px) {
  section.gaming_options .col-lg-4:nth-child(odd) .card .card-body h3 {
    padding-right: 120px !important;
  }
}

.card {

  --bs-card-border-color: none !important;
}
</style>
