<template>
<div>
    <div class="heading">
        <h2 class="fw-bold text-white">{{text3}}<span class="blue_color">{{text4}}</span></h2>  
        <p class=" para text-white fs-6 py-4 m-0">{{text5}}</p>         
    </div>
</div>
</template>
<script>
export default {
  name: 'AllHeadings',
  props:{
    text3:String,
    text4:String,
    text5:String
  },
 
}
</script>
<style scoped>
.heading h2{
    font-size: 43px;
}



/* Media Query */

@media (max-width:767px) {

.heading{
  text-align: center !important;
}
.heading p {
    font-size: 14px !important;
}
}
@media(min-width: 320px) and (max-width: 575px) {
  .heading h2{
    font-size: 24px !important;
}

}
@media(min-width: 576px) and (max-width: 767px) {
  .heading h2{
    font-size: 26px !important;
}
}
@media(min-width: 768px) and (max-width: 991px) {
  .heading h2{
    font-size: 28px !important;
}
}
@media(min-width: 992px) and (max-width: 1199px) {
  .heading h2{
    font-size: 38px !important;
}
}
</style>