<template>
    <div>
        <div class="main_section">
            <h2 class="fw-bold text-white mb-3"><span class="main_sec_head">{{data}}</span><br> {{data2}}</h2>          
        </div>
    </div>
    </template>
    
    <script>
    export default {
      name: 'PrivacyHead',
      props:{
        data:String,
        data2:String,
      }
    }
    </script>
    <style scoped>
    .main_section h2{
      font-size: 55px !important;
    }
    .main_section span.main_sec_head{
      color:#081A38;
      -webkit-text-fill-color: #081A38;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fff;
    }
    
    /* Media Query */
    @media (max-width:767px) {
    
    .main_section h2{
      font-size: 27px !important;
    }

    }
    @media(min-width: 768px) and (max-width: 991px) {
      .main_section h2{
      font-size: 35px !important;
    }
    }
    @media(min-width: 992px) and (max-width: 1199px) {
      .main_section h2 {
        font-size: 40px !important;
    }
    }
    @media(min-width: 1200px) and (max-width: 1399px) {
      .main_section h2{
        font-size: 45px !important;
    }
    }
    @media(min-width: 1400px) and (max-width: 1600px) {
      .main_section h2{
        font-size: 50px !important;
    }
    }
    </style>