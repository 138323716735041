<template>
    <div class="">
        <NavBar />



        <!-- banner start -->
        <section class="home text-center text-lg-start">
            <div class="container">
                <div class="row align-items-center flex-column-reverse flex-lg-row">
                    <div class="col-lg-6 p-lg-0">
                        <div class="header_content text-lg-start">
                            <!-- <AllHeadings text3="" text4="  " /> -->

                            <div class="banner_heading mb-3 mb-md-5">
                                <h1 class="blue_color fw-bold">Rock the world with <span class="text-white">Digital
                                        Art</span></h1>
                                <p class="text-white m-0 pe-lg-5 me-lg-5 mt-3 mt-md-5">An innovation that offers
                                    everyone who
                                    wants to use digital assets
                                    an opportunity.</p>
                                <p class="text-white m-0 pe-lg-5 me-lg-5">Game-changing technology for everyone looking
                                    to access digital
                                    assets.</p>
                            </div>
                            <div class="custome_button">
                                <AllButton text="Explore More" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-8 col-sm-10 my-5 ">
                        <div class="home_img home_img_main">
                            <img src="@/assets/NFT_Elements/img/banner_img_a.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- banner end -->


        <!-- future start -->

        <section class="py-5 my-lg-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7 text-center">
                        <AllHeadings text3="Future" text4=" of NFTs"
                            text5="Individuals can use NFT to quickly transact with anyone in the world, while businesses can use digital tokens to
                             manage their entire investor base. NFTs have the potential to form the foundation for future economies if given enough time and investment." />
                        <AllHeadings />
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-lg-0 mb-5" v-for="(future,index) in future_data"
                        :key="index">
                        <div class="my-info-box text-center position-relative h-100">
                            <div class="my-info-box-top w-100 position-relative d-inline-flex">
                                <h5
                                    class="small mb-0 position-absolute start-50 top-50 fw-bold translate-middle text-white">
                                    {{future.heading}}<br :class="future.class_add">{{future.heading1}}</h5>
                                <img src="@/assets/NFT_Elements/img/hover_img.png" alt=""
                                    class="info-box-top-hover position-absolute start-50 top-50 translate-middle img-fluid opacity-0">
                            </div>
                            <div class="my-info-box-content d-flex align-items-center justify-content-center p-3 ">
                                <div class="mt-award  py-2">
                                    <p class="mb-0 text-white">{{future.para}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- future end -->





        <!-- What can NFTs start -->

        <section class="pb-5 pt-lg-5 pt-0 my-lg-5 ntf_used">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-10 text-center">
                        <AllHeadings text3="What can " text4=" NFTs be used for?" text5="" />
                        <AllHeadings />
                    </div>
                </div>
                <div class="row align-items-center">
                    <div :class="'col-lg-4 col-md-4 col-sm-6 '+what_can.add_offset"
                        v-for="(what_can, index) in what_can_data" :key="index">
                        <div :class="'future_div text-white text-center mb-4 pb-2 '+what_can_inner.add_class"
                            v-for="(what_can_inner,index) in what_can.what_can_inner_data" :key="index">
                            <div :class="'Future_icon_content text-center  mb-2  gap-3 '+ what_can_inner.add_class1">
                                <img :src="require(`@/assets/playearn_elements/svg_files/${what_can_inner.img}.svg`)"
                                    alt="" class="img-fluid slide_icon mb-4" loading="lazy" />
                                <h4 class="mb-0 text-center">{{what_can_inner.heading}}</h4>
                                <!-- <img :src="require('@/assets/nft_icon/'+what_can_inner.img+'.png')" alt=""> -->
                            </div>
                            <div class="Future_content">
                                <p class="mb-0 opacity-75 text-center">{{what_can_inner.para}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 order-md-2 order-1 center_image_Nft_col text-center text-md-start">
                        <div class="center_image_Nft mb-md-0 mb-4">
                            <video autoplay="" muted="" loop="" width="" playsinline>
                                <source src="@/assets/NFT_Elements/video/video_a.mp4" type="video/m4v">
                                <source src="@/assets/NFT_Elements/video/video_a.mp4" type="video/ogg">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- What can NFTs end -->


        <!-- Top Seller start -->

        <section class="py-5 my-lg-5" id="seller">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 top_seller_heading">
                        <AllHeadings text3="Popular " text4=" Collection"
                            text5="lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptu" />
                        <AllHeadings />
                    </div>

                </div>


                <div class="row">
                    <div class="col-lg-12">
                        <div class="custom_slider">
                            <swiper :slidesPerView="4" :spaceBetween="20" :loop="true" :navigation="true" :pagination="{
                              clickable: true,
                            }" :autoplay="{
                              delay: 2500,
                              disableOnInteraction: false,
                            }" :breakpoints="{
                              '0': {
                                slidesPerView: 2,
                                spaceBetween: 10,
                              },
                              '576': {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              '768': {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                              '992': {
                                slidesPerView: 4,
                                spaceBetween: 20,
                              },
                            }" :modules="modules" class="mySwiper">
                                <swiper-slide v-for="(top_seller,index) in top_seller_data" :key="index">
                                    <div class="slider_inner_div">
                                        <div class="row user_row bg_color align-items-center mb-4 px-1 py-2 mx-0 rounded-4"
                                            v-for="(top_seller_inner,index) in top_seller.top_seller_inner_data"
                                            :key="index">
                                            <div class="col-lg-5">
                                                <div class="user_img">
                                                    <img :src="require('@/assets/NFT_Elements/img/'+top_seller_inner.img+'.png')"
                                                        class="img-fluid w-100">
                                                </div>
                                            </div>
                                            <div class="col-lg-7">
                                                <div class="user_content text-center text-lg-start my-lg-0 mt-3 mb-2 ">
                                                    <h2 class="fs-4 text-white fw-light lh-sm">{{top_seller_inner.name}}
                                                    </h2>
                                                    <h4 class="fs-5 text-white fw-light lh-sm pb-1">626.2 <span
                                                            class="custom_color fw-semibold">Eth</span></h4>
                                                    <ul
                                                        class="mb-0 ps-0 d-flex gap-3 justify-lg-start justify-content-center">
                                                        <li>
                                                            <a class="d-flex align-items-center justify-content-center border border-2 border-secondary custom_color"
                                                                href="javascript:void(0)" tabindex="-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                                    height="20" fill="currentColor"
                                                                    class="bi bi-person custom_color"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="d-flex align-items-center justify-content-center border border-2 border-secondary custom_color"
                                                                href="javascript:void(0)" tabindex="-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                                    height="18" fill="currentColor"
                                                                    class="bi bi-telegram custom_color"
                                                                    viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Top Seller end -->




        <!-- Live Auction start -->

        <section class="py-5 my-lg-5" id="auction">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7 text-center">
                        <AllHeadings text3="Live" text4=" Auction"
                            text5="lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptu" />
                        <AllHeadings />
                    </div>
                </div>
                <span class="blur_shadow"></span>
                <div class="row align-items-center justify-content-center ">
                    <div class="col-lg-4 col-md-6 col-sm-6 " v-for="(live_auction,index) in live_auction_data"
                        :key="index">
                        <div :class="'live_auction_div bg_color shadow mb-4 mb-lg-0 rounded-4'+ live_auction.add_class">
                            <div class="live_auction_div_img position-relative ">
                                <img :src="require('@/assets/NFT_Elements/img/'+live_auction.img+'.png')"
                                    class="img-fluid w-100">
                                <img :src="require('@/assets/NFT_Elements/img/'+live_auction.img1+'.png')"
                                    class="live_auction_img position-absolute rounded-circle border-5 border border-dark">
                            </div>
                            <div class="live_auction_data_outer text-start p-3 pt-4 mt-2">
                                <div class="live_auction_data d-flex justify-content-between">
                                    <div class="live_auction_data_inner">
                                        <h3 class="text-white fw-normal">{{live_auction.name}}</h3>
                                        <h3 class="text-white text-opacity-50 fw-normal">By Davincidante</h3>
                                    </div>
                                    <div class="live_auction_data_inner">
                                        <button
                                            class="p-2 bg-transparent fs-6 fw-normal custom_color bg_border_button border-0 px-3">2.45
                                            Eth</button>
                                        <p class="text-white opacity-25 text-end mb-0 mt-2">10 in Stock</p>
                                    </div>
                                </div>
                                <p class="custom_color"><small><i class="fa-light fa-arrow-down-arrow-up"></i>
                                        &nbsp;<span class="text-white-50">Highest Bid&nbsp;</span> <span>0.02
                                            Eth</span></small></p>
                                <div class="live_auction_data_inner_buttons d-flex justify-content-between gap-3">
                                    <!-- <button
                                        class="custom_button1 border_15 w-50 border-0 gradient_bg d-inline-block fs-5 fw-light text-white p-2 rounded-pill">Place
                                        a Bid</button> -->
                                    <div class="custome_button">
                                        <AllButton text="Place a Bid" />
                                    </div>
                                    <button
                                        class="custom_button w-50  d-inline-block bg-transparent fw-semibold bg_border_button border-0 fw-normal blue_color">View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Live Auction end -->



        <!-- Popular Collection start -->

        <section class="py-5 my-lg-5" id="collection">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 text-center text-lg-start">
                        <AllHeadings text3="Popular" text4=" Collection"
                            text5="lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptu" />
                        <AllHeadings />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="collection_main_div">
                            <swiper :slidesPerView="3" :spaceBetween="20" :loop="true" :navigation="false" :pagination="{
                              clickable: true,
                            }" :autoplay="{
                              delay: 2500,
                              disableOnInteraction: false,
                            }" :breakpoints="{
                              '0': {
                                slidesPerView: 1,
                                spaceBetween: 10,
                              },
                              '576': {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              '768': {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              '992': {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                            }" :modules="modules" class="mySwiper">
                                <swiper-slide v-for="(collection,index) in collection_data" :key="index">
                                    <div class="popular_collection  mb-4"
                                        v-for="(collection_inner,index) in collection.collection_inner_data"
                                        :key="index">
                                        <img :src="require('@/assets/NFT_Elements/img/'+collection_inner.img+'.png')"
                                            alt="">
                                        <div
                                            class="popular_collection_data d-flex justify-content-between p-4 text-white position-relative align-items-center">
                                            <h2 class="fw-normal fs-5 m-0">{{collection_inner.heading}}</h2>
                                            <button tabindex="0"
                                                class="px-4 py-2 rounded-3 border-0 bg-black text-white">28
                                                items</button>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Popular Collection end -->





        <!-- why nft start -->

        <section class="py-5 my-lg-5" id="work">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 mb-5 mb-md-start text-center text-lg-start">
                        <div class="why_nft_content_right text-lg-start mt-4 mt-lg-0">
                            <AllHeadings text3="Why" text4=" NFT?" text5="NFT stands for Non-Fungible Token. It is a unique cryptographic token. A person who has
                                NFT indicates that he has some unique or antique digital artwork that nobody in the
                                world has. NFTs are one-of-a-kind tokens or digital assets that earn revenue. It
                                combines the greatest features of decentralized blockchain technology with non-fungible
                                assets to generate tokens that can be shown to be unique, scarce, and real.NFTs can be
                                used for a variety of applications, including collectibles, gaming, art, digital assets,
                                and tokenizing real-world goods." />
                            <AllHeadings />
                            <h2 class="text-white fw-bold mb-4">Key Points</h2>

                            <ul class="ps-0 mb-0 list-group-numbered">
                                <li class="text-white mb-2" v-for="(key_info,index) in key_data" :key="index">
                                    {{key_info.data}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="row pe-lg-4">
                            <div class="col-lg-6 col-md-6 col-sm-6" v-for="(why_nft,index) in why_nft_data"
                                :key="index">
                                <div class="how_it_work_box_outer w-100 position-relative text-start"
                                    v-for="(why_nft_inner,index) in why_nft.why_nft_inner_data" :key="index">
                                    <div class="how_it_work_box p-4 text-white mb-4">
                                        <div class="how_it_work_box_img_outer d-flex align-items-center gap-3">
                                            <div
                                                class="how_it_work_box_img mb-3 d-flex align-items-center gap-3 justify-content-center">
                                                <!-- <img :src="require(`@/assets/playearn_elements/svg_files/${why_nft_inner.img}.svg`)"
                                                    alt="" class="img-fluid slide_icon mb-4" loading="lazy" /> -->

                                                <img :src="require('@/assets/playearn_elements/svg_files/'+why_nft_inner.img+'.svg')"
                                                    alt="">
                                            </div>
                                            <h4 class="mb-3 fw-semibold">{{why_nft_inner.heading}}</h4>
                                        </div>
                                        <div class="how_it_work_box_content">
                                            <p class="mb-0 opacity-75 small lh-base">{{why_nft_inner.para}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!-- why nft end -->



        <!-- Artworks start -->

        <section class="py-5 my-lg-5" id="artworks">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <HeadingMain add_class_style="text-lg-start" add_class="me-auto ms-lg-0 ms-auto"
                            heading="Explore Artworks" img="5"
                            para="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.">
                        </HeadingMain>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs mb-5" id="myTab" role="tablist">
                            <li class="nav-item w-100" role="presentation">
                                <button
                                    class="nav-link text-white w-100 border-start-0 border-end-0 border-bottom border-2 border-secondary border-top-0 text-nowrap active"
                                    id="Recommended-tab" data-bs-toggle="tab" data-bs-target="#Recommended"
                                    type="button" role="tab" aria-controls="Recommended"
                                    aria-selected="true">Recommended</button>
                            </li>
                            <li class="nav-item w-100" role="presentation">
                                <button
                                    class="nav-link text-white w-100 border-start-0 border-end-0 border-bottom border-2 border-secondary border-top-0 text-nowrap"
                                    id="Music-tab" data-bs-toggle="tab" data-bs-target="#Music" type="button" role="tab"
                                    aria-controls="Music" aria-selected="false">Music</button>
                            </li>
                            <li class="nav-item w-100" role="presentation">
                                <button
                                    class="nav-link text-white w-100 border-start-0 border-end-0 border-bottom border-2 border-secondary border-top-0 text-nowrap"
                                    id="Art-tab" data-bs-toggle="tab" data-bs-target="#Art" type="button" role="tab"
                                    aria-controls="Art" aria-selected="false">Modern Art</button>
                            </li>
                            <li class="nav-item w-100" role="presentation">
                                <button
                                    class="nav-link text-white w-100 border-start-0 border-end-0 border-bottom border-2 border-secondary border-top-0 text-nowrap"
                                    id="threeD-tab" data-bs-toggle="tab" data-bs-target="#threeD" type="button"
                                    role="tab" aria-controls="threeD" aria-selected="false">3D</button>
                            </li>
                            <li class="nav-item w-100" role="presentation">
                                <button
                                    class="nav-link text-white w-100 border-start-0 border-end-0 border-bottom border-2 border-secondary border-top-0 text-nowrap"
                                    id="Watercolor-tab" data-bs-toggle="tab" data-bs-target="#Watercolor" type="button"
                                    role="tab" aria-controls="Watercolor" aria-selected="false">Watercolor</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade text-white active show" id="Recommended" role="tabpanel"
                                aria-labelledby="Recommended-tab">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_a.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Vapourte</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_b.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Wadafox</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_c.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Aesthetic</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_d.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">The Angle</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="load_button text-center">
                                            <AllButton text="Load More" />
                                            <!-- <button
                                                class="custom_button1 border_15 p-2 px-4 gradient_bg fw-light fs-5 border-0 d-inline-block text-white">Load
                                                More</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade text-white" id="Music" role="tabpanel"
                                aria-labelledby="Music-tab">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_a.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Vapourte</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_b.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Wadafox</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_c.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Aesthetic</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_d.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">The Angle</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="load_button text-center">
                                            <AllButton text="Load More" />
                                            <!-- <button
                                                class="custom_button1 border_15 p-2 px-4 gradient_bg fw-light fs-5 border-0 d-inline-block text-white">Load
                                                More</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade text-white" id="Art" role="tabpanel" aria-labelledby="Art-tab">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_a.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Vapourte</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_b.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Wadafox</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_c.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Aesthetic</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_d.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">The Angle</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="load_button text-center">
                                            <AllButton text="Load More" />
                                            <!-- <button
                                                class="custom_button1 border_15 p-2 px-4 gradient_bg fw-light fs-5 border-0 d-inline-block text-white">Load
                                                More</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade text-white" id="threeD" role="tabpanel"
                                aria-labelledby="threeD-tab">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_a.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Vapourte</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_b.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Wadafox</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button
                                                            class="text-white p-1 px-3 fs-6 bg-transparent fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_c.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Aesthetic</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6  fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_d.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">The Angle</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="load_button text-center">
                                            <AllButton text="Load More" />
                                            <!-- <button
                                                class="custom_button1 border_15 p-2 px-4 gradient_bg fw-light fs-5 border-0 d-inline-block text-white">Load
                                                More</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade text-white" id="Watercolor" role="tabpanel"
                                aria-labelledby="Watercolor-tab">
                                <div class="row">
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_a.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Vapourte</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_b.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Wadafox</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_c.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">Aesthetic</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="artwork_inner mb-5 border_15">
                                            <img src="@/assets/NFT_Elements/img/artwork_d.png" class="img-fluid w-100">
                                            <div class="artwork_inner_content p-3 text-start">
                                                <div class="live_auction_data mb-3 d-flex justify-content-between">
                                                    <div class="live_auction_data_inner">
                                                        <h4 class="text-white fw-normal">The Angle</h4>
                                                        <h6 class="text-white text-opacity-50 fw-normal">By Davincidante
                                                        </h6>
                                                    </div>
                                                    <div class="live_auction_data_inner">
                                                        <button class="text-white p-1 px-3 fs-6 fw-semibold">2.45
                                                            Eth</button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="available_data d-flex align-items-center justify-content-between">
                                                    <small class="custom_color">1 of 1 available </small>
                                                    <span><i class="fa-light fa-heart"></i> 50K</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="load_button text-center">
                                            <AllButton text="Load More" />
                                            <!-- <button
                                                class="custom_button1 border_15 p-2 px-4 gradient_bg fw-light fs-5 border-0 d-inline-block text-white">Load
                                                More</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Artworks end -->



        <!-- faq start -->

        <section class="faq custom_p text-center text-lg-start">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-12">

                    </div>
                </div>

                <div class="row align-items-center justify-content-center mt-5">
                    <div class="col-lg-6">
                        <div class="mb-5">
                            <AllHeadings text3="FAQ OF" text4=" NFTs" />
                        </div>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
                                    <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Q. What is NFT in simple words?
                                    </button>
                                </span>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">Non-fungible Tokens (NFTs) are one-of-a-kind tokens that
                                            represent a one-of-a-kind good or asset, such as digital art. They can be
                                            sold to collectors as well as in NFT sales. The Flow blockchain hosts NFTs
                                            created on Mint.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Q. How do I buy a NFT?
                                    </button>
                                </span>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white"> 1. CHOOSE your favorite piece of art and add it to your
                                            shopping basket.
                                        </p>
                                        <p class="text-white"> 2. CHECK OUT with the e-mail address you want to use to
                                            access them
                                            afterward.
                                        </p>
                                        <p class="text-white"> 3. PAY with your local currency using PayPal or
                                            cryptocurrency via Coinbase.
                                        </p>


                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Q. What is a Wallet?
                                    </button>
                                </span>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">A wallet is a digital storage space where you may save all
                                            of your NFTs in one place and access them easily. </p>
                                    </div>
                                </div>

                            </div>
                            <div class="accordion-item">
                                <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
                                    <button class="accordion-button mb-0 collapsed" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                        Q. After I purchase my NFT, what can I do with it?
                                    </button>
                                </span>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p class="text-white">You can share it, give it as a gift, or sell it right from
                                            your wallet on the marketplace. There you can check how much your NFT is
                                            currently worth.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 text-center mt-5 mt-lg-start">
                        <img src="@/assets/NFT_Elements/img/faq_img.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>

        <!-- faq end -->.





        <!-- NFT Marketplace start -->

        <section class="py-5 my-lg-5 text-center text-md-start">
            <div class="container">
                <div class="row digital_creater_row align-items-center flex-column-reverse flex-lg-row">
                    <div class="col-lg-7 px-0">
                        <div class="digital_creater_content text-lg-start">
                            <h3 class="Rubik_font display-5 mb-4 fw-bold">Are you a digital creator?</h3>
                            <p class="mb-4 pb-2">lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                voluptua. at vero eos et accusam et justo duo dolores et ea rebum. stet clita kasd
                                gubergren, no sea takimata sanc.</p>
                            <div
                                class="digital_creater_content_input bg-dark border border-1 d-flex p-1 rounded-2 bg-opacity-25">
                                <input type="email" name="email"
                                    class="w-100 bg-transparent border-0 p-0 text-white ps-3"
                                    placeholder="Enter your email">
                                <button class="border-0 p-2 px-4 rounded-2 text-nowrap text-primary fw-semibold">Get
                                    Start</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 text-center">
                        <div class="live_auction_data_img">
                            <img src="@/assets/NFT_Elements/img/spunch.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- NFT Marketplace end -->


        <LbmFooter />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import AllButton from '@/components/AllButton.vue';
import db from '@/assets/NFT_Elements/data/db.json'
import AllHeadings from '@/components/AllHeadings.vue';
// import Section_Heading from '@/components/Section_Heading.vue'
// import AllHeadings from '@/components/AllHeadings.vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "./style.css";

// import required modules
import { Autoplay, Navigation } from "swiper";

export default {
    name: 'EnterPrise',
    components: {
        NavBar,
        LbmFooter,
        AllButton,
        AllHeadings,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay, Navigation],
        };
    },
    data() {
        return {
            future_data: db.future_data,
            what_can_data: db.what_can_data,
            top_seller_data: db.top_seller_data,
            live_auction_data: db.live_auction_data,
            collection_data: db.collection_data,
            why_nft_data: db.why_nft_data,
            key_data: db.key_data
        }
    }


}
</script>




<style scoped>
.banner_heading h1 {
    font-size: 58px !important;
}

.my-info-box .my-info-box-top[data-v-45ef44b7] {
    background-image: url(http://192.168.11.78:8080/img/content_bg.b8c0df0b.png);
    min-height: 60px;
    background-position: 50%;
    z-index: 1;
    background-repeat: no-repeat;
    margin-top: -20px;
    background-size: contain;
}

.my-info-box {
    /* background: #18191f; */
    background: #18191F;
    min-height: 227px;
}

.my-info-box:hover .my-info-box-top img {
    opacity: 10 !important;

}

.my-info-box .info-box-top-hover {
    transition: .3s linear;
    opacity: 0;
    z-index: -1;
}

.my-info-box:hover .my-info-box-top h5.small {
    z-index: 10 !important;

}




/* What can NFTs start  */
.ntf_used .heading .para {
    display: none !important;
}

.center_image_Nft video {
    mix-blend-mode: difference;
}

/* What can NFTs end  */

/* section.slider */
section .custom_slider .row.user_row .user_content ul li a {
    height: 40px;
    width: 40px;
    border-radius: 10px;
}

section .custom_slider .user_content h2,
h4 {
    font-family: unset !important;
}


.custom_slider[data-v-45ef44b7] :is(.swiper-button-prev, .swiper-button-next) {
    width: 45px !important;
    height: 45px !important;
    background: #14233A !important;
    /* box-shadow: 2px -1px 1px, 0 0 1px #0964F3 !important; */
    box-shadow: 3px 0px 3px #0964f3 !important;
    color: #ccccccf0 !important;
    border-radius: 10px !important;
    top: 93% !important;
}

section .custom_slider .swiper {
    height: 367px;
}

/* section.slider */

/* Live Auction start */
.live_auction_div img.live_auction_img {
    width: 80px;
    left: 8%;
    bottom: -5%;
}

.live_auction_div {
    border-radius: 25px;
    padding: 3px;
}

.bg_border_button {
    background-image: url(@/assets/img/hover.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    color: #fff;
    font-weight: 600;
    white-space: nowrap;
}

.live_auction_div .live_auction_data_inner .custom_btn .home_btn {
    /* font-weight: 800 !important; */
}

.live_auction_div .live_auction_data_inner h3 {
    font-family: unset !important;
}

.live_auction_data_inner h3[data-v-45ef44b7][data-v-45ef44b7] {
    font-size: 20px;
}


/* Live Auction end */


/* Popular Collection start  */
.popular_collection .popular_collection_data button {
    box-shadow: -4px 0 2px #0964f3 !important;
}

.popular_collection {
    border-radius: 20px;
}

.collection_main_div h2 {
    font-family: unset !important;
}

.popular_collection[data-v-45ef44b7] {
    background: linear-gradient(90deg, #211f24d1, #2a2431e3);
}

/* Popular Collection end  */



/* why nft start */
.how_it_work_box_outer .how_it_work_box {
    min-height: 225px;
    position: relative;

}

.how_it_work_box::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 140px;
    background: #0964f3;
    border-radius: 5px;
}

.how_it_work_box_outer:hover::after {
    content: "";
    position: absolute;
    top: 40%;
    height: 80px;
    width: 5px;
    background: #0964f3;
    border-radius: 5px;
}

.how_it_work_box_outer .how_it_work_box:hover {
    min-height: 225px;
    background: #1B1E23;
    border-radius: 25px;
    isolation: isolate;
}

/* why nft end  */


/* start artwork start  */
ul#myTab {
    flex-wrap: nowrap;
    border-bottom: unset;
}

ul#myTab .active {
    border-bottom-color: #0964f3 !important;
    background-color: #0964f3;
    color: #fff !important;
}

.live_auction_data_inner button {
    border: 1px solid #0952c4;
    border-radius: 5px;
    background-color: black !important;
}

.artwork_inner[data-v-45ef44b7] {
    background: linear-gradient(90deg, #19171c, #0964F32E);
}

.border_15 {
    border-radius: 15px !important;
}

.artwork_inner_content .live_auction_data .live_auction_data_inner h6 {
    font-family: unset !important;
}

/* start artwork end  */



/* accordion css */
section.faq .accordion-button:focus {
    box-shadow: unset !important;
}

section.faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 15px 15px;
}

section.faq .accordion-collapse p {
    font-size: 14px !important;
}

section.faq .accordion-item {
    color: var(--bs-accordion-color);

}

.accordion {
    position: relative;
}

.accordion:before {
    content: "";
    height: 96%;
    width: 2px;
    background: #464343;
    position: absolute;
    left: -27px;
}

div#collapseOne {
    position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after {
    content: "";
    height: 156%;
    width: 1px;
    background: #fff;
    position: absolute;
    left: -27px;
    top: -57px;
}

div#collapseTwo:after {
    height: 36%;
    top: 79px;
}

div#collapseThree:after {
    height: 37%;
    top: 136px;
}

div#collapseFour:after {
    height: 41%;
    top: 205px;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed,
section.faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

section.faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed {
    background-color: #0e0c0c;
}

section.faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}

/* accordion css */


.row.digital_creater_row {
    background-image: url(@/assets/NFT_Elements/img/creator_bg.png);
    background-size: 100% 100%;
    margin: 0;
    color: #fff;
    padding: 50px;
}

.digital_creater_row .digital_creater_content input {
    outline: unset !important;
}

.popular_collection img {
    width: 100%;
}



@media(max-width:1399px) and (min-width:1200px) {
    .how_it_work_box_outer .how_it_work_box {
        min-height: 245px;
    }

    .center_image_Nft video {
        width: 100% !important;
    }

    .banner_heading h1 {
        font-size: 42px !important;
    }

    .live_auction_data_inner h3[data-v-45ef44b7] {
        font-size: 19px;
    }

    .user_content h2[data-v-45ef44b7] {
        font-size: 19px !important;
    }
}

@media(max-width:1199px) and (min-width:992px) {
    .header_content h3 {
        font-size: 20px;
    }

    .my-info-box {
        background: #18191f;
        min-height: 254px;
    }

    .user_content h2 {
        font-size: 17px !important;
    }

    .row.user_row .user_content ul li a {
        height: 35px;
        width: 35px;
    }

    .user_content h4 {
        font-size: 16px !important;
    }

    .live_auction_data_inner h3 {
        font-size: 18px;
    }

    .live_auction_data_inner h3:last-child {
        font-size: 15px !important;
    }

    .live_auction_data_inner button {
        padding: 7px 8px !important;
        font-size: 12px !important;
    }

    .live_auction_data_inner p {
        font-size: 12px;
    }

    .live_auction_data_inner_buttons button {
        font-size: 15px !important;
    }

    .how_it_work_box_outer .how_it_work_box {
        min-height: 266px;
    }

    .heading_data h1 {
        font-size: 35px;
    }

    .footer_links h4 {
        font-size: 20px;
    }

    .my-info-box .my-info-box-top[data-v-45ef44b7][data-v-45ef44b7] {
        background-size: cover;
    }

    .center_image_Nft video {
        width: 100% !important;
    }

    .banner_heading h1[data-v-45ef44b7] {
        font-size: 42px !important;
    }
}

@media(max-width:991px) and (min-width:768px) {
    .center_image_Nft_col {
        padding-inline: 0;
    }

    .Future_icon_content img {
        width: 40px !important;

    }

    .Future_content p {
        font-size: 14px;
    }
    .banner_heading h1 {
        font-size: 47px !important;
    }




    .center_image_Nft video {
        width: 100% !important;
    }

}

@media(max-width:767px) and (min-width:576px) {
    .future_div {
        min-height: 205px !important;
    }

    .live_auction_data_inner button {
        font-size: 12px !important;
        padding: 5px 10px !important;
    }

    .how_it_work_box_outer .how_it_work_box {
        min-height: 266px;
    }

    .live_auction_data_inner h3 {
        font-size: 17px !important;
    }

    .live_auction_data_inner :is(button, p) {
        font-size: 12px;
    }

    .live_auction_data_inner_buttons button {
        font-size: 14px !important;
    }

    .my-info-box .my-info-box-top[data-v-45ef44b7][data-v-45ef44b7] {
        background-size: contain;
    }

    .how_it_work_box_outer .how_it_work_box {
        background: #1B1E23;
        border-radius: 25px;
    }


    .banner_heading h1 {
        font-size: 47px !important;
    }
}

@media(max-width:991px) {
    .live_auction_div.custom_width_data {
        width: 100%;
    }

    .custom_slider :is(.swiper-button-prev, .swiper-button-next) {
        top: 97% !important;
        left: 0 !important;
        right: 0 !important;
        margin-inline: auto;
    }

    ul#myTab {
        overflow-x: auto;
    }

    .row.digital_creater_row {
        padding: 50px 20px;
    }

    .custom_button_padding {
        padding: 5px 20px !important;
    }

    .heading_data h1 {
        font-size: 30px;
    }

    .heading_data img {
        width: 60px;
    }

    #home {
        padding-top: 130px;
        padding-bottom: 0px;
    }

    .footer_links h4 {
        font-size: 19px;
    }








    .my-info-box .my-info-box-top[data-v-45ef44b7][data-v-45ef44b7] {
        background-size: cover;
    }

    section .custom_slider .swiper {
        height: unset !important;
    }

    .center_image_Nft video {
        width: 50%;
    }

}

@media(max-width:575px) {


    .banner_heading h1[data-v-45ef44b7] {
        font-size: 30px !important;
    }

    .my-info-box .my-info-box-top[data-v-45ef44b7][data-v-45ef44b7] {
        background-size: cover;
    }

    section .custom_slider .swiper {
        height: unset !important;
    }

    .center_image_Nft video {
        width: 50%;
    }

    .how_it_work_box_outer .how_it_work_box {
        background: #1B1E23;
        border-radius: 25px;
    }






    .user_content h2 {
        font-size: 16px !important;
    }

    .user_content h4 {
        font-size: 14px !important;
    }

    .row.user_row .user_content ul li a {
        height: 35px;
        width: 35px;
    }
}


</style>











