<template>
<div>
  <NavBar />

  <section class="cryptoExchange">
    <!-- <div class="scroll"><span class="iconify" data-icon="bi:arrow-down"></span></div> -->
    <!-- <div class="main-banner">
      <video autoplay="" muted="" loop="" id="bg-video">
        <source src="@/assets/video/crypto_exchange.mp4" type="video/mp4">
      </video>
    </div> -->
    <div class="video_caption">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-6">
          <div class="home_header">
            <Section_Heading text1="Embark On A Crypto " text2=" Exchange Development Journey" text3="">
            </Section_Heading>
            <div class="">
              <div class="row justify-content-center">
                <div class="col-md-10 col-lg-6">
                  <div class="form_bg input_form position-relative rounded-4 p-3 p-sm-5">
                    <form class="position-relative">
                      <div class="mb-3">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
                      </div>
                      <div class="mb-3">
                        <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Number">
                      </div>
                      <button type="submit" class="btn btn-primary w-75 mt-4">Proceed</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="platform ">
    <div class="container">
      <div class="row justify-content-between platform_bg rounded-5 p-md-5">
        <div class="col-lg-7 text-center text-lg-start pe-3">
          <AllHeadings text3="Create a Secure, Adaptable, and Intelligible Digital   Currency " text4="Exchange Platform" text5="Capture the prospect of venturing into your crypto exchange platform. Cryptocurrency exchange development is influential in fulfilling a robust competitive fringe. Track our specialized adroitness, in-depth field understanding, and years of experience reviving your crypto exchange development voyage">
          </AllHeadings>
        </div>
        <div class="col-lg-4">
          <div class="crypto_text text-center text-lg-start">
            <div class="row justify-content-center align-items-center">
              <div class="col-6 mb-5 set_platform">
                <AllHeadings text3="" text4="1."></AllHeadings>
                <p class="text-white">
                  Robust APIs
                </p>
              </div>
              <div class="col-6 mb-5 set_platform">
                <AllHeadings text3="" text4="2.">
                </AllHeadings>
                <p class="text-white">
                  Crypto spot liquidity
                </p>
              </div>
              <div class="col-6 px-lg-3 set_platform">
                <AllHeadings text3="" text4="3.">
                </AllHeadings>
                <p class="text-white">
                  Enterprise-class
                  wallet
                </p>
              </div>
              <div class="col-6 set_platform" >
                <AllHeadings text3="" text4="4.">
                </AllHeadings>
                <p class="text-white">
                  100,000 transactions per second
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <section class="slider_a">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <!-- use the swiper slider -->
          <AllHeadings text3="Pivotal Traits" text4=" Of Our Exchange Platform " text5="Our exchange platform is structured with industry-leading traits that enclose but are not confined to">
          </AllHeadings>
        </div>
      </div>
      <div class="row mt-md-5">
        <div class="col-12">

          <swiper :slidesPerView="1" :navigation="false" :spaceBetween="10" :loop="true" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :breakpoints="{
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }" :modules="modules" class="mySwiper">
            <swiper-slide>
              <div class="main_slide">
                <div class="text-center about_img "> <img src="@/assets/crypt_exchange/img/credit_a.png" alt="" class="img-fluid set_slider_a_img" loading="lazy"></div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Referral and Reward<br class="break_line">
                    Program</span>
                  <p class="text-white mt-4">The cryptocurrency exchange platform proposed at LBM
                    Solutions is merged with dedicatedly framed referral and bonus agendas to
                    allow
                    users to fetch in more.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/trading_b.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">High-Powered Trading<br class="break_line">
                    Engine</span>
                  <p class="text-white mt-4">TPS is one of the exceptional frameworks to measure
                    the
                    viability of an exchange platform. Our crypto exchange assures world-class
                    interpretation by processing 100,000 transactions in a second.

                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/scan_c.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">500+ Cryptocurrencies<br class="break_line">
                    and Fiat Support</span>
                  <p class="text-white mt-4">Our mighty exchange supports fiat and popular
                    cryptocurrencies. One can create a collaborative trading platform for
                    numerous
                    currencies using our crypto exchange.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/bottrading_d.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Bot Trading

                    and Fiat<br class="break_line"> Support</span>
                  <p class="text-white mt-4">The diligent bot trading module permits users to
                    capture
                    market prospects 24/7. The bot allows
                    round-the-clock trading and trades consistently when users are not busily
                    bartering</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/liquidity_e.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">High-Liquidity At
                    <br class="break_line"> All Market Conditions</span>
                  <p class="text-white mt-4">We merge your cryptocurrency exchanges with viable
                    liquidity prospects to assure a whole order book and seamless trade
                    undertakings
                    beneath all market requirements.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/chart_f.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Advanced Chart
                    <br class="break_line"> Tools
                  </span>
                  <p class="text-white mt-4">Our cryptocurrency exchange proposes a delineative
                    trading view allowing newbies and professional users to conveniently create
                    a
                    plan established on classes, orders, and more.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/gateway_g.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Payment Gateway<br class="break_line">
                    Integration</span>
                  <p class="text-white mt-4">Numerous payment gateways broaden the client base and
                    improve clients’ faith in crypto exchanges. We mount up client amenities by
                    merging multiple payment modes in trading platforms.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/staking_h.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Staking <br class="break_line">Module</span>
                  <p class="text-white mt-4">Staking permits end-users to make auxiliary staking
                    incentives over the crypto holdings and platform proprietors to enrich
                    liquidity, stake, and command.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/cryptowallet_i.png" alt="" class="img-fluid set_slider_a_img" loading="lazy">
                </div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">Enterprise-Grade Multi-
                    cryptocurrency Wallet</span>
                  <p class="text-white mt-4">Our exchange has an in-built multi-cryptocurrency
                    wallet
                    created assured by ecliptic curves and multi-signature technology to promote
                    seamless and protected storage of digital assets.</p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="main_slide">
                <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/tps.png" alt="" class="img-fluid set_slider_a_img" loading="lazy"></div>
                <div class="text-center mt-3">
                  <span class=" text-white fw-bold">High-Volume <br>TPS</span>
                  <p class="text-white mt-4">TPS is one of the exceptional frameworks to measure
                    the
                    viability of an exchange platform. Our crypto exchange assures world-class
                    interpretation by processing 100,000 transactions in a second.</p>
                </div>
              </div>
            </swiper-slide>

          </swiper>
        </div>

      </div>
    </div>
  </section>
<!-- 
  <section class="services py-5 py-lg-2">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-8 col-lg-8 mb-5 mb-md-start">
          <AllHeadings text3="Lets’s Try our Services Now" text4=" " text5="Everything that you need">
          </AllHeadings>
          <div class="d-inlnie-flex mt-4">
            <AllButton text="Get Started" />
          </div>
        </div>
        <div class="col-md-3  col-lg-3 text-center text-md-start">
          <img src="@/assets/crypt_exchange/img/employe.png" alt="" class="img-fluid " loading="lazy">
        </div>
      </div>
    </div>
  </section> -->














  <section class="slider_b">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <!-- use the swiper slider -->
          <AllHeadings text3="Security Markers Of " text4=" Cryptocurrency Exchange Script " text5="Using institutional-grade security mechanisms, LBM Blockchain Solutions develops security attributes for your crypto exchange that incorporate:-">
          </AllHeadings>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="height_slider">
            <swiper :slidesPerView="1" :navigation="false" :spaceBetween="10" :loop="true" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :breakpoints="{
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }" :modules="modules" class="mySwiper">
              <swiper-slide>
                <div class="main_slide">
                  <div class="text-center about_img"> <img src="@/assets/crypt_exchange/img/sec__img_a.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Anti-Denial of Service<br class="break_line">
                      (DoS)
                    </span>
                    <p class="text-white mt-4">Whenever there is a high number of potentially dangerous
                      requests, anti-DoS systems secure the ecosystems.
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_b.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">HTTP<br class="break_line">
                      Authentication</span>
                    <p class="text-white mt-4">Authenticating users with tokens-based HTTP
                      authentication mechanisms like OAuth is secure.

                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_c.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Jail<br class="break_line">
                      Login</span>
                    <p class="text-white mt-4">After multiple unsuccessful login attempts, the jail
                      feature denies login requests for a preset amount of time.</p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_d.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Cross-Site Request Forgery (CSRF) Security
                    </span>
                    <p class="text-white mt-4">Exchanges are protected from unwanted actions by
                      token-based security mechanisms.</p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_e.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Server-Side Request Forgery
                      (SSRF) Protection</span>
                    <p class="text-white mt-4">System security is ensured by SSRF protection against
                      attacks from authorized internal applications.</p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_f.png" alt="" class="img-fluid" loading="lazy"></div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Anti-Distributed Denial Of
                      Service (DDoS)
                    </span>
                    <p class="text-white mt-4">Multiple connected devices attacking a bitcoin exchange
                      software at the same time are protected from DDoS attacks.
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_g.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">HTTP Parameter Pollution Protection</span>
                    <p class="text-white mt-4">Defends against web attacks that manipulate HTTP requests
                      to retrieve concealed data.</p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="main_slide">
                  <div class="about_img  text-center"> <img src="@/assets/crypt_exchange/img/sec__img_h.png" alt="" class="img-fluid" loading="lazy">
                  </div>
                  <div class="text-center">
                    <span class="fs-5 text-white fw-bold">Anti-Distributed Denial Of
                      Service (DDoS)
                    </span>
                    <p class="text-white mt-4">Multiple connected devices attacking a bitcoin exchange
                      software at the same time are protected from DDoS attacks.

                    </p>
                  </div>
                </div>
              </swiper-slide>

            </swiper>
          </div>

        </div>

      </div>
    </div>
  </section>

  <section class="developing d-none d-md-block">
    <div class="container">
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-md-6 col-lg-5">
          <AllHeadings text3="Developing Exchange" text4=" Software With Our 
                            Prime Modules " text5="">
          </AllHeadings>
        </div>
        <div class="col-md-6 col-lg-6 text-center text-md-start">
          <p class="text-white">The following modules are included in our fully-featured, highly
            extensible, scalable, & trustworthy exchange development solution:</p>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 col-lg-6 mb-5">
          <div class="row">

            <!-- v-for="(data_c, index) in data_c" :key="index" -->
            <div class="col-lg-12">
              <!-- button -->
              <div class="layer_a rounded-5">
                <div class="nav flex-column nav-pills  p-3 p-lg-5 rounded-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                  <div class="tab_a d-flex align-items-center justify-content-between mb-3">
                    <div class="">

                      <p class="text-white fw-bold mb-4">Client App Dashboard</p>

                      <img src="@/assets/playearn_elements/svg_files/tab_a.svg" alt="" class="fs-1 fs-1 bg-white pad p-2 rounded-2 bg-opacity-10" loading="lazy">

                    </div>
                    <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Know More</button>

                  </div>

                  <span class="tab_border"></span>

                  <div class="tab_a d-flex align-items-center justify-content-between my-3 tab_border position-relative">
                    <div class="">
                      <p class="text-white fw-bold mb-4">Admin Panel
                      </p>
                      <img src="@/assets/playearn_elements/svg_files/tab_b.svg" alt="" class="fs-1 fs-1 bg-white pad p-2 rounded-2 bg-opacity-10" loading="lazy">
                    </div>

                    <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Know More</button>

                  </div>

                  <span class="tab_border"></span>

                  <div class="tab_a d-flex align-items-center justify-content-between mt-3">
                    <div class="">
                      <p class="text-white fw-bold mb-4">Order Book</p>
                      <img src="@/assets/playearn_elements/svg_files/tab_c.svg" alt="" class="fs-1 fs-1 bg-white pad p-2 rounded-2 bg-opacity-10" loading="lazy">
                    </div>

                    <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Know
                      More</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <!-- content -->
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active text-white" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal">User-authentication <br> and authorization
                    </p>
                  </div>
                  <div class="d-flex my-4">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal"> In-Built Electronic<br>
                      Wallets
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal"> Detailed and Convenient<br>
                      Cryptocurrency
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 mt-4">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal mb-4"> Cryptocurrency trading <br> Engine
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal"> Charts
                    </p>
                  </div>
                </div>
              </div>

            </div>

            <div class="tab-pane fade text-white" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal">Data Rich Dashboards
                    </p>
                  </div>
                  <div class="d-flex my-4">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal"> Real-Time View of Trades, Wallet and
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal mb-5"> Transactions
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal"> Payment Order Dashboard
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 mt-4">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal mb-5"> Transaction Details
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal"> Transaction History
                    </p>
                  </div>
                  <div class="d-flex mt-4">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal">User’s Details
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade text-white" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabindex="0">
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal">List Of Open Orders
                    </p>
                  </div>
                  <div class="d-flex my-4">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal"> Order history
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class=" fw-normal mb-5">List of Recent Orders
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal">Pending Transactions (Waiting for Authorization)
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6">
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal mb-4">Cryptocurrency Balance
                    </p>
                  </div>
                  <div class="d-flex">
                    <div class=" me-3">
                      <span class="iconify text-white fs-1" data-icon="charm:circle-tick"></span>
                    </div>
                    <p class="fw-normal"> Bid Table and Ask Table Overview
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- phone responsive -->
  <section class="developing d-md-none">
    <div class="container">
      <div class="row justify-content-between align-items-center mb-5">
        <div class="col-md-6 col-lg-5">
          <AllHeadings text3="Developing Exchange" text4=" Software With Our 
                            Prime Modules " text5="">
          </AllHeadings>
        </div>
        <div class="col-md-6 col-lg-6 text-center text-md-start">
          <p class="text-white">The following modules are included in our fully-featured, highly
            extensible, scalable, & trustworthy exchange development solution:</p>
        </div>
      </div>
      <!-- custom new tab -->
      <div class="row d-md-none">
        <div class="col-lg-12">
          <div class="layer_a rounded-5">
            <div class="layer_b rounded-5">
              <div class="custome_new_tab">
                <div class="container">

                  <ul class="tabs pt-4 text-center mb-4">
                    <li class="tab-link current text-white fw-semibold rounded-2" data-tab="tab-1">Client
                      App</li>
                    <li class="tab-link text-white fw-semibold rounded-2" data-tab="tab-2">Admin
                      Panel
                    </li>
                    <li class="tab-link text-white fw-semibold rounded-2" data-tab="tab-3">Order
                      Book</li>
                  </ul>
                  <!-- 1st tab -->
                  <div id="tab-1" class="tab-content current">
                    <div class="tab_inner_content">
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>User-authentication and authorization</p>
                      </div>

                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>In-Built Electronic Wallets</p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p> Detailed and Convenient Cryptocurrency</p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Cryptocurrency trading Engine</p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Charts</p>
                      </div>
                    </div>
                  </div>
                  <!-- 2st tab -->
                  <div id="tab-2" class="tab-content">
                    <div class="tab_inner_content">
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Data Rich Dashboards </p>
                      </div>

                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Real-Time View of Trades, Wallet and </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p> Transactions </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Payment Order Dashboard </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Transaction Details </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Transaction History </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>User’s Details </p>
                      </div>
                    </div>
                  </div>
                  <!-- 3st tab -->
                  <div id="tab-3" class="tab-content">
                    <div class="tab_inner_content">
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>List Of Open Orders </p>
                      </div>

                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Order history </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p> List of Recent Orders </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Pending Transactions </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p>Cryptocurrency Balance </p>
                      </div>
                      <div class="d-flex">
                        <div class=""><span class="iconify" data-icon="charm:circle-tick"></span></div>
                        <p> Bid Table and Ask Table Overview </p>
                      </div>

                    </div>
                  </div>

                </div><!-- container -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- custom new tab -->
    </div>
  </section>

  <section class="services py-5">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6 col-lg-6">
          <AllHeadings text3="Be smart" text4=" And Simplify Your Trading " text5="">
          </AllHeadings>

        </div>
        <div class="col-md-6 col-lg-6 text-center text-md-start">
          <p class="text-white">From being the first to the most used stablecoin, and one of the most
            traded
            tokens by volume.</p>
          <AllButton text="Get Started" />
        </div>
      </div>
    </div>
  </section>

  <section class="platform">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <AllHeadings text3="Services For The Development Of Cryptocurrency" text4=" Exchange Platforms
                            " text5="Partnered with us means choosing trusted exchange developers. We are with you every step of the success and more. Besides,
                            we're not technical developers, we're creative minds providing outstanding exchange features.">
          </AllHeadings>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-10 col-md-12 ">
          <ExchangePlatform></ExchangePlatform>
        </div>
      </div>
    </div>
  </section>

  <section class="faq custom_p">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-lg-12">
        <AllHeadings text3="Frequently Asked" text4=" Questions" />
      </div>
    </div>
    <div class="row align-items-center justify-content-center mt-5">
      <div class="col-lg-10">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
              <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is the difference between centralized and decentralized exchanges?
              </button>
            </span>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p class="text-white">Decentralized exchanges are more cost-effective, allowing
                  users to hold their assets and avoid regulatory burdens. Centralized exchanges,
                  however, offer faster transactions, convenience, and high liquidity.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
              <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                How do crypto exchanges make money?
              </button>
            </span>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p class="text-white">When cryptocurrency wasn't even invented until 2009, income at
                  that level was astounding compared to the historical growth of other industries.
                  Users who sell, purchase, or withdraw cryptocurrencies on cryptocurrency
                  exchanges will be charged fees, so it is easy to see why cryptocurrency
                  exchanges make their money.</p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
              <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Do exchanges buy crypto?
              </button>
            </span>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p class="text-white">Any service that matches buyers and sellers of bitcoin is
                  referred to as a bitcoin exchange. A bitcoin exchange is what makes Bitcoin a
                  liquid asset for traders on a large scale. When most people mention bitcoin
                  exchanges, they're referring to centralized 'custodial' platforms such as
                  Coinbase, Kraken, and Binance.</p>
              </div>
            </div>

          </div>
          <div class="accordion-item">
            <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
              <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Do crypto exchanges mine coins?
              </button>
            </span>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p class="text-white">Bitcoin, for instance, can be purchased on Coinbase, received
                  as payment for goods, or virtually mined. This is the third category we are
                  discussing here.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  <LbmFooter />
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

import LbmFooter from "@/components/LbmFooter.vue";
import AllButton from "@/components/AllButton.vue";
import AllHeadings from "@/components/AllHeadings.vue";
import Section_Heading from "@/components/Section_Heading.vue";

import {
  Swiper,
  SwiperSlide
} from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  Autoplay,
  Navigation
} from "swiper";
import ExchangePlatform from "../components/ExchangePlatform.vue";

export default {
  name: "CryptoExchange",
  components: {
    Swiper,
    SwiperSlide,
    NavBar,
    LbmFooter,
    AllButton,
    AllHeadings,
    ExchangePlatform,
    Section_Heading,


  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  data() {
    return {
      data_a: [{
          svg: "platform_a.svg",
          para: "Centralized Exchange Development",
          para_a: "Our best Cryptocurrency Exchange Development Company will craft a blazing-fast centralized, superior crypto exchange that will allow users to trade cryptocurrencies in a fully secure environment.",
        },
        {
          svg: "platform_b.svg",
          para: "P2P Exchange Development",
          para_a: 'Decentralized exchanges allow your customers to trade with confidence, privacy, security, and complete control while avoiding centralization risks. We help our clients build scalable DEXs using distributed ledger technology.'
        },
        {
          svg: "platform_c.svg",
          para: "Decentralized Exchange Development",
          para_a: 'By combining the strengths of both centralized and decentralized exchanges, we will build a high-performance P2P crypto exchange platform with advanced features and an effective dispute resolution system.'
        },
        {
          svg: "platform_d.svg",
          para: "Bitcoin Exchange Development",
          para_a: 'We build highly accountable, scalable Bitcoin exchange software to facilitate seamless trading and enhance the adoption of digital currencies. Our expertise includes creating Bitcoin exchanges, scripts, and customized Bitcoin exchanges.'
        },
        {
          svg: "platform_e.svg",
          para: "Derivatives Exchange Development Our cryptocurrency ",
          para_a: 'Derivative exchange development solutions ensure quick, easy, and secure crypto derivative trading by building feature-rich derivatives exchange platforms that commit to the highest performance standards.'
        },
        {
          svg: "platform_f.svg",
          para: "Margin Trading Exchange Development",
          para_a: 'Add margin cryptocurrency trading to your existing business if you want a larger pool of investors to gather over your trading ecosystem. With our ultra-modern trading platforms, customers can earn up to 100X over their investments.'
        },
        {
          svg: "platform_g.svg",
          para: "Non Fungible Token Exchange Development",
          para_a: 'In parallel with the tremendous growth of the NFT market, the demand for NFT exchange development is rising. As a leading crypto exchange development company, LBM Blockchain solutions provide customers with scalable exchange platforms for a wide range of NFTs that deliver world-class trading experiences.'
        },
        {
          svg: "platform_g.svg",
          para: "Security Token Exchange Development",
          para_a: 'We are committed to developing a platform that adheres to the highest standards of UI/UX, security, functionality, scalability, and performance in a trillion-dollar niche. Our company is a pioneering cryptocurrency trading software company that has developed a security token exchange that adheres to the highest functionality, security, and scalability standards.'
        },
        {
          svg: "platform_i.svg",
          para: "Over-the-counter Trading Platform Development",
          para_a: 'LBM Blockchain Solutions has become an established exchange software development company, providing over-the-counter (OTC) trading platforms that eliminate slippage and facilitate massive cryptocurrency trades at lightning-fast speed. Please take advantage of our blockchain development expertise and mission-driven solutions to lead the way.'
        },
      ],

    }
  },
  mounted() {
    window.$(document).ready(function () {

      window.$('.tabs li').click(function () {
        var tab_id = window.$(this).attr('data-tab');

        window.$('.tabs li').removeClass('current');
        window.$('.tab-content').removeClass('current');

        window.$(this).addClass('current');
        window.$("#" + tab_id).addClass('current');
      })

    })
  }
}
</script>

<style scoped>
.platform_bg {
  background-color: #131313;
}

/* custome new tab */

.custome_new_tab ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;

}

.custome_new_tab ul.tabs li {
  background: none;
  color: #222;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}

.custome_new_tab ul.tabs li.current {
  background: #0964f3 !important;
  color: #222;
}

.custome_new_tab .tab-content {
  display: none;
  /* background: #ededed; */
  padding: 15px;
}

.custome_new_tab .tab-content.current {
  display: inherit;
}

.custome_new_tab .tab-content {
  display: none;
  background: #15151b;
  color: #fff;
  padding: 15px;
}

.custome_new_tab ul.tabs li.current {
  background: #0285ff;
  color: #222;
}

/* custome new tab */

.form_bg {
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(55px);
}

section.cryptoExchange input {
  padding: 10px 10px;
  background: rgba(255, 255, 255, 0.09);
  border: 1px solid rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(50px);
}

section.cryptoExchange input .form-control:focus {
  background: rgba(255, 255, 255, 0.09) !important;
}

section.platform .crypto_text .para {
  display: none;
}

section.services .heading p {
  display: none;
}

section.services {
  background: linear-gradient(90.02deg, #032456 -5.24%, rgba(3, 15, 35, 0.51) 72.52%);
  box-shadow: 0px 19px 67px rgba(57, 57, 57, 0.18), 11px -41px 39px rgba(0, 0, 0, 0.25);
}

/* slider_a */
section.slider_a .main_slide {
  background: #1B1919;
  padding: 18px 22px;
  border-radius: 30px;
  min-height: 446px;
}

/* slider_b */
section.slider_b .swiper {
  min-height: 530px;
  display: flex;
  align-items: end;
}

section.slislider_b .main_slide img {
  margin: 0 0;
}

section.slider_b .main_slide {
  background: #12121C;
  padding: 18px 22px;
  border-radius: 30px;
  min-height: 446px;
}

section.slider_b .main_slide .about_img img {
  position: relative;
  margin-top: -74px;
}

/* tabs button */
.developing .tab-content p {
  font-size: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: rgb(255, 255, 255) !important;
  background-color: unset !important;
}

section.developing .layer_a p {
  font-size: 24px;
}

section.developing .heading p {
  display: none;
}

section.developing .layer_a {
  padding: 1px;
  background: linear-gradient(184deg, rgba(164, 164, 166, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

section.developing span.tab_border {
  width: 100%;
  height: 1px;
  background: linear-gradient(184deg, rgba(164, 164, 166, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

section.developing .nav {
  background-color: #15151B;
}

section.developing .layer_b {
  background-color: #15151B;
}

.developing button.nav-link.active {
  background-image: url(@/assets/img/active.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 51px;
  width: 143px;
  font-weight: 600;

}

.developing button.nav-link {
  background-image: url(@/assets/img/hover.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 51px;
  width: 143px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
}

section.platform .layer_a .layer_b .para_line {
  display: none;
}

section.platform .layer_a .layer_b {
  min-height: 280px;
}

section.platform .layer_a .layer_b:hover img {
  filter: invert(1)
}

section.platform .layer_a .layer_b:hover {
  color: #fff;
  background-color: #1E55D7;
  border-radius: 10px;
}

section.platform .layer_a .layer_b:hover .para_line {
  display: block !important;
}

/* accordion css */
section.faq .accordion-button:focus {
  box-shadow: unset !important;
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #1D1B1B !important;
}

div#collapseOne,
div#collapseTwo,
div#collapseThree,
div#collapseFour {
  background-color: #1d1b1b;
  border-radius: 0 0 15px 15px;
}

section.faq .accordion-collapse p {
  font-size: 14px !important;
}

section.faq .accordion-item {
  color: var(--bs-accordion-color);

}

.accordion {
  position: relative;
}

.accordion:before {
  content: "";
  height: 96%;
  width: 2px;
  background: #464343;
  position: absolute;
  left: -27px;
}

div#collapseOne {
  position: relative;
}

div#collapseOne:after,
div#collapseTwo:after,
div#collapseThree:after,
div#collapseFour:after {
  content: "";
  height: 156%;
  width: 1px;
  background: #fff;
  position: absolute;
  left: -27px;
  top: -57px;
}

div#collapseTwo:after {
  height: 36%;
  top: 79px;
}

div#collapseThree:after {
  height: 37%;
  top: 136px;
}

div#collapseFour:after {
  height: 41%;
  top: 205px;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed,
section.faq .accordion-button:not(.collapsed) {
  border: unset !important;
  color: #fff !important;
  border-radius: 15px 15px 0 0;
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

section.faq .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
  background: #000;
}

section.faq .accordion-item,
section.faq button.accordion-button.collapsed {
  background-color: #0e0c0c;
}

section.faq .accordion-button::after {
  filter: invert(0.5);
  padding: 10px 10px;
  border: 1px solid #616161;
}

/* accordion css */

@media(min-width:320px) and (max-width:575px) {

  /* custome new tab */

  .custome_new_tab ul li {
    font-size: 17px;
  }

  .tab-content .tab_inner_content p {
    font-size: 16px !important;
    margin-left: 10px;
  }

  /* custome new tab */

  section.cryptoExchange h1 {
    font-size: 34px !important;
    line-height: unset !important;
  }

  section.cryptoExchange {
    padding: 127px 0;
  }

  section.slider_a .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }

  section.slider_a .swiper-slide {
    height: unset;
  }

  section.slider_a .swiper-slide .about_img img {
    width: unset !important;
  }

  /* .developing */
  section.developing .heading p {
    font-size: 16px;
  }

  /* .developing */
}

/* css hero */

.home_header {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 80%;
}

section.cryptoExchange .main-banner {
  position: relative;
}

section.cryptoExchange #bg-video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

section.cryptoExchange {
  position: relative;
}

section.cryptoExchange::before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background-color: #000000b3;
  z-index: 9;
}

@media(min-width:320px) and (max-width:375px) {
  section.slider_b .main_slide {
    min-height: 375px;
  }
}

@media(min-width:576px) and (max-width:767px) {

  .tab-content .tab_inner_content p {
    font-size: 18px !important;
    margin-left: 10px;
  }

}

@media(min-width:768px) and (max-width:991px) {
  section.developing .layer_a p {
    font-size: 17px;
  }

  .developing .tab-content p {
    font-size: 16px !important;
  }

  .developing button.nav-link {
    height: 43px;
    width: 125px;
    font-size: 14px;
  }

  .developing button.nav-link.active {
    height: 43px;
    width: 125px;
    font-size: 14px;
  }
}

@media(min-width:992px) and (max-width:1500px) {
  .developing .tab-content p {
    font-size: 17px !important;
  }
}

.form-control:focus {
  color: #fff !important;
  background-color: #324248 !important;
  border-color: transparent !important;
  box-shadow: unset !important;
}

.form-control {
  color: white !important;
}

.heading_a h1 {
  color: white;
}

.heading_a h1 span {
  color: #081A38;
  -webkit-text-fill-color: #081A38;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

section {
  padding: 60px 0;
}
section.cryptoExchange{
background-image: url(../assets/video/crypto_exchange.png);
height: 100vh;
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    padding: 0;
}
</style>
