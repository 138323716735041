<template>
  <div>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">


    
      <swiper-slide v-for="(prise, index) in prise_data" :key="index">
        <div class="mainmarket_slide">
          <div class="mainmarket_slide_a">
            <div class="sub_div">
              <div class="text-center">
                <img
                  :src="
                    require(`@/assets/playearn_elements/svg_files/${prise.img}.svg`)
                  "
                  alt=""
                  class="img-fluid svg_img p-3"
                />
              </div>
              <div class="text-center market_slide mt-3">
                <span class="fs-6 text-white fw-semibold">{{
                  prise.head
                }}</span>
                <p class="text-white mt-3">{{ prise.para }}</p>
              </div>
            </div>
          </div>

          <div class="another_div position-relative">
            <span class="clip_path d-flex justify-content-center align-items-center fs-5 fw-bold">{{prise.clip}}</span>
            <span class="line_A d-block position-relative"></span>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<style scoped>
.prise_slide:hover {
  background-color: #191d25;
  border: 1px solid #0964f3;
  padding: 10px;
}
.mainmarket_slide_a {
  border: 2px solid #555657;
  height: 355px;
  padding: 15px;
  background: #222325;
  position: relative;
}
.swiper-slide.swiper-slide-active .mainmarket_slide_a {
  background-color: #17181d;
  border: 2px solid#1362df;
}
span.line_A {
  background-color: #555657;
  height: 100px;
  width: 2px;
  margin: 0 auto;
}
.clip_path {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #7f8585;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -48px;
    -webkit-clip-path: polygon(50% 0%, 100% 24%, 100% 73%, 48% 100%, 0 71%, 0 28%);
    clip-path: polygon(50% 0%, 100% 24%, 100% 73%, 48% 100%, 0 71%, 0 28%);
}
.swiper-horizontal > .swiper-pagination-bullets {
  bottom: 0 !important;
}
.swiper {
  height: 560px !important;
}
.market_slide p {
  font-size: 12px;
}
img.svg_img {
  width: 115px;
  height: 115px;
}
@media (max-width: 991px){
.market_slide p{
    font-size: 11px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  img.svg_img {
    width: 80px !important;
    height: 80px !important;
  }
  .market_slide p {
    font-size: 10px;
  }
}
</style>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles

import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, FreeMode, Pagination, Navigation],
    };
  },
  data() {
    return {
      prise_data: [
        {
          img: "m_svg2",
          head: "Search Engine Optimization (SEO)",
          para: "Known for our influencer marketing expertise, our crypto branding agency connects crypto influencers with a defined audience, and we will market your crypto through these influencers. Social media platforms like Twitter, Reddit, Youtube, and Instagram effectively market cryptocurrency.",
          clip:"01"
        },
        {
          img: "m_svg3",
          head: "Social Media Marketing",
          para: "The reach of social media platforms is well-recognized, regardless of the type of brand or business. Several social media platforms are suitable for spreading cryptocurrencies, including Discord, Twitter, Reddit, and Telegram. Our marketers create valuable content using social media platforms to market your crypto.",
          clip:"02"
        },
        {
          img: "m_svg1",
          head: "Press Releases ",
          para: "Blockchain PR is part of our marketing services as well. Press releases are increasingly influential since they are considered legitimate sources of information. To boost your crypto's visibility and credibility, our PR team approaches acclaimed PR websites.",
          clip:"03"
        },
        {
          img: "m_svg4",
          head: "Airdrops / Bounty Campaigns",
          para: "By offering crypto tokens as an incentive, airdrops or bounty campaigns capture potential and existing audiences through crypto marketing. ",
          clip:"04"
        },
        {
          img: "m_svg5",
          head: "Influencer Marketing ",
          para: "Known for our influencer marketing expertise, our crypto branding agency connects crypto influencers with a defined audience, and we will market your crypto through these influencers. Social media platforms like Twitter, Reddit, Youtube, and Instagram effectively market cryptocurrency.",
          clip:"05"
        },
        {
          img: "m_svg6",
          head: "Community Management",
          para: "Using community marketing means we get to connect only with potential audiences. Telegram, Discord, and Reddit are popular social media platforms that make it easy to build communities.",
          clip:"06"
        },
      ],
    };
  },
};
</script>
