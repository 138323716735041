<template>
<div>
  <NavBar />

  <section class="gallery_home">
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-8">
          <PrivacyHead data="About" data2="LBM" />
        </div>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="offset-lg-1 col-lg-5 text-center">
          <img src="@/assets/aboutus/robo.png" class="img-fluid" alt="img">
        </div>
        <div class="col-lg-6">
          <AllHeadings text3="About" text4=" Us " />          
          <span class="text-white fs-5">"We believe that by working together we can achieve anything"</span>
          <p class="text-white  mb-4 pt-3"><span class="blue_color fw-semibold">LBM Solutions </span>is a market leader in providing innovative <span class="blue_color fw-semibold">Software Solutions</span> and services to business and government organizations throughout the world. For more than <span class="blue_color fw-semibold">5 years</span> , we have been helping our clients solve their most complex business challenges and improve their performance.</p>
          <p class="text-white">At our company, we pride ourselves on creating unique and innovative software solutions that help our clients stay ahead of the competition. Our team of <span class="blue_color fw-semibold">Dedicated Professionals</span> is committed to providing the highest quality products and services, and our company culture is one of collaboration and creativity</p>
        </div>
        
      </div>
    </div>
  </section>

  <section class="what_belive">
    <div class="container">
      <div class="row justify-content-center align-items-center row_reverse">
        <div class="col-lg-6">
          <AllHeadings text3="What We " text4="  Believe " />

          <p class="text-white">
            <span class="blue_color fw-semibold">Our company</span> is all about people. Individuals who are passionate a
            about their work and dedicated to making a difference. Our culture is
            one of collaboration and respect. We value diverse perspectives and
            talents, and believe that by working together we can achieve great
            things. Our company Bio showcases our company's core values,
            commitment to <span class="blue_color fw-semibold">excellence</span>, and <span class="blue_color fw-semibold">dedication </span>to our customers.
          </p>
          <p class="text-white">It tells the story of who we are, what we do, and why we do it. The bio showcases our people, our products, and our services. It gives an overview of our history, highlights our milestones, and defines what
            sets us apart from other companies in the industry. </p>
        </div>
        <div class="offset-lg-2 col-lg-4 text-center pb-3">
          <div>
            <img src="@/assets/aboutus/believe_lbm.png" class="img-fluid belive_img" alt="img">
          </div>
        </div>

      </div>

    </div>

  </section>

  <section class="lbm_brands">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-lg-12">
        <AllHeadings text3="Our" text4=" Brands"/>
      </div>
    </div>
    <div class="row justify-content-center gap-5">
      <div class="col-lg-3 text-center" v-for="(brands, index) in brands_data" :key="index">
        <div class="brands_img">
          <router-link to="/"><img :src="require(`@/assets/aboutus/${brands.img}.png`)" alt="" class="img-fluid" loading="lazy"/></router-link>
        </div>
        
      </div>
    </div>
  </div>
</section>


<section class="quotes">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
      <h1 class="text-white"> "Harness the power of technology to improve  your business"</h1> 
      <div class="text-center mt-5">
        <AllButton text="Contact Us"/> 
      </div>
      </div>
    </div>
  </div>
</section>



<section class="lbm_life">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-6">
      <AllHeadings text3="Life At" text4=" LBM? " text5=" “Our philosophy is to value collaboration and creativity.” "/>
      <p class="text-white m-0 pt-3"><span class="blue_color fw-semibold">LBM</span> is an exciting place to work, with a tone of voice that is creative, professional, inspiring, and enticing. We provide a modern and innovative work culture that is perfect for those who want to be at the forefront of new technologies. The company has a <span class="blue_color fw-semibold">team-oriented philosophy</span> that values collaboration and creativity. Employees at LBM are given the best opportunities to learn and grow in their careers.</p>
    </div>
    <div class="offset-lg-1 col-lg-5">
      <div>
        <img src="@/assets/aboutus/life_lbm.png" class="img-fluid">
      </div>
    </div>
    </div>
  </div>
</section>

<section class="lbm_awards">
  <div class="container">
    <div class="row align-items-center justify-content-center text-center">
      <div class="col-lg-12">
        <AllHeadings text3="Awards"/>
      </div>
    </div>
    <div class="row justify-content-center gap-5">
      <div class="col-lg-2 text-center" v-for="(award, index) in award_data" :key="index">
        <div class="brands_img">
          <img :src="require(`@/assets/aboutus/${award.img}.jpg`)" alt="" class="img-fluid" loading="lazy"/>
        </div>
      </div>
    </div>
  </div>
</section>





  <LbmFooter />
</div>
</template>

<script>
import LbmFooter from '@/components/LbmFooter.vue';
import NavBar from '@/components/NavBar.vue';
import AllHeadings from '@/components/AllHeadings.vue';
import PrivacyHead from '@/components/PrivacyHead.vue';
import AllButton from '@/components/AllButton.vue';

export default {
  name: 'HomeView',
  components: {
    LbmFooter,
    NavBar,
    AllHeadings,
    PrivacyHead,
    AllButton
},
data(){
      return{
        award_data:[
          {
            img:"et",
          },
          {
            img:"ficci",
          },
          {
            img:"iso",
          },
          {
            img:"ceo",
          },
        ],
        brands_data:[
          {
            img:"blck",
          },
          {
            img:"teqo",
            
          },
          {
            img:"in",
          },
        ]

      }
}
}
</script>

<style scoped>
section.gallery_home {
    background-image: url(@/assets/img/gallery_banner1.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 150px 0;
    background-position: 100% 100%;
    margin-top: 75px;
}
section.quotes{
  background-image: url(@/assets/aboutus/cta.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
    padding: 80px 0;
    z-index: 0;
    background-attachment: fixed;
}
section.quotes:before {
    content: ":";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #000000b3;
    z-index: -1;
}
img.belive_img {
    filter: drop-shadow(1px 1px 1px white);
}
section.lbm_brands .col-lg-3.text-center:nth-child(2) {
    border-left: 1px solid #ffffff61;
    border-right: 1px solid #ffffff61;
}

section{
  padding: 60px 0;
}























</style>
