<template>
  <div>
    <swiper :slidesPerView="3" :spaceBetween="30" :freeMode="true" :centeredSlides="true" :navigation="false" :autoplay="{
      delay:2500,
      disableOnInteraction: false,
    }" :loop="true" :pagination="{
      clickable: true,
    }" :modules="modules" :breakpoints="{
      
      
       '320': {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 8,
        spaceBetween: 90,
      },
    }" class="mySwiper">
      <swiper-slide v-for="(prise, index) in prise" :key="index">
        <div class="prise_slides">
          <div class="text-center"><img :src="require('@/assets/Dapp_page/img/'+prise.img+'.png')" alt=""
              class="img-fluid"></div>

        </div>
      </swiper-slide>
    </swiper>
  </div>

</template>
<style scoped >
.prise_slide:hover {
  background-color: #191d25;
  border-bottom: 4px solid #0964F3;
  padding: 10px;

}

.prise_slide {
  border-bottom: 1px solid transparent;
  height: 240px;
  padding: 10px;
}

.prise_head p {
  font-size: 14px;
}
</style>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles


import "swiper/css/free-mode";
import "swiper/css/pagination";



// import required modules
import { Autoplay, FreeMode, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, FreeMode, Navigation],
    };
  },
  data() {
    return {
      prise: [
        {
          img: "plag_svg_a",

        },
        {
          img: "plag_svg_b",

        },
        {
          img: "plag_svg_c",

        },
        {
          img: "plag_svg_d",

        },
        {
          img: "plag_svg_e",

        },
        {
          img: "plag_svg_f",

        },
        {
          img: "plag_svg_g",

        },
        {
          img: "plag_svg_h",

        },

      ]
    }
  }
};
</script>

<style scoped>
section.platform .swiper-slide.swiper-slide-active {
    width: 130px !important;
    height: 130px !important;
}
 .swiper-wrapper {
  padding: 80px 0 !important;
    align-items: center !important;
}
.swiper-wrapper {
    align-items: center;
}
</style>
  