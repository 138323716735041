<template>
  <div class="page text-center">
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      ref="exampleModal"
    >
      <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              @click="hideModal()"
              type="button"
              class="btn-close text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img src="@/assets/icons/cross.svg" alt />
            </button>
          </div>
          <img
            src="@/assets/img/contact-popup.png"
            alt=""
            class="model_img img-fluid"
          />

          <div class="modal-body position-relative">
            <div class="row model-body-row justify-content-center">
              <div class="col-xl-9 col-lg-12 col-md-12">
                <div class="row inner-row model_contant justify-content-center text-center">
                  <div class="col-xl-12 col-lg-12 col-md-12 mb-3">
                    <div class="modal_logo text-center"></div>

                    <div class="modal-info">
                        <!-- <div><img src="@/assets/img/logo_lbm.png" alt="" class="img-fluid pt-3"/></div> -->
                      <h2 class="talk_text">For Further Information</h2>

                      <h5 class="py-3">Please Submit your Details?</h5>
                    </div>
                  </div>
                  <!--col-xl-12 col-lg-12 col-md-12-->

                  <div class="col-xl-10 col-lg-10 col-md-12 mb-3">
                    <form class="form" @submit.prevent="formSubmit">
                      <div class="row form-row justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 mb-4 mx-auto">
                          <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">
                                <span class="iconify" data-icon="ant-design:user-outlined"></span></span
                            >
                            <input
                              v-model="name"
                              type="text"
                              class="form-control"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              placeholder="Name"
                            />
                          </div>
                          <div
                            class="input-errors mt-2"
                            v-for="error of v$.name.$errors"
                            :key="error.$uid"
                          >
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 mb-4 mx-auto">
                          <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"
                              ><span class="iconify" data-icon="akar-icons:phone"></span></span
                            >
                            <span
                              class="input-group-text country-drop"
                              id="basic-addon1"
                            >
                              <div class="dropdown">
                                <button
                                  class="btn set_colour dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span>
                                    <!-- <img :src="'https://flagpedia.net/data/flags/normal/' + country.code.toLowerCase() + '.png'" alt="" loading="lazy" class="img-fluid" style="width:14px" height="14px"> -->
                                    <img
                                      :src="
                                        require(`../assets/country/${country.code.toLowerCase()}.svg`)
                                      "
                                      alt=""
                                      loading="lazy"
                                      class="img-fluid"
                                      style="width: 14px"
                                      height="14px"
                                    />
                                    {{ country.number }}</span
                                  >
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li
                                    v-for="(country, index) in ACountries"
                                    :key="index"
                                    @click="countryChange(country)"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                    >
                                      <span>
                                        <!-- <img :src="'https://flagpedia.net/data/flags/normal/' + country.code.toLowerCase() + '.png'" alt="" loading="lazy" class="img-fluid" style="width:14px" height="14px"> -->
                                        <img
                                          :src="
                                            require(`../assets/country/${country.code.toLowerCase()}.svg`)
                                          "
                                          alt=""
                                          loading="lazy"
                                          class="img-fluid"
                                          style="width: 14px"
                                          height="14px"
                                        />
                                        {{ country.number }}</span
                                      >
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </span>

                            <input
                              v-model="phonenumber"
                              type="text"
                              class="form-control"
                              aria-label="Username"
                              id="number-field"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                          <div
                            class="input-errors mt-2"
                            v-for="error of v$.phonenumber.$errors"
                            :key="error.$uid"
                          >
                            <div class="error-msg">{{ error.$message }}</div>
                          </div>
                        </div>

                        <div class="col-xl-10 col-lg-10 col-md-10 mx-auto">
                          <div class="submit-button">
                            <button
                              class="btn btn-primary"
                              type="submit"
                              v-if="!loading"
                            >
                              Submit
                            </button>

                            <button
                              class="btn btn-primary"
                              type="button"
                              v-else
                            >
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden"></span>
                              </div>
                            </button>
                          </div>
                          <!--submit-button-->
                        </div>
                        <!--col-xl-10 col-lg-10 col-md-10-->
                      </div>
                      <!--row form-row-->
                    </form>
                  </div>
                  <!--col-xl-12 col-lg-12 col-md-12 mb-3-->
                </div>
                <!--row inner-row-->
              </div>
              <!--col-xl-7 col-lg-7 col-md-7-->

              <div
                class="col-xl-5 col-lg-5 col-md-5 align-self-center order-first order-sm-1"
              >
                <!-- <div class="mobile-box">
                                    <img src="@/assets/img/logo_lbm.png" alt="" class="img-fluid" />
                                </div> -->
              </div>
              <!--col-xl-5 col-lg-5 col-md-5-->
            </div>
            <!--row model-body-row-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import CountryCodejson from "@/assets/jsonfiles/CountryCode.json";
import DCountries from "@/assets/jsonfiles/countries.json";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ApiClass from "@/api/api";

export default {
  name: "ModalView",
  data() {
    return {
      country: {
        number: "+91",
        flag: "🇮🇳",
        code: "IN",
      },
      CountrySelect: CountryCodejson.CountryCode,
      ACountries: DCountries.sort(
        (a, b) =>
          parseFloat(a.number.substring(1)) - parseFloat(b.number.substring(1))
      ),
      name: "",
      phonenumber: "",
      loading: false,
      my_timeout: null,
      my_modal: null,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {
    this.my_modal = new Modal(this.$refs.exampleModal);
    this.my_modal.show();
  },
  methods: {
    countryChange(selected_country) {
      console.log("HERE");
      this.country = selected_country;
      this.country.number = selected_country.number;
    },

    showModal() {
      this.my_modal.show();
    },

    hideModal() {
      this.my_modal.hide();
    },
    resetForm() {
      this.country = {
        number: "+91",
        flag: "🇮🇳",
        code: "IN",
      };
      this.phonenumber = "";
      this.name = "";
      this.v$.$reset(); // reset validation
    },

    async formSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }

      let form_data = {
        leaddata: {
          name: this.name,
          phonenumber: this.phonenumber,
          country_code: this.country.number,
          status: "2",
        },
      };
      this.loading = true;

      let response = await ApiClass.postRequest(
        "Leads/addlead",
        false,
        form_data,
        null,
        null,
        "https://login.lbmsolutions.in/"
      );

      this.loading = false;
      if (response?.data) {
        this.hideModal();
        if (response.data.status == "success") {
          this.resetForm();
          this.$swal({
            position: "center",
            icon: "success",
            title: response.data.msg,
            showConfirmButton: false,
            timer: 3000,
          });

          return;
        }

        if (response.data.status == "error") {
          this.$swal({
            position: "center",
            icon: "error",
            title: response.data.msg,
            showConfirmButton: false,
            timer: 3000,
          });

          return;
        }
      }

      this.loading = false;
    },
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("Name is Required", required),
      },
      phonenumber: {
        required: helpers.withMessage("Number is Required", required),
      },
    };
  },
};
</script>

<style scoped>
  /* Modal Css Start */

.modal {
  z-index: 9999;
}

.talk_text{
    color: #000;
    text-align: center;
    margin: 0;
    padding-top: 25px;
    letter-spacing: 2px;
    font-weight: 700;
}
.modal-body h5 {
  color: #000 !important;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.modal-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #f7f7f7;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    position: relative;
    box-shadow: 0 0 15px rgb(255 255 255 / 15%);
}

.input-group {
    box-shadow: 1px 1px 29px #0000001c !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0;
  color: var(--white);
  background: var(--talk-text);
  border: 0;
  border-radius: 0.25rem;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.btn-close:focus {
  box-shadow: none;
}

.input-group .form-control {
    padding-left: 10px;
    border-radius: 0;
    background-color: #fff !important;
    color: #000 !important;
    border: unset !important;
    border-left: 1px solid #ccc !important;
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
}
input#number-field {
    border-left: unset !important;
}

.input-group-text:before {
  position: absolute;
  content: "";
  top: 0;
  left: 80px;
  right: 0;
  margin: auto;
  width: 1px;
  z-index: 999;
  height: 25px;

  bottom: 0;
}

.input-group-text {
  position: relative;
  background-color: #0964f3;
  color: #000;
  border: none;
}
button.btn-close{
    position: absolute;
    right: -7px;
    top: -10px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 50px #0964f3;

}
.btn-close:hover {
   opacity: unset !important;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  box-shadow: none;
}

.form-control:focus {
  color: var(--white);
}

.modal_logo {
  position: relative;
}

/* .modal_logo::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 275px;
        background-repeat: no-repeat;
        height: 42px;
        background-image: url(@/assets/img/award.jpg);
        z-index: -1;
    } */

.btn.btn-primary {
  background-color: #0964f3 !important;
  border-color: #0964f3 !important;
  color: #fff !important;
  min-width: 250px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 17px;
  text-transform: capitalize;
  transition: all 0.5s ease;
}
button.btn-close img {
    filter: invert(1);
}

.btn.btn-primary:focus {
  box-shadow: none;
}

.mobile-box {
  transform: translate(140px, 0);
}

.row.inner-row {
  transform: translate(118px, 0);
}

.country-drop {
  padding: 0;
  padding-left: 0px;
  padding-left: 6px;
}

.input-group-text.country-drop {
  position: relative;
}

.input-group-text.country-drop::before {
  display: none !important;
}

.input-group .input-group-text.country-drop {
  padding: 0;
  background-color: #fff !important;
}

.input-group .input-group-text.country-drop .dropdown {
  padding: 0;
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
}

.input-group .input-group-text.country-drop .dropdown #dropdownMenuButton1 {
  background-color: #fff !important;
  color: var(--white);
  border: none;
}

.input-group
  .input-group-text.country-drop
  .dropdown
  #dropdownMenuButton1:focus {
  box-shadow: none;
}

.dropdown-menu {
  max-height: 245px !important;
  overflow-y: scroll;
  z-index: 1;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .row.inner-row {
    transform: none;
  }

  .mobile-box {
    transform: none;
    margin-bottom: 30px;
  }
  button.btn-close {
    right: 5px;
  }
}

.set_colour {
  background-color: #fff !important;
  border-radius: unset !important;
  border-left: 1px solid #ccc !important;
}
.modal_logo img{
  position: absolute;
  top: -233px;
  width: 50%;
}

/* model _box contant */
.model_contant {
  transform: unset !important;
}


img.model_img.img-fluid{
    position: absolute !important;
    top: -226px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
}
.modal.fade .modal-dialog {
  transform: translate(0, 50px)  !important;
}
.input-group .input-group-text.country-drop .dropdown #dropdownMenuButton1 {
    color: #000 !important;
}
svg.iconify {
    color: #fff;
}

@media all and (min-width: 320px) and (max-width: 991px) {
  img.model_img.img-fluid {
    display: none;
}
}
/* model _box contant */

.modal{
  top: 10px !important;
}









/* Modal Css End */
</style>
