<template>
    <div>
        <NavBar/>
        <section class="gallery_home">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-8">
                        <PrivacyHead data="Our" data2="Gallery"/>
                    </div>
                </div>
            </div>
        </section>

        <section class="gallery_culture">
            <div class="container">
                <div class="row justify-content-center align-items-center text-center">
                    <div class="col-lg-10">
                        <AllHeadings text3="Work" text4=" Culture"/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-lg-5 mt-3">
                    <div class="col-lg-7 col-md-12">
                        <div class="culture_img">
                            <img src="@/assets/img/img1.png" class="img-fluid" loading="lazy">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-3 col-sm-6 col-6 mt-lg-0 mt-4" v-for="(culture, index) in culture_data" :key="index">
                                <div class="text-center">
                                    <img :src="require(`@/assets/img/${culture.img}.png`)" alt="" class="img-fluid" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="gallery_award">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-8">
                        <AllHeadings text3="Awards &amp" text4=" Achievements"/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-lg-5 mt-3">
                    <div class="col-lg-12">
                    <swiper :effect="'coverflow'"  
                        :autoplay="{
                        delay: 2500,
                        disableOnInteraction: false,
                        }" :grabCursor="true" :centeredSlides="true" :slidesPerView="'3'" :coverflowEffect="{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                        }" :pagination="false" :modules="modules" :loop="true" class="mySwiper">
                        
                        <swiper-slide v-for="(gallery, index) in gallery_data" :key="index">
                            <div> <img :src="require(`@/assets/img/${gallery.img}.png`)" alt="" class="img-fluid" loading="lazy"/></div>>
                        </swiper-slide>
                    </swiper>
                </div>
                </div>
                
            </div>
        </section>

        <section class="gallery_videos">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-10">
                        <AllHeadings text3="LBM" text4=" videos"/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-lg-5 mt-3 text-center">
                    <div class="col-lg-12">
                        <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="true" :navigation="true"  :breakpoints="{
                            '640': {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            },
                            '768': {
                            slidesPerView: 2,
                            spaceBetween: 40,
                            },
                            '1024': {
                            slidesPerView: 3,
                            spaceBetween: 50,
                            },
                            }" :modules="modules" class="mySwiper">
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/FO3AlJhY7ZM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/ZIzGJz8Z0rI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/wLA82khoMRY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/NuvtOx9uMr0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/H0ptZ7qZiQQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                            <swiper-slide>
                                <iframe width="" height="" loading="lazy" src="https://www.youtube.com/embed/LkLQ2unrm0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </section>

        <LbmFooter/>
    </div>
</template>

<script>
//Import Swiper Vue.js components
import {
  Swiper,
  SwiperSlide
} from "swiper/vue";

// Import Swiper styles

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {Autoplay, FreeMode } from "swiper";
// import required modules
import {
  EffectCoverflow,
  Pagination
} from "swiper";
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import PrivacyHead from '@/components/PrivacyHead.vue';
import AllHeadings from '@/components/AllHeadings.vue';

export default {
  name: 'GalleryPage',
  components: {
    NavBar,
    LbmFooter,
    PrivacyHead,
    AllHeadings,
    Swiper,
    SwiperSlide,
},
data(){
            return{
                culture_data:[
                    {
                    img:"img2"
                    },
                    {
                    img:"img3"
                    },
                    {
                    img:"img4"
                    },
                    {
                    img:"img5"
                    }
                ],
                gallery_data:[
                    {
                        img:"slide1",
                    },
                    {
                        img:"slide2",
                    },
                    {
                        img:"slide3",
                    }
                ]
}
},
setup() {
    return {
      modules: [EffectCoverflow, Pagination,Autoplay, FreeMode],
    };
  },
}
</script>

<style scoped>
    section.gallery_home{
        background-image: url(@/assets/img/gallery_banner1.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 50vh;
        margin-top:75px;
    }

    section{
        padding: 40px 0;
    }


</style>

