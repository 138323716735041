<template>
    <div>
        <NavBar/>

        <section class="token_home">
            <!-- <div class="main-banner">
                <video autoplay="" muted="" loop="" id="bg-video">
                    <source src="@/assets/video/token_banner.mp4" type="video/mp4" loading="lazy">
                </video>
             
            </div> -->
            <div class="video_caption">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-7">
                        <div class="home_header">
                        <Section_Heading text1="An Attractive Token That" text2=" Appeals To Investors!" text3="Make sure your physical assets are protected. Let experts at LBM Blockchain Solutions tokenize them for you.">
                        </Section_Heading>
                        <AllButton text="Explore More"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="token_category">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-9">
                        <AllHeadings text3="Our Tokens Have Been Categorized To Make Your" text4=" Life Easier "/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-5">
                    <div class="col-lg-3 col-md-6" v-for="(token, index) in token_data" :key="index">
                        <div class="category_main p-4">
                            <div class="category_bg mb-3">
                                <span class="text-white fs-2">{{token.head}}</span>
                            </div>
                            <span class="text-white fs-5">{{token.sub_head}}</span>
                            <p class="text-white m-0 pt-2">{{token.para}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="token_encryption">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <AllHeadings text3="Tokenization" text4=" Is The First Step"/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-5 col-md-6" v-for="(encrypt, index) in encrypt_data" :key="index">
                                <div class="data_encrypt d-flex align-items-center gap-4 mb-3">
                                    <div class="token_icon d-flex justify-content-center align-items-center">
                                        <img :src="require(`@/assets/playearn_elements/svg_files/${encrypt.active}.svg`)" alt="" class="img-fluid active_token " loading="lazy"/>
                                        <img :src="require(`@/assets/playearn_elements/svg_files/${encrypt.hover}.svg`)" alt="" class="img-fluid hover_token d-none" loading="lazy"/>
                                    </div>
                                    <p Class="text-white m-0">{{encrypt.para}}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="encrypt_img">
                            <img src="@/assets/img/token_encrypt.png" class="img-fluid" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="token_insight position-relative">
            <div class="container">
                <div class="sight_main">
                    <span class="sight_data"></span>
                    <div class="row align-items-center justify-content-center text-center">
                        <div class="col-lg-6">
                            <BlackHeading head="Insights" span_text=" Into Crypto Tokenization"/>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center position-relative mt-5">
                        <div class="col-lg-4 col-md-6 col-sm-9" v-for="(sight, index) in sight_data" :key="index">
                            <div class="sight_content text-center mb-3 p-lg-4">
                            <span class="fs-6 text-black fw-semibold">{{sight.head}}</span>
                            <p class="text-black m-0 pt-lg-3">{{sight.para}}</p>
                        </div>  
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="token_plateform">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-4">
                                <div class="form_main">
                                <div class="form_content text-center">
                                    <div class="mb-3"><img src="@/assets/img/token_f1.png" class="img-fluid" loading="lazy"></div>
                                    <span class="text-white fs-5 fw-bold">Ethereum</span>
                                </div>
                            </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_main">
                                <div class="form_content text-center ">
                                    <div class="mb-3"><img src="@/assets/img/token_f2.png" class="img-fluid" loading="lazy"></div>
                                    <span class="text-white fs-5 fw-bold">Binance</span>
                                </div>
                                </div>
                                <div class="form_main">
                                <div class="form_content text-center">
                                    <div class="mb-3"><img src="@/assets/img/token_f3.png" class="img-fluid" loading="lazy"></div>
                                    <span class="text-white fs-5 fw-bold">Litecoin</span>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form_main">
                                <div class="form_content text-center">
                                    <div class="mb-3"><img src="@/assets/img/token_f4.png" class="img-fluid" loading="lazy"></div>
                                    <span class="text-white fs-5 fw-bold">Tron</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <AllHeadings text3="Different platforms" text4=" for Crypto Tokens"/>
                    </div>
                </div>
            </div>
        </section>

         <!-- faq start-->
    <section class="token_faq">
    <div class="container">
        <div class="row align-items-center justify-content-center text-center">
            <AllHeadings text3="Frequently asked" text4=" questions"/>
        </div>
      <div class="row align-items-center justify-content-center row_reverse mt-5">
        <div class="col-lg-6 col-md-6">
          <div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <span class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    1. Crypto tokenization: how does it work?
                  </button>
                </span>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Digital tokens (such as shares in a building) are divided up by tokenization, which acts as a means of dividing ownership. They are similar to non-fungible tickets (NFTs), except that they're fungible, so their value depends on the asset's value.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2. Is NFT tokenization?
                  </button>
                </span>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>In cryptography, NFTs (non-fungible tokens) represent real-world objects such as artwork and real estate. They are unique cryptographic tokens that can't be replicated. By tokenizing these tangible assets, you can trade them more efficiently while reducing fraud risk.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    3. Can I tokenize my company?
                  </button>
                </span>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>It's possible to tokenize hotels, superyachts, art, and technology. As a result, any business that owns valuable assets can quickly raise funding via a fast, efficient, and legally compliant process by selling digital shares to accredited investors.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <span class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    4. Are tokenized stocks legal?
                  </button>
                </span>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>It is unclear whether tokenized equity as a traded security is legal and regulated, although initial coin offerings for blockchain-based projects have utilized tokenized equity.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 px-lg-4">
            <img src="../assets/img/token_faq.png" alt="" class="img-fluid" loading="lazy">
        </div>
      </div>
    </div>
  </section>
      <!-- faq end-->

        <LbmFooter/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import AllButton from '@/components/AllButton.vue';
import Section_Heading from '@/components/Section_Heading.vue'
import AllHeadings from '@/components/AllHeadings.vue';
import BlackHeading from '@/components/BlackHeading.vue';


export default {
  name: 'EnterPrise',
  components: {
    NavBar,
    LbmFooter,
    AllButton,
    Section_Heading,
    AllHeadings,
    BlackHeading,

},
data(){
            return{
                token_data:[
                    {
                        head:"01",
                        sub_head:"Stable Token",
                        para:"Since they are perfectly encrypted, no one can forge your transactions. Because they are global, they can be sent anywhere in the world, but they lose their volatility.",
                    },
                    {
                        head:"02",
                        sub_head:"Security Token",
                        para:"A two-factor authentication system provides two-factor authentication for access to products and services.",
                    },
                    {
                        head:"03",
                        sub_head:"Asset Token",
                        para:"Financial regulators consider these to be securities because they are the most recent developments.",
                    },
                    {
                        head:"04",
                        sub_head:"Non-fungible Token ",
                        para:"An NFT is a cryptographically validated identifier that presents digital proof of ownership of a subject.",
                    },
                ],
                encrypt_data:[
                    {
                        active:"tk1",
                        hover:"tkh1",
                        para:"Full Encryption",
                    },
                    {
                        active:"tk2",
                        hover:"tkh2",
                        para:"Data Privacy",
                    },
                    {
                        active:"tk3",
                        hover:"tkh3",
                        para:"Location",
                    },
                    {
                        active:"tk4",
                        hover:"tkh4",
                        para:"Independent",
                    },
                    {
                        active:"tk5",
                        hover:"tkh5",
                        para:"Extremely Easy ",
                    },
                    {
                        active:"tk6",
                        hover:"tkh6",
                        para:"Feasible",
                    },
                    {
                        active:"tk7",
                        hover:"tkh7",
                        para:"Decentralized",
                    },
                    {
                        active:"tk8",
                        hover:"tkh8",
                        para:"Globally Recognized",
                    },
                ],
                sight_data:[
                    {
                        head:"Liquidity Enhancement",
                        para:"Crypto Tokenization Has Improved Liquidity In The Market. Investors And Clients Can Start Working Together Much Sooner. Early Redemption And High Net Worth Have Streamlined The Process.",
                    },
                    {
                        head:"Taking Out The Middlemen",
                        para:"With Crypto Tokenization, There Are No Intermediaries To Interfere With Your Experience. No Commissions Are Charged.",
                    },
                    {
                        head:"Irreversible",
                        para:"By Using Blockchain Technology, The Chances Of Fraud Are Reduced Since The Data Is Non-Removable. Anyone Can Easily Be Caught Imitating Someone Else.",
                    },
                    {
                        head:"Improved Accessibility",
                        para:"Crypto Traders Are Becoming More And More Popular As The Crypto World Expands. In Order To Be A Part Of This, You Must Also Use Your Digital Assets, I.E., Your Tokens. Increasing Accessibility Increases The Reach Of Your Project And Ideas To Millions.",
                    },
                    {
                        head:"A Higher Level Of Transparency",
                        para:"For Security Reasons, The Whole Process Is Totally Transparent, Which Is One Of The Reasons Why It Is More Interesting To Get Involved In The Crypto World. Our Experienced Coders Developed The Smart Contract That Allows You To See From Where Your Token Is Purchased To Its Exchange Point.",
                    },
                    {
                        head:"Effortless Transactions",
                        para:"Automation Would Take Less Time. Experiencing Fast & Feasible Transactions Are Made Possible By Eliminating Intermediaries And Administrations.",
                    },
                ]
            }
}   
}
</script>

<style scoped>
section.token_home .home_header{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
    }
    section.token_home .main-banner {
        position: relative;
    }
    
    section.token_home #bg-video {
        min-width: 100%;
        min-height: 100vh;
        max-width: 100%;
        max-height: 100vh;
        object-fit: cover;
        z-index: -1;
    }
    section.token_home .video_caption{
        position: absolute;
        background-color:#000000b3;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    section.token_category .category_bg{
    background: #FDC94E;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 2px solid #fff;
    margin-top: -60px;
}
section.token_category .category_main{
    background-color: #201E1E;
    height: 200px;
}
section.token_category .category_main:hover{
    background-color: #0964F3;
}

.category_main p{
    font-size: 13px;
}
section.token_encryption .token_icon{
    background: #343232;
    border-radius: 8px;
    height: 45px;
    width: 45px;
}
.data_encrypt{
    background: transparent;
    border-radius: 8px;
    padding: 6px;
}
.data_encrypt:hover {
    background: #1B1919;        
}
.data_encrypt:hover .token_icon{
    background-color: #E9F1FE;
}
.data_encrypt:hover .hover_token{
    display: block !important;
}
.data_encrypt:hover .active_token{
    display: none;
}

/* section token_insight */
section.token_insight  {
    background-color:#ECF3FC;
    
}
 .sight_content{
    height: 160px;
}
.sight_content p {
    font-size: 13px;
}

/* token_insight end */

/* token_plateform */

.custom_space {
    margin-top: 50px;
}
.form_content {
    background: #0F0F0F;
    padding: 40px;
}
.form_main{
    border-radius: 5px;
    padding: 3px;
    background: transparent;
    transition: all 500ms ease-in-out;
}
.form_main:hover {
    background: linear-gradient(180deg, #0964F3, transparent,transparent,transparent,#0964F3);
    transition: all 500ms ease-in-out;
}
/* token_plateform end */

/* faq */

.accordion-item {
  color: white;
  background-color: #181616;
  border: none;
  margin: 15px 0;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/Ico/plus.png) !important;

}

.accordion-button::after {
  background-image: url(../assets/Ico/minus.png);
}

.accordion-button {
  color: white;
  background-color: transparent;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-body p {
    font-size: 12px;
}

/* faq end */
section.token_home {
    position: relative;
}

@media(min-width: 1025px) and (max-width: 1399px){
    .sight_content {
        height: 235px;
}
}
@media(min-width: 992px) and (max-width: 1024px){
    .sight_content {
    height: 240px;
}
}
@media(min-width: 320px) and (max-width: 991px){
    section.token_home .home_header{
        width: 90%;
    }
    section.token_category .category_bg{
        margin-top: 0px;
    }
}
section{
    padding:60px 0;
}

section.token_home{
    background-image: url(../assets/video/token_bg.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
}
</style>