<template>
   <div>
   
     <div class="row justify-content-center">
   <div class="col-12">
     <div class="welcome text-center">
       <marquee behavior="" direction="">    <p class="text-white mb-0 py-2" > Welcome To The LBM Blockchain Solutions</p></marquee>
   
     </div>
   </div>
   </div>
   <header class=" w-100 main_head position-fixed">
   
       <nav class="navbar navbar-expand-lg navbar-light py-2">
         <div class="container">
           <a class="navbar-brand" href="#"><img class="logo img-fluid" alt="Vue logo" src="@/assets/img/logo_lbm.png" /></a>
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
             <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
             <ul class="navbar-nav ms-auto gap-lg-3 mb-2 mb-lg-0">
               <li class="nav-item">
                 <router-link to="/" class="nav-link green_color fw-light">Home</router-link>
               </li>
        





                     <li class="nav-item dropdown">
          <router-link class="nav-link dropdown-toggle green_color" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </router-link>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/token"> <div class="main_nav_parent">
             <div>Token </div> <div>   <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/SmartContract"> <div class="main_nav_parent">
              <div>Smart Contract </div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/CryptoExchange"> <div class="main_nav_parent">
              <div>Crypto Exchange </div> <div> <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <!-- <li><router-link class="dropdown-item green_color mb-2 p-2" to="/CryptoExchange_b"> <div class="main_nav_parent">
              <div>CryptoExchange 1 </div> <div><i class="fa-solid fa-angle-right"></i> </div> 
            </div> </router-link></li> -->


            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/IcoPage"> <div class="main_nav_parent">
              <div>ICO</div> <div> <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/coin"> <div class="main_nav_parent">
              <div>Coin</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>


            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/NFTView"> <div class="main_nav_parent">
              <div>NFT</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>


            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/PlayEarn"> <div class="main_nav_parent">
              <div>PlayEarn</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/marketing"> <div class="main_nav_parent">
              <div>Marketing</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>
          

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/DappPage"> <div class="main_nav_parent">
              <div>DappPage</div> <div> <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>


            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/enterprise"> <div class="main_nav_parent">
              <div>Enterprise</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>

            <!-- <li><router-link class="dropdown-item green_color mb-2 p-2" to="/"> <div class="main_nav_parent">
              <div>Core Blockchain</div> <div>  <i class="fa-solid fa-angle-right"></i></div> 
            </div> </router-link></li> -->

            <li><router-link class="dropdown-item green_color mb-2 p-2" to="/IoT"> <div class="main_nav_parent">
              <div>IoT</div> <div>  <!--<i class="fa-solid fa-angle-right"></i> --></div> 
            </div> </router-link></li>
       
          </ul>
        </li>

     
        
      


                     
               <li class="nav-item">
                 <router-link to="/" class="nav-link green_color text-nowrap fw-light">Careers</router-link>
               </li>
               <li class="nav-item">
                 <router-link to="/AboutUs" class="nav-link green_color text-nowrap fw-light">About Us</router-link>
               </li>
   
               <li class="nav-item">
                 <router-link to="/" class="nav-link green_color text-nowrap fw-light">Blog</router-link>
               </li>
               <li class="nav-item">
                 <router-link to="/gallery" class="nav-link green_color text-nowrap fw-light">Gallery</router-link>
               </li>
               <li class="nav-item">
                 <router-link to="/contact" class="nav-link green_color text-nowrap fw-light">Contact Us</router-link>
               </li>
               
             </ul>
           </div>
         </div>
       </nav>
   
     </header>
   </div>
   </template>
   
   <script>
   export default {
     name: "NavBar",
     mounted: function () {
       let scrollpos = window.scrollY;
       const header = document.querySelector("header");
       const header_height = header.offsetHeight;
       const add_class_on_scroll = () => header.classList.add("fade_in");
       const remove_class_on_scroll = () => header.classList.remove("fade_in");
   
       window.addEventListener("scroll", function () {
         scrollpos = window.scrollY;
         if (scrollpos >= header_height) {
           add_class_on_scroll();
         } else {
           remove_class_on_scroll();
         }
       });
   
     }
   }
   </script>
   
   <style scoped>
   .navbar-nav .nav-link.active {
     color: #fff;
   }
   
   .green_color {
     color: #ffffff94;
   }
   
   .nav-link:focus,
   .nav-link:hover {
     color: #fff !important;
   }
   
   .Services_dropdown[data-v-4295d220] {
     width: 210px;
     display: none;
     left: -10%;
     background: #0000007d;
     -webkit-backdrop-filter: blur(50px);
     backdrop-filter: blur(40px);
     top: 0;
     margin-top: 39px;
     z-index: 999;
   }
   
   ul.Services_dropdown {
     min-width: 200px;
     display: none;
   }
   
   .custom_dropdown:hover .Services_dropdown {
     display: block;
   }
   
   .custom_dropdown>a i {
     transition: all 200ms ease-in-out;
   }
   
   .custom_dropdown:hover>a i {
     transform: rotate(180deg);
   }
   
   span.nav_icon {
     position: absolute;
     top: -32px;
     color: #ffffff21;
     font-size: 32px;
   }
   
   .services_dropdown_inner:before {
     position: absolute;
     content: "";
     height: 100%;
     width: 1px;
     left: -7%;
     right: 0;
     margin-inline: auto;
   }
   
   .navbar-toggler:focus {
     box-shadow: unset !important;
   }
   
   .navbar-toggler {
     background-color: #fff !important;
     padding: 2px 7px !important;
   
   }
   .welcome {
       z-index: 9;
       position: relative;
       background-color: black;
   }
   
   @media (max-width:991px) {
     a.navbar-brand {
       width: 70%;
     }
   
     header {
       background: #262222eb !important;
     }
     .navbar-nav .dropdown-menu {
    position: absolute;
    left: 48px !important;
    height: 289px;
    overflow: scroll;
}
   }
   
   @media (max-width:425px) {
     .Services_dropdown {
       width: 100% !important;
       left: 0 !important;
       background: #212529 !important;
       box-shadow: rgb(99 99 99 / 67%) 0px 2px 8px 0px;
     }
   
   }
   @media(min-width: 426px) and (max-width:991px){
     .Services_dropdown {
   
       left: 0% !important;
     }
   
   
   }
   /* new css */
   .main_nav_parent {
    justify-content: space-between;
    display: flex;
}
.dropdown-menu{
  background: #0000007d !important;
  backdrop-filter: blur(40px);
  padding: 5px !important;
    width: 210px;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: transparent;
  color: #ffffff94;
}


@media screen and (min-width: 991px){
  li.nav-item.dropdown:hover .dropdown-menu{
  display: block;
}
}







   </style>
   