<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2000,
        disableOnInteraction: false,
      }" :breakpoints="{
        '680': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
    <swiper-slide v-for="(slide, index) in slide_data" :key="index">
        <div class="main_slide">
      <div class="text-center about_img ">
        <img :src="require(`@/assets/playearn_elements/svg_files/${slide.icon}.svg`)" alt="" class="img-fluid icon_active"/>
        <img :src="require(`@/assets/playearn_elements/svg_files/${slide.hover}.svg`)" alt="" class="img-fluid icon_hover"/>
      </div>
      <div class="text-center mt-3">
        <span class=" text-white fw-bold">{{slide.content}}</span>
        <p class="text-white mt-2 mt-lg-3">{{slide.items}}</p>
      </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<style scoped >

</style>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/pagination";



// import required modules
import {Autoplay, FreeMode, Pagination } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, FreeMode, Pagination],
    };
  },
  data(){
            return{
              slide_data:[
                    {
                        icon:"blockchain1",
                        hover:"blockchain",
                        content:"Custom Blockchain Solutions",
                        items:"As a distributed ledger technology provider, we develop custom tokens, individual nodes, hash algorithms, and architectures for public and private blockchains. As a result of our consensus protocols, double-spending mistakes are reduced, and third parties are no longer needed."
                    },
                    {
                        icon:"contract1",
                        hover:"contract",
                        content:"Smart Contracts ",
                        items:"We develop decentralized network solutions for e-commerce development, banking, real estate, supply chain, and gaming with our smart contracts. In addition to registering loan requests and intellectual property, establishing decentralized autonomous organizations (DAOs), validating insurance eligibility, and producing credit letters for foreign transactions, we design smart contracts."
                    },
                    {
                        icon:"crypto_currency1",
                        hover:"crypto_currency",
                        content:"White Label Cryptocurrency Exchange Development R",
                        items:"We are developing and deploying centralized, decentralized, and hybrid cryptocurrency exchange software development with authorized white label solutions."
                    },
                    {
                        icon:"metaverse1",
                        hover:"metaverse",
                        content:"Metaverse Development ",
                        items:"With in-depth proficiency in blockchain, NFTs, and Crypto development tools, we propose Metaverse development services for projects spanning NFT marketplaces, social media, and 3D gaming."
                    },
                    {
                        icon:"nft1",
                        hover:"nft",
                        content:"NFT Marketplace Development",
                        items:"We assist firms in creating their protocol-specific decentralized NFT marketplaces to organize NFT trading, biding, and selling digital assets. To crack the interoperability problems, we create cross-chain marketplaces that enable the minting of multi-chain consistent NFTs."
                    },
                    {
                        icon:"token1",
                        hover:"token",
                        content:"Cryptocurrencies & Tokenization ",
                        items:"We enable you to tokenize your assets with supreme trust and realism for more suitable liquidity, risk management, and cost reduction."
                    },
                    {
                        icon:"defi1",
                        hover:"defi",
                        content:"DeFi 2.0 Development",
                        items:"With DeFi 2.0, you can create completely decentralized and transparent platforms and financial products. Backed by the Ethereum blockchain, we'll help you design them."
                    },
                    {
                        icon:"cryptowallet1",
                        hover:"cryptowallet",
                        content:"Cryptocurrency Wallet  Development ",
                        items:"We create secure and safe crypto wallets with hot and cold storage traits, entitling the exchange of diverse digital assets and currencies."
                    },
                    {
                        icon:"dapp1",
                        hover:"dapp",
                        content:"End-to-end DApp Solution",
                        items:"From inventiveness, innovation, and coding to the conclusive launch, we abide by your entire DApp development cycle from beginning to finish via our Blockchain decentralized app development expertise."
                    },
                    {
                        icon:"ico1",
                        hover:"ico",
                        content:"ICO Development",
                        items:"From the initial ICO smart contract concept to the final website deployment, our best blockchain development company provides all-inclusive services for ICOs."
                    },
                    {
                        icon:"whitepaper1",
                        hover:"whitepaper",
                        content:"White Paper Writing Services",
                        items:"We specialize in white paper writing and production for our customers globally. Employ our White Paper faithful writers who are profound about discovering all they can think about you, your brand, products or services, and your target market."
                    },
                    {
                        icon:"consulting1",
                        hover:"consulting",
                        content:"Cryptocurrency Consulting Services",
                        items:"We deliver cryptocurrency consultation services for devising cryptocurrency, wallets, payment gateways, smart contracts, and more. Our professionals have years of knowledge and expertise to comprehend your firm's requirements and deliver first-class resolutions."
                    },
                    {
                        icon:"digitalassets1",
                        hover:"digitalassets",
                        content:"Digital Assets Trading",
                        items:"Create your blockchain solutions for digital trading assets to rev digital value transfers, buy and sell rapidly and forge funds for ripening."
                    },
                    {
                        icon:"marketing1",
                        hover:"marketing",
                        content:"Marketing ",
                        items:"Get professional guidance to specify the requirements of making returns to get your derivative to more elevated levels in the market"
                    },
                    {
                        icon:"audit1",
                        hover:"audit",
                        content:"Smart Contract Audit Development",
                        items:"Automate your approaches, transactions, and contracts while enhancing security and decreasing expenditures with a smart contract. Our extensive services span smart contract design, development, auditing, and optimization."
                    },
                    
                    
                    
                ],
            }
  }
};
</script>
<style scoped>
.main_slide{
    background-color: transparent;
    border: 1px solid #ccc;
    padding: 25px 20px;
    height: 370px;
    border-radius: 15px;
}
.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    background: #7f8585 !important;
    opacity: unset !important;
}

section.about .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-free-mode.mySwiper {
    height: 440px !important;
}

img.icon_active {
    width: 100px;
    height: 100px;
}
img.icon_hover {
    width: 100px;
    height: 100px;
}
/* .main_slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
} */

@media(min-width: 320px) and (max-width:767px){
  .main_slide{
   height: 415px ;
  }
}

@media(min-width: 768px) and (max-width:1399px) {
  .main_slide{
   height: 415px ;
  }
}











</style>