<template>
  <div>
    <NavBar/>
    <section class="marketing_main p-0">
      <!-- <div class="scroll"><span class="iconify" data-icon="bi:arrow-down"></span></div> -->
      <!-- <div class="main-banner">
            <video autoplay="" muted="" loop="" id="bg-video" playsinline>
                <source src="@/assets/video/marketing_video2.mp4" type="video/mp4" loading="lazy">
            </video>
        </div> -->
      <div class="video_caption">
        <div class="row align-items-center justify-content-center text-center">
          <div class="col-lg-8">
            <div class="market_header">
              <Section_Heading text1="Bringing Brands and " text2="Buyers Together!" text="Get all-inclusive Crypto marketing solutions from specialists of cryptocurrency."  
              text3="LBM Blockchain Solutions is on a mission to help brands build powerful momentum around this emerging technology in the world of cryptocurrency." />
              <AllButton text="Get Started"/>
             </div>
          </div>
        </div>
      </div>
    </section>

    <section class="crypto_marketing">
      <div class="container">
        <div class="row align-items-center gap-5 justify-content-center">
          <div class="col-lg-5">
            <div class=""><img src="@/assets/img/crypto.png" class="img-fluid" loading="lazy"></div>
          </div>
          <div class="col-lg-6">
            <AllHeadings text3="What Is Crypto " text4=" Marketing?" text5="Marketing is essential to a firm or trademark that aids entice the potential user base. Likewise, crypto marketing services intend to allure crypto devotees by raising cognition, entertaining audiences, and tracking success metrics." />
            <p class=" text-white fs-6 m-0">LBM Blockchain Solutions has a conventional appellation for delivering blockchain solutions and marketing. We take intakes for edifice marketing tactics from the existing market trends and audiences’ expectations to make them an all-ar</p>
          </div>
        </div>
      </div>
    </section>

    <section class="marketing_service">
      <div class="container">
        <div class="row align-items-center justify-content-center text-center">
          <div class="col-lg-10">
            <AllHeadings text3="Our Crypto Marketing Services" text4=" Encapsulated" />
          </div>
        </div>
        <div class="row align-items-center justify-content-center mt-5">
          <div class="col-lg-10">
          <MarketingSlider/>
           
          </div>
        </div>
      </div>
    </section>

    <section class="marketing_choice">
      <div class="container">
        <div class="row align-items-center justify-content-between flex-column-reverse flex-lg-row">
          <div class="col-lg-7">
            <BlackHeading head="Our Crypto Marketing Services:" span_text=" Why Should You Choose Us?" />
            <p class="text-black market_content fs-6 pt-4">The importance of crypto marketing services cannot be overstated when promoting cryptocurrency 
              and attracting admirers to purchase. Our experience as a crypto branding agency includes marketing cryptocurrencies, launchpads, initial coin offerings (ICOs), and more. Our willingness to take on multiple digital marketing practices for cryptocurrencies has made us one of the most dominant blockchain marketing services providers.</p>
          </div>
          <div class="col-lg-5">
            <div class="mt-md-4"><img src="@/assets/img/choice.png" class="img-fluid" loading="lazy"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="marketing_faq">
      <div class="container">
        <div class="row align-items-center justify-content-between mt-5">
        <div class="col-lg-4">
          <div class="faq-img"><img src="@/assets/img/Object.png" class="img-fliud" loading="lazy"></div>
        </div>
        <div class="col-lg-7">
          <AllHeadings text3="Frequently Asked" text4=" Questions"/>
          <div class="accordion mt-4" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
      <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        1. What should you expect from your crypto marketing firm?
      </button>
    </span>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">You should expect digital marketing services for blockchain projects enclosing Crypto, Exchange, NFTs, DeFi, ICO, Coin or Token development, and more. </p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        2. Should you consider blockchain consultation from your marketing agency? 
      </button>
    </span>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Yes, you should consider expert blockchain consulting services from your marketing agency.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        3. How much does it cost to use a crypto marketing agency?
      </button>
    </span>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">The cost of using a cryptocurrency marketing agency will vary depending on the size and scope of your project. However, we at LBM believe that our prices are competitive and offer excellent value.</p> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        4. What are the uses of blockchain in marketing?
      </button>
    </span>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Using blockchain to market improves transparency and builds trust with customers. Blockchain, for instance, can allow customers to see how big businesses use their data for advertising purposes.</p> 
      </div>
    </div>
    
  </div>
</div>
        </div>
      </div>
      </div>
    </section>

    <LbmFooter/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import NavBar from '@/components/NavBar.vue'
  import Section_Heading from '@/components/Section_Heading.vue';
  import LbmFooter from '@/components/LbmFooter.vue';
  import AllButton from '@/components/AllButton.vue';
  import AllHeadings from '@/components/AllHeadings.vue';
import BlackHeading from '@/components/BlackHeading.vue';
import MarketingSlider from '../components/MarketingSlider.vue';

  export default {
  name: 'MarKeting',
  components: {
    NavBar,
    Section_Heading,
    LbmFooter,
    AllButton,
    AllHeadings,
    BlackHeading,
    MarketingSlider
}
}
</script>

<style scoped>
  section.marketing_main .market_header{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
section.marketing_main .main-banner {
    position: relative;
}

section.marketing_main #bg-video {
    min-width: 100%;
    min-height: 100vh;
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    z-index: -1;
}
section.marketing_main .video_caption{
    position: absolute;
    background-color:#000000b3;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
section.marketing_main {
    position: relative;
}
section.marketing_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}
div#collapseOne, div#collapseTwo, div#collapseThree, div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 5px 5px;
}


section.marketing_faq .accordion-collapse p{
  font-size: 14px !important;
}
section.marketing_faq .accordion-item {
    color: var(--bs-accordion-color);
   
}
section.marketing_faq .accordion-item{
  border: 1px solid #fff !important;
}
section.marketing_faq .accordion-item, section.marketing_faq button.accordion-button.collapsed, section.marketing_faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 5px 5px 0 0;
    margin-bottom: 10px;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
section.marketing_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}
section.marketing_faq .accordion-item, section.marketing_faq button.accordion-button.collapsed{
  background-color: #0e0c0c;
}
section.marketing_faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}
section.marketing_faq .accordion-button:focus {
    box-shadow: unset !important;
}

section.marketing_faq button.accordion-button.mb-0.collapsed {
    background: #000 !important;
    border-radius: 5px !important;
}
section.marketing_choice{
  background-image: url(@/assets/img/marketing_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}



@media(min-width: 320px) and (max-width:991px) {

  section.marketing_main .market_header{
    top: 55%;
    width: 100%;
}


section.marketing_main .video_caption {
    height: 100%;
}


}

@media(min-width: 320px) and (max-width:420px) {
  .faq-img img {
    width: 90%;
}
}


@media(max-width:991px) {
section.marketing_choice{
  background-size:unset;
}
.faq-img {
    text-align: center;
    margin-bottom: 30px;
}
section.crypto_marketing p {
    text-align: center;
}
p.market_content{
    text-align: center;
    font-size: 14px !important;
}
}


@media(min-width: 320px) and (max-width: 1400px) {
section{
  padding-bottom: unset !important;
  padding-top: 40px !important;
}
}
section{
  padding: 100px 0;
}
section.marketing_main{
  background-image: url(../assets/video/marketing_video2.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
}
</style>
