<template>
    <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }" :breakpoints="{
          '640': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }" :modules="modules" class="mySwiper">
  <swiper-slide>
    <div class="main">
      <div class="text-center">
        <img src="@/assets/playearn_elements/img/slider_img_a.png" alt="" class="img-fluid">
      </div>
      <div class="text-center mt-3">
        <span class="fs-5 text-white fw-bold"></span>
        <p class="text-white mt-4"></p>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="main">
      <div class="text-center">
        <img src="@/assets/playearn_elements/img/slider_img_b.png" alt="" class="img-fluid">
      </div>
      <div class="text-center mt-3">
        <span class="fs-5 text-white fw-bold"></span>
        <p class="text-white mt-4"></p>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="main">
      <div class="text-center">
        <img src="@/assets/playearn_elements/img/slider_img_c.png" alt="" class="img-fluid">
      </div>
      <div class="text-center mt-3">
        <span class="fs-5 text-white fw-bold"></span>
        <p class="text-white mt-4"></p>
      </div>
    </div>
  </swiper-slide>
</swiper>
</template>

  
<style scoped >
  </style>
  
<script>
// Import Swiper Vue.js components
import {
  Swiper,
  SwiperSlide
} from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";

// import required modules
import {
  Autoplay,
  FreeMode,
  Navigation
} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay, FreeMode],
    };
  },
};
</script>

  <style scoped>
/* 
 button.slick-next.slick-arrow::before {

content: "" !important;

background-image: url(@/assets/playearn_elements/img/arrow_a.png) !important;

background-position: center center;

background-size: contain;

padding: 33px;

background-repeat: no-repeat;
} */

</style>
