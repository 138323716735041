<template>
  <div class="set_the_button_youtubeset">
    <swiper :slidesPerView="1" :spaceBetween="10"  :navigation="true" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
    <swiper-slide>
      <iframe width="" height="" src="https://www.youtube.com/embed/vr7_CyweFUc" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </swiper-slide>
    <swiper-slide>
      <iframe width="" height="" src="https://www.youtube.com/embed/NuvtOx9uMr0"
        title="Thursday Morning at LBM Solutions #joinlbm" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </swiper-slide>
    <swiper-slide>
      <iframe width="" height="" src="https://www.youtube.com/embed/zqF6xkyY13g" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </swiper-slide>
  </swiper>
  </div>

</template>


<style scoped >
iframe {
display: flex;
  border: 0;
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  margin: 0 auto;
}


</style>






<script>
// Import Swiper Vue.js components
import {
  Swiper,
  SwiperSlide
} from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";

// import required modules
import {
  Autoplay,
  FreeMode,
  Navigation
} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Autoplay, FreeMode],
    };
  },
};
</script>

<style scoped>

</style>
