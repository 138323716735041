<template>
<div>
  <div class="main_img">

    <img :src="require(`@/assets/images/${path}`)" alt="" class=" img-fluid  " loading="lazy">

  </div>

</div>
</template>

<script>
export default {
  name: "MainImage",
  props: {
    path: String,

  },

}
</script>
