<template>
    <div>
        <div class="black_heading">
            <h2 class="fw-bold text-black">{{head}}<span class="blue_color">{{span_text}}</span></h2>         
        </div>
    </div>
    </template>
    <script>
    export default {
      name: 'AllHeadings',
      props:{
        head:String,
        span_text:String
      },
     
    }
    </script>
    <style scoped>
    .black_heading h2{
        font-size: 43px;
    }
    
    
    
    /* Media Query */
    
    @media (max-width:767px) {
    .black_heading h2{
        font-size: 20px !important;
    }
    .black_heading{
      text-align: center !important
    }
    }
    @media (max-width:991px) {
    .black_heading{
      margin-top: 20px;
    }
    }
    @media(min-width: 768px) and (max-width: 991px) {
      .black_heading h2{
        font-size: 25px !important;
    }
    }
    @media(min-width: 992px) and (max-width: 1199px) {
      .black_heading h2{
        font-size: 38px !important;
    }
    }
    </style>