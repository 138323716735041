<template>
<swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
      delay: 2000,
      disableOnInteraction: false,
    }" :breakpoints="{
      '680': {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }" :modules="modules" class="mySwiper">
  
    <swiper-slide>
      <div class="parent_div text-center">
        <div class="main_div">
        <div class="text-center">
          <img src="../assets/Ico/icons/white-papeR.svg" alt="" class="img-fluid set_svg">
        </div>
  
      </div>
      <h6 class="fw-bold mt-3 text-white">Whitepaper Drafting</h6>
        <p class="text-white">ICO whitepapers are the backbone of any ICO. We offer elaboration and expert writing services to make your whitepapers stand out to prospective investors.</p>
      </div>
  
    </swiper-slide>
  
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/roadmap.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">Roadmap Creation</h6>
        <p class="text-white">Our ICO development company creates time-based roadmaps to display the trajectory of the product and security stakeholders to kick off the ICO development process. Without a roadmap, an ICO has no direction and cannot be trusted.
</p>
      </div>
    </div>
    </swiper-slide>
  
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/smart-contract.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white"> Token Development</h6>
        <p class="text-white">  Smart contracts issue ERC20 compatible tokens to Ethereum users during ICOs, preventing the organization that holds the ICO from creating more tokens than is specified in the initial contract. We specialize in ERC20 token development, whether it's ICOs for real estate development or any other venture. </p>
      </div>
    </div>
    </swiper-slide>
  
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/landing-page.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">   Landing Page Design</h6>
        <p class="text-white">The ICO's website must be intuitively designed. Several layouts and designs are available, and we suggest essential information that your business landing page should include.</p>
      </div>
    </div>
    </swiper-slide>
  
   
  
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/smart-contract.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">   Smart Contract Setup</h6>
        <p class="text-white">The development of smart contracts for automating a variety of businesses can be done by us reliably and securely. With more than a decade of experience, our specialists specialize in smart contracts, customer loyalty programs, and blockchain data storage.</p>
      </div>
    </div>
    </swiper-slide>
  
  
    
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/marketing.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">  PR and Marketing</h6>
        <p class="text-white">A strong PR and marketing campaign can help your ICO achieve fantastic outcomes. We are a team of multi-disciplined domain experts committed to delivering a positive customer experience.</p>
      </div>
    </div>
    </swiper-slide>
  
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/ico.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white"> ICO Customer Services</h6>
        <p class="text-white">A dedicated team of ICO software developers will answer all your questions at the beginning of the product development process. Our highly experienced experts will be able to clear all your doubts about how to build an ICO product.</p>
      </div>
    </div>
    </swiper-slide>
    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/exchange.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">Exchange Listing Assistance</h6>
<p class="text-white">We build risk-free and authentic cryptocurrency exchange platforms that will allow tokens and cryptocurrencies to be listed on exchanges and merchant platforms after the ICO launches
</p>
      </div>
    </div>
    </swiper-slide>

    <swiper-slide>
      <div class="parent_div text-center">
      <div class="main_div">
        <div>
          <img src="../assets/Ico/icons/legal.svg" alt="" class="img-fluid set_svg">
        </div>
        <h6 class="fw-bold mt-3 text-white">Legal, Risk, and Compliance
        </h6>
        <p class="text-white">We assure you that your undertaking has a sound management structure and relents with the most delinquent laws in the nations where the token is created, launched, and marketed. We operate with leading law corporations to ensure that your ICO is not witnessed in future compliance and legal matters.</p>
      </div>
    </div>
    </swiper-slide>
  </swiper>
  </template>
  
  <script>
    // Import Swiper Vue.js components
    import {
      Swiper,
      SwiperSlide
    } from "swiper/vue";
    
    // Import Swiper styles
    import "swiper/css";
    
    import "swiper/css/pagination";
    
    // import required modules
    import {
      Autoplay,
      Pagination
    } from "swiper";
    
    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        return {
          modules: [Pagination, Autoplay],
        };
      },
    };
    </script>
  <style scoped>
    .parent_div {
      border: 1px solid #464646;
    height: 400px;
      padding: 30px 10px;
      border-radius: 5px;
  }
  .set_svg {
    filter: invert(1);
    height: 100px;
    width: auto;
}
.swiper-slide.swiper-slide-next {
    border: 1px solid #0964F3;
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px){
  .parent_div {
 
    height: 448px !important;
  } 
}
  </style>