<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2000,
        disableOnInteraction: false,
      }" :breakpoints="{
        '680': {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(data_a, index) in data_a" :key="index">
      

        <div class="layer_b px-4">
              <img :src="require(`@/assets/playearn_elements/svg_files/${data_a.svg}`)" alt="" class="img-fluid svg_img p-3">
              <p class="fs-5 fw-normal">{{ data_a.para }}</p>
              <p class="para_line">{{ data_a.para_a }}</p>
            </div>
      </swiper-slide>
    </swiper>

  
  </template>
  <style scoped >
 .layer_b {
  background-color: white;
    border-radius: 5px;
    height: 400px;
    text-align: center;
    padding: 20px 0;
}
.layer_b p:first-child{
  white-space: pre-line;
}

@media(min-width: 320px) and (max-width:480px){
  p.para_line {
    font-size: 13px;
}
.layer_b {

    height: 380px;
}
.layer_b p:first-child {
  font-size: 1rem!important;
}
}
@media(min-width: 992px) and (max-width:1199px) {
  p.para_line {
    font-size: 13px;
}
.layer_b {

    height: 380px;
}

}













  </style>
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  
  
  import "swiper/css/free-mode";
  import "swiper/css/pagination";
  
  
  
  // import required modules
  import {Autoplay, FreeMode, Pagination, Navigation } from "swiper";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, FreeMode, Pagination, Navigation],
      };
    },
    data(){
              return{
                data_a: [{
          svg: "platform_a.svg",
          para: "Centralized Exchange \n Development",
          para_a: "Our best Cryptocurrency Exchange Development Company will craft a blazing-fast centralized, superior crypto exchange that will allow users to trade cryptocurrencies in a fully secure environment.",
        },
        {
          svg: "platform_b.svg",
          para: "P2P Exchange \n  Development",
          para_a:'Decentralized exchanges allow your customers to trade with confidence, privacy, security, and complete control while avoiding centralization risks. We help our clients build scalable DEXs using distributed ledger technology.'
        },
        {
          svg: "platform_c.svg",
          para: "Decentralized Exchange \n  Development",
          para_a:'By combining the strengths of both centralized and decentralized exchanges, we will build a high-performance P2P crypto exchange platform with advanced features and an effective dispute resolution system.'
        },
        {
          svg: "platform_d.svg",
          para: "Bitcoin Exchange \n  Development",
          para_a:'We build highly accountable, scalable Bitcoin exchange software to facilitate seamless trading and enhance the adoption of digital currencies. Our expertise includes creating Bitcoin exchanges, scripts, and customized Bitcoin exchanges.'
        },
        {
          svg: "platform_e.svg",
          para: "Derivatives Exchange \n  Development Our cryptocurrency ",
          para_a:'Derivative exchange development solutions ensure quick, easy, and secure crypto derivative trading by building feature-rich derivatives exchange platforms that commit to the highest performance standards.'
        },
        {
          svg: "platform_f.svg",
          para: "Margin Trading Exchange \n  Development",
          para_a:'Add margin cryptocurrency trading to your existing business if you want a larger pool of investors to gather over your trading ecosystem. With our ultra-modern trading platforms, customers can earn up to 100X over their investments.'
        },
        {
          svg: "platform_g.svg",
          para: "Non Fungible Token \n  Exchange Development",
          para_a:'Along with the rapid growth of the NFT market, the demand for NFT exchanges is also increasing. Our customers get scalable exchange platforms for a wide range of NFTs that provide world-class trading experiences through LBM Blockchain solutions.'
        },
        {
          svg: "platform_g.svg",
          para: "Security Token \n  Exchange Development",
          para_a:'Our mission is to build a platform with the best UI/UX, security, functionality, scalability, and performance in a trillion-dollar market. As a leading cryptocurrency trading software company, we developed security token exchanges that adhered to the highest security, scalability, and functionality standards.'
        },
        {
          svg: "platform_i.svg",
          para: "Over-the-counter \n  Trading Platform Development",
          para_a:'  Platform Development OTC trading platforms by LBM Blockchain Solutions eliminate slippage and facilitate massive crypto trades rapidly, making it a leading exchange software developer. Let our blockchain development experience and mission-driven solutions lead the way.'
        },
      ]
              }
    }
  };
  </script>
  