<template>
    <div>
        <NavBar/>

        <section class="enterprise-home">
      <!-- <div class="scroll"><span class="iconify" data-icon="bi:arrow-down"></span></div> -->
            <!-- <div class="main-banner">
                <video autoplay="" muted="" loop="" id="bg-video" playsinline>
                    <source src="@/assets/video/enterprise_banner.mp4" type="video/mp4" loading="lazy">
                </video>
            </div> -->
            <div class="video_caption">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-7">
                        <div class="home_header">
                        <Section_Heading text1="The World Leader In" text2=" Enterprise Blockchain Consulting" text3="Leverage the power of blockchain technology to create more innovative business solutions. We help you take your business to the next level by providing comprehensive blockchain development and consulting services at every stage of blockchain adoption. Build highly secure, reliable, and scalable solutions.">
                        </Section_Heading>
                        <AllButton text="Explore More"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="enterprise-dapp">
            <div class="container">
                <div class="row align-items-center text-md-center text-lg-start">
                    <div class="col-lg-6">
                        <AllHeadings text3="Developing DApps" text4=" Effectively!" text5="Our Dapp Development Services Cater To A Wide Range Of Needs." />
                    </div>
                    <!-- <div class="col-lg-6 text-end">
                        <div><img src="@/assets/img/drag.png" class="img-fluid" loading="lazy"></div>
                    </div> -->
                </div>

                <div class="row align-items-center justify-content-center mt-5">
                        <div class="col-md-10">
                            <swiper :slidesPerView="3"  
                            :spaceBetween="30" 
                            :freeMode="true"  
                            :centeredSlides="true"
                            :autoplay="{
                                delay:2500,
                                disableOnInteraction: false,
                                }"
                            :loop="true" 
                           
                            :modules="modules" 
                            :breakpoints="{


                            '320': {
                                    slidesPerView: 1,
                                    spaceBetween: 40,
                                },
                                
                                '640': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                                }"
                            class="mySwiper">
                                <swiper-slide v-for="(slide1, index) in slide_data1" :key="index">
                                    <div class="app_slide">
                                <div class="text-center app_img ">
                                    <div class="position-relative"><img :src="require(`@/assets/img/${slide1.img}.png`)" alt="" class="img-fluid" loading="lazy"/></div>
                                    <img :src="require(`@/assets/playearn_elements/svg_files/${slide1.icons}.svg`)" alt="" class="img-fluid slide_icon" loading="lazy"/>
                                    <img :src="require(`@/assets/playearn_elements/svg_files/${slide1.icons_h}.svg`)" alt="" class="img-fluid slide_icon_hover" loading="lazy"/>
                                </div>
                                <div class="text-center app_content mt-3">
                                    <span class=" text-black fw-bold">{{slide1.content}}</span>
                                    <p class="text-black mt-2">{{slide1.item}}</p>
                                </div>
                                </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                </div>
            </div>
        </section>

        <section class="enterprise_technology">
            <div class="container">
                <div class="row align-items-center justify-content-center flex-column-reverse flex-lg-row">
                    <div class="col-lg-6 col-md-10">
                        <div class="text-center mt-5 mt-lg-0">
                            <video autoplay="" loop="" id="coin_round" playsinline>
                                <source src="@/assets/img/enter_video.mp4" type="video/mp4" loading="lazy">
                            </video>  
                        </div>
                    </div>
                    <div class="col-lg-6 text-center">
                        <AllHeadings text3="Technologies" text4=" We Use"/>
                    </div>
                </div>
            </div>
            
        </section>

        <section class="enterprise_work">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <AllHeadings text3="How We" text4=" Work" text5="Enterprise Blockchain Development Process Working"/>
                    </div>
                    <div class=" col-lg-4 col-md-6 col-sm-9 custom_col" v-for="(work, index) in work_data" :key="index">
                        <div class="enterprise_data position-relative">
                            <h3 class="text-muted work_head">{{work.head}}</h3>
                            <span class="fw-semibold text-white fs-5 work_para">{{work.data}}</span>
                            <p class="text-white mt-3">{{work.para}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="enterprise_unleash position-relative">
            <div class="container">
                <div class="work_main">
                    <span class="work_data"></span>
                <div class="row align-items-center justify-content-center text-center" >
                    <div class="col-lg-6">
                        <BlackHeading head="Unleash The" span_text=" Power Of Blockchain"/>
                        <p class="text-black mt-3">Benefits/Advantages</p>
                    </div>
                    
                </div>
                <div class="row align-items-center justify-content-center position-relative">
                    <div class="col-lg-4 col-md-6 col-sm-9" v-for="(unleash, index) in unleash_data" :key="index">
                        <div class="unleash_data text-center mb-3 mb-lg-5 p-lg-4">
                            <div class="text-center"><img :src="require(`@/assets/playearn_elements/svg_files/${unleash.icon}.svg`)" alt="" loading="lazy" class="img-fluid unleash_icon mb-3"/>
                            <img :src="require(`@/assets/playearn_elements/svg_files/${unleash.hover}.svg`)" alt="" class="img-fluid unleash_icon_hover d-none mb-3" loading="lazy"/></div>
                            <span class="fs-5 text-black fw-bold">{{unleash.data}}</span>
                            <p class="text-black mt-lg-4">{{unleash.para}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <section class="enterprise_industry">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-12">
                        <AllHeadings text3="Industries" text4=" We Serve"/>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-5">
                    <div class="col-lg-3 col-md-6 col-sm-9" v-for="(industry, index) in industry_data" :key="index">
                        <div class="industry_data position-relative text-center mb-5 p-4">
                            <div><img :src="require(`@/assets/img/${industry.img}.png`)" alt="" class="img-fluid ind_img mb-3" loading="lazy"/></div>
                            <span class="text-white fs-6 fw-semibold">{{industry.content}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="enterprise_service">
            <div class="container">
                <div class="row align-items-center coin_bg p-lg-5 p-4 flex-column-reverse flex-md-row text-center text-md-start">
                        <div class="col-lg-7 col-md-6">
                            <h2 class="text-white form_heading">Get Blockchain App Development Services for the growth of all types of business enterprises</h2>
                            <AllHeadings text5="Get in touch with our support team and give wings to your business "/>
                            <div class="enter_btn"><AllButton text="Schedule your Demo"/></div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-10">
                            <div class="text-lg-end text-center mb-5 mb-lg-0">
                                <img src="@/assets/img/service.png" class="img-fluid" loading="lazy">
                            </div>
                        </div>

                    
                </div>
            </div>
        </section>


        <section class="enterprise_innovation">
            <div class="container">
                <div class="row align-items-center justify-content-center text-md-center text-lg-start">
                    <div class="col-lg-4">
                        <AllHeadings text3="Innovative &amp" text4=" Modern Stack" text5="We Use Innovative Technologies To Create Blockchain Applications For Business Enterprises"/>
                    </div>
                    <div class="col-lg-2">
                        <div class="enter_circle"><img src="@/assets/img/circle.png" loading="lazy"></div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-4 mb-5" v-for="(innovation, index) in innovative_data" :key="index">
                        <div class="innovative_img text-center"><img :src="require(`@/assets/img/${innovation.img}.png`)" alt="" class="img-fluid mb-3" loading="lazy"/></div>
                        <div class="text-center"><span class="fs-6 text-white">{{innovation.head}}</span></div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="enterprise_selection">
            <div class="container">
                <div class="row align-items-center justify-content-center mt-lg-5 flex-column-reverse flex-lg-row text-md-center text-lg-start">
                    <div class="col-lg-6">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-9 mb-4" v-for="(selection, index) in selection_data" :key="index">
                        <div class="selection_data d-flex  align-items-center bg_color py-2 px-3 rounded-5">
                            <div class="text-center"><img :src="require(`@/assets/playearn_elements/svg_files/${selection.active_icon}.svg`)" alt="" loading="lazy" class="img-fluid active_icon me-4"/>
                            <img :src="require(`@/assets/playearn_elements/svg_files/${selection.hover_icon}.svg`)" alt="" loading="lazy" class="img-fluid hover_icon d-none  me-4"/></div> 
                            <p class="text-white m-0">{{selection.para}}</p>
                        </div>
                    </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <AllHeadings text3="Why" text4=" choose LBM?" text5="We Are Considered The Most Trusted Company In Blockchain Software Development"/>
                    </div>
                </div>
            </div>
        </section>


        <section class="enterprise_faq">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-12">
          <AllHeadings text3="Frequently Asked" text4=" Questions"/>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-lg-7 col-sm-10">
          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
      <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        What is enterprise blockchain development?
      </button>
    </span>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Blockchain networks designed for large companies and organizations are called enterprise blockchains. The underlying architecture of enterprise blockchain networks enables it to maintain the benefits of blockchain technology, such as privacy, immutability, and decentralization.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Why Companies Choose Blockchain Services?
      </button>
    </span>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Business infrastructures can be secured with blockchain technology, preventing hackers from accessing data. Beyond sending and receiving financial transactions, this extends to storing and transferring customer information, as well as to every aspect of the business.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        How much does it cost to develop a Business Enterprise application?
      </button>
    </span>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">The cost of developing any blockchain related solutions for big business enterprises depends and varies according to its sector and its needs.</p> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        How long does it take to develop a business enterprise application?
      </button>
    </span>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">In general, it takes three to six months to develop an enterprise app from scratch, depending on the requirements. White-label Blockchain solutions can be deployed within one day, whereas custom solutions can take longer.</p> 
      </div>
    </div>
    
  </div>
</div>
        </div>
      </div>
    </section>

        <LbmFooter/>
    </div>
</template>

<script>

    // Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/pagination";



// import required modules
import {Autoplay, FreeMode, Pagination } from "swiper";

// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import AllButton from '@/components/AllButton.vue';
import Section_Heading from '@/components/Section_Heading.vue'
import AllHeadings from '@/components/AllHeadings.vue';
import BlackHeading from "@/components/BlackHeading.vue";


export default {
  name: 'EnterPrise',
  components: {
    NavBar,
    LbmFooter,
    AllButton,
    Section_Heading,
    AllHeadings,
    Swiper,
    SwiperSlide,
    BlackHeading,
},
setup() {
    return {
      modules: [Autoplay, FreeMode, Pagination],
    };
  },
  data(){
            return{
                slide_data1:[
                    {
                        img:"slide_shape",
                        icons:"p",
                        icons_h:"ph",
                        content:"Develop Metaverse Platform",
                        item:"Build a new global platform based on blockchain technology and trade assets in the form of NFTs. Our team helps in developing Metaverse platform for games, social networking, and much more."
                    },
                    {
                        img:"slide_shape",
                        icons:"p1",
                        icons_h:"ph1",
                        content:"Build Supply Chain link",
                        item:"Our blockchain-based solutions can make your global supply chain more efficient and transparent, producing better solutions for all of your supply-chain needs"
                    },
                    {
                        img:"slide_shape",
                        icons:"p3",
                        icons_h:"ph2",
                        content:"Blockchain for Financial Enterprises",
                        item:"We develop blockchain solutions for the financial sector using the latest technologies to improve all of your business transactions relating to security, speed, and efficiency."
                    },
                    {
                        img:"slide_shape",
                        icons:"p2",
                        icons_h:"ph3",
                        content:"Decentralized & Digital Identity",
                        item:"LBM’s decentralized digital identity solution enables businesses to transparently interact with its people and also provides new authentication tools for users to improve privacy by removing tracked versions."
                    },
                    {
                        img:"slide_shape",
                        icons:"p4",
                        icons_h:"ph4",
                        content:"The Blockchain Impact",
                        item:"Use the potential of blockchain to positively impact the social ecosystem. LBM has implemented a variety of tools and services to build solutions that meets future challenges."
                    },
                    {
                        img:"slide_shape",
                        icons:"p5",
                        icons_h:"ph5",
                        content:"Developing Decentralized Applications",
                        item:"Our team of highly skilled developers has extensive experience in using blockchain technology and platforms like Ethereum to suit your specific business goals through our dApps."
                    },
                    {
                        img:"slide_shape",
                        icons:"p6",
                        icons_h:"ph6",
                        content:"CryptoCurrency Wallets",
                        item:"Create a digital crypto wallet with the latest 2-factor authentication for added security. Our professionals provide complete, fully featured support for cryptocurrency transactions through end-to-end encrypted wallets."
                    },
                    {
                        img:"slide_shape",
                        icons:"p7",
                        icons_h:"ph7",
                        content:"Exchange Platform",
                        item:"Believe in our developers to create highly efficient crypto trading exchanges. Our team specializes in cryptocurrency trading, multi account trading and a lot more."
                    },
                    {
                        img:"slide_shape",
                        icons:"p8",
                        icons_h:"ph8",
                        content:"Create a private blockchain",
                        item:"Network administrators control private blockchains and participants are required to consent to join the network, thus making them permissioned blockchains. In private blockchain network only the parties involved in the transaction are aware of the transaction, but others are not allowed to see it."
                    },
                    {
                        img:"slide_shape",
                        icons:"p9",
                        icons_h:"ph9",
                        content:"Smart Contract Development & Auditing Services",
                        item:"LBM is a leading blockchain-based smart contract development and auditing service providers with a promising database of high profile and happy Business enterprises from all across the globe."
                    },
                    
                ],
                work_data:[
                    {
                        head:"01",
                        data:"Strategic Planning",
                        para:"After a thorough understanding of the design requirements, we make the design that helps in a complete transformation of your business.",
                    },
                    {
                        head:"02",
                        data:"Rapid Assessment",
                        para:"Our team then goes through a basic development process to evaluate their design and performance.",
                    },
                    {
                        head:"03",
                        data:"Build & Implement the Framework",
                        para:"We then proceed to the development of a blockchain application and implement a framework in which all the main elements are built.",
                    },
                    {
                        head:"04",
                        data:"Methods and Solutions",
                        para:"Yes ! We offer you tailored solutions according to your needs. If necessary, we implement procedures and security modules, just to give you an extra layer of security",
                    },
                    {
                        head:"05",
                        data:"Ecosystem Management",
                        para:"After creating and testing various configurations, based on our experience, you get yourself a robust blockchain ecosystem.",
                    },
                   

                ],
                unleash_data:[
                    {
                        icon:"ul",
                        hover:"ulh",
                        data:"Increased Security",
                        para:"Privacy issues can be solved with a firewall that prevents hackers from accessing your data.",
                    },
                    {
                        icon:"ul1",
                        hover:"ulh1",
                        data:"Transparent & Fair",
                        para:"All transactions on the blockchain are recorded immutably, allowing participants to see the full transaction history and eliminating any possibility of fraud.",
                    },
                    {
                        icon:"ul2",
                        hover:"ulh2",
                        data:"Accuracy",
                        para:"Traditional processes are time-consuming, prone to error, and not that efficient due to human factors involved. But the blockchain technology offers to eliminate such factors. ",
                    },
                    {
                        icon:"ul3",
                        hover:"ulh3",
                        data:"Faster Transactions",
                        para:"Because of blockchain technology, the digital ledger system speeds up business by automating the process. It also provides storage space for your business.",
                    },
                    {
                        icon:"ul4",
                        hover:"ulh4",
                        data:"Automation Process",
                        para:"Transactions are performed automatically. Smart contracts help automate the transactions, thus making the process more efficient, fast, and low cost.",
                    },

                    
                ],
                industry_data:[
                    {
                        img:"ind1",
                        content:"Supply chain",
                    },
                    {
                        img:"ind2",
                        content:"Finance & Banking",
                    },
                    {
                        img:"ind3",
                        content:"HealthCare",
                    },
                    {
                        img:"ind4",
                        content:"Real-Estate",
                    },
                    {
                        img:"ind5",
                        content:"Retail",
                    },
                    {
                        img:"ind6",
                        content:"Government Departments",
                    },
                    {
                        img:"ind7",
                        content:"Travel and Tourism",
                    },
                    

                ],
                innovative_data:[
                    {
                        img:"python",
                        head:"Python",
                    },
                    {
                        img:"angular",
                        head:"Angular",
                    },
                    {
                        img:"react",
                        head:"React",
                    },
                    {
                        img:"swift",
                        head:"Swift",
                    },
                    {
                        img:"kotlin",
                        head:"Kotlin",
                    },
                    {
                        img:"express",
                        head:"Express",
                    },
                ],
                selection_data:[
                    {
                        active_icon:"inn1",
                        hover_icon:"in1",
                        para:"Highly Skilled Staff",  
                    },
                    {
                        active_icon:"inn2",
                        hover_icon:"in3",
                        para:"Systematic & Simple Aprroach",  
                    },
                    {
                        active_icon:"inn3",
                        hover_icon:"in2",
                        para:"Flexible And Diverse Hiring Models",  
                    },
                    {
                        active_icon:"inn4",
                        hover_icon:"in4",
                        para:"Technically Proficient",  
                    }
                ]
                
            }
    }
}
</script>

<style scoped>

section.enterprise-home .home_header{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 68%;
    }
    section.enterprise-home .main-banner {
        position: relative;
    }
    
    section.enterprise-home #bg-video {
        min-width: 100%;
        min-height: 100vh;
        max-width: 100%;
        max-height: 100vh;
        object-fit: cover;
        z-index: -1;
    }
    section.enterprise-home .video_caption{
        position: absolute;
        background-color:#000000b3;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    img.slide_icon{
    width: 45px;
    height: 45px;
    position: absolute;
    top: 73px;
    left: 10px;
    right: 0;
    margin: 0 auto;
}
img.slide_icon_hover {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 73px;
    left: 10px;
    right: 0;
    margin: 0 auto;
}

section.enterprise-dapp .swiper-slide.swiper-slide-active img.slide_icon{
  display: none;
}
section.enterprise-dapp .swiper-slide.swiper-slide-active img.slide_icon_hover{
  display: block;
  margin:0 auto;
}
section.enterprise-dapp .swiper-slide img.slide_icon_hover{
  display: none;
}
.app_slide {
    background: #fff;
    height: 380px;
    border-radius: 15px;
    padding: 30px;
}
.app_content p{
    font-size: 13px;
}
.app_content span{
    font-size: 15px;
}
section.enterprise-dapp .swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-free-mode.mySwiper {
    height: 450px;
}
.enterprise_data{
    padding: 45px 30px 20px 30px;
    border: 2px solid #6c757d;
    margin-top: 30px;
    height: 220px;
    cursor: pointer;
}
.form_heading{
    font-size: 18px;
}
.enterprise_data:hover{
    border: 2px solid #fff;
}
.enterprise_data:hover h3.work_head, .enterprise_data:hover span.work_para{
    color:#0964F3 !important;
}
.enterprise_data h3{
    position: absolute;
    top: -20px;
    padding: 0 10px;
    background: #0e0c0c;
}
.enterprise_data p {
    font-size: 14px;
}

.work_main {
    background-color:#ECF3FC;
    padding: 60px;
}
.work_items p{
    font-size: 14px;
}
/* span.work_data[data-v-1a80270a] {
    position: absolute;
    border: 10px solid #0964f3;
    height: 100%;
    right: 0;
    left: 0;
    width: 55%;
    margin: auto;
    top: 0;
    bottom: 0;
} */
.unleash_data{
    background: transparent;
    height: 300px;
    border: 2px solid transparent;
    cursor:pointer;
    border-radius: 5px;

}

.unleash_data:hover {
    background: #D9EAFE;
    border: 2px solid #1774EF;
   
}
.unleash_data:hover img.unleash_icon_hover{
    display: block !important;
    margin: 0 auto;
}
.unleash_data:hover img.unleash_icon{
    display: none !important;
}
.industry_data {
    background: #222020;
    z-index: 9999;
    height: 245px;
}
.industry_data::before{
    content: "";
    position: absolute;
    border-width: 1px !important;
    border-top-style: solid;
    border-left-style: solid;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    top: 0;
    left: 0;
    border-right: transparent;
    -o-border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image-slice: 1;
    z-index: -1;
}

.enterprise_service .coin_bg {
        background: linear-gradient(90deg, rgba(3,36,88,1) 0%, rgba(0,0,0,1) 100%, rgba(0,212,255,0) 100%);
        border-radius: 15px;
    }

    section.enterprise_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #1D1B1B !important;
}
div#collapseOne, div#collapseTwo, div#collapseThree, div#collapseFour {
    background-color: #1d1b1b;
    border-radius: 0 0 15px 15px;
}


section.enterprise_faq .accordion-button:focus {
    box-shadow: unset !important;
}
section.enterprise_faq .accordion-collapse p{
  font-size: 14px !important;
}
section.enterprise_faq .accordion-item {
    color: var(--bs-accordion-color);
   
}
.accordion{
  position: relative;
}
.accordion:before {
    content: "";
    height: 96%;
    width: 2px;
    background: #464343;
    position: absolute;
    left: -27px;
}
div#collapseOne{
  position: relative;
}
div#collapseOne:after, div#collapseTwo:after, div#collapseThree:after, div#collapseFour:after {
  content: "";
    height: 156%;
    width: 1px;
    background: #fff;
    position: absolute;
    left: -27px;
    top: -57px;
}
div#collapseTwo:after{
  height: 36%;
  top: 79px;
}
div#collapseThree:after{
  height: 37%;
  top: 136px;
}
div#collapseFour:after{
  height: 41%;
  top: 205px;
}
section.enterprise_faq .accordion-item, section.enterprise_faq button.accordion-button.collapsed, section.enterprise_faq .accordion-button:not(.collapsed) {
    border: unset !important;
    color: #fff !important;
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
}
.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
section.enterprise_faq .accordion-button:not(.collapsed) {
    box-shadow: unset !important;
    background: #000;
}
section.enterprise_faq .accordion-item, section.enterprise_faq button.accordion-button.collapsed{
  background-color: #0e0c0c;
}
section.enterprise_faq .accordion-button::after {
    filter: invert(0.5);
    padding: 10px 10px;
    border: 1px solid #616161;
}


section.enterprise_innovation{
    background-image: url(@/assets/img/mask_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.selection_data{
    background: #282626;
}
img.active_icon {
    width: 35px;
    height: 35px;
}
img.hover_icon{
    width: 35px;
    height: 35px;
}
.selection_data p {
    font-size: 12px;
}
.selection_data:hover {
    background: #E9F2FF;
}
.selection_data:hover p {
   color:#0E0C0C !important;
}
.selection_data:hover img.hover_icon{
    display: block !important;
} 
.selection_data:hover img.active_icon{
    display: none !important;
} 
section.enterprise_technology video#coin_round {
    height: auto;
    width: 80%;
    mix-blend-mode: lighten;
}
section.enterprise-home {
    position: relative;
}

/* Media Query */
@media (max-width:767px) {
    section.enterprise-home .home_header {
    width: 100%;
}
.enterprise_data{
    padding: 30px 30px 20px 30px;
}
.enterprise_data p{
    font-size: 12px;
}
.enter_btn{
    text-align: center;
}
.innovative_img img {
    width: 70%;
}
}

@media (max-width:991px) {
.enter_circle img{
    display: none;
}
.enterprise_data span {
    font-size: 18px !important;
}
}

@media(min-width: 992px) and (max-width:1199px) {
.enterprise_data{
    padding: 25px;
}
}
@media(min-width: 320px) and (max-width:1200px) {
.work_main{
    padding: 15px;
}
}
section{
    padding: 60px 0;
}
section.enterprise-home{
    background-image: url(../assets/video/enterprise_banner.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
}
</style>




