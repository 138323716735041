<template>
  <div>
    <NavBar />
    <!-- ico home start -->
    <section class="home_ico p-0">

      <!-- <div class="main-banner">
        <video autoplay="" muted="" loop="" id="bg-video" playsinline>
        <source src="@/assets/video/ico_video.mp4" type="video/mp4" loading="lazy">
      </video>
      </div> -->
   
      <div class="container">
        <div class="video-overlay header-text">
          <div class="row">
            <div class="col-lg-6 ">
              <div class="caption">
                <Section_Heading text1="Start Developing" text2="Your ICO Right Away!"
                  text3="Make your ICO campaign a success and attract investors with our top-notch ICO development services.">
                </Section_Heading>
                <div class="main-button scroll-to-section">
                  <AllButton text="Get Started"></AllButton>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <!-- ico home end -->
    <!-- long term success start-->
    <section>
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6">
            <div>
              <img src="@/assets/Ico/about.png" alt="" class="img-fluid" loading="lazy">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ico_heading text-lg-end">
              <AllHeadings text3="ICO For " text4="Long-Term Success"></AllHeadings>
            </div>
            <div>
              <p class="text-white text-lg-end">A cryptocurrency and blockchain-based initial coin offering (ICO) is a
                new way for businesses to raise funds from investors who help them grow their businesses</p>
              <p class="text-white text-lg-end">Known for its proficiency in ICO development, LBM Blockchain Solutions
                assists businesses in launching successful ICOs. As part of the ICO development process, we continually
                assist with backend development, bounty management, and white paper creation.
              </p>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- long term success end -->

    <!-- Complete solutions slider start -->
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">
            <div class="ico_heading">
              <AllHeadings text3="Complete Solutions " text4="For ICO Development"></AllHeadings>
            </div>
          </div>
          <div class="col-12 pt-4">
            <IcoSlider_a></IcoSlider_a>
          </div>
        </div>
      </div>
    </section>
    <!-- Complete solutions slider end -->

    <!-- roadmap start-->
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 set_p ">
            <AllHeadings text3="ICO Capitalization : " text4="Your Roadmap"
              text5="LBM's ICO software development company will guide you through the process of choosing the services you need.">
            </AllHeadings>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">White Paper/ Light Paper Creation</h6>
              </div>

              <p class="text-white ms-2">Our specialized content writers forge flaky, pertinent, and emphatic content to
                convey your idea via the paper effectively.</p>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Pre-ICO Marketing</h6>
              </div>

              <p class="text-white ms-2">We deliver emphatic brand messaging and content across digital marketing
                channels like Steemit, Telegram, Reddit, Twitter, LinkedIn, and more.</p>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-3">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Wallet Development</h6>
              </div>

              <p class="text-white ms-2">We deliver a favorably secure and adaptable wallet infrastructure (backed with
                multi-sig and multi-cryptocurrency support) to send, receive and store coins and tokens.</p>
            </div>
          </div>
        </div>
        <div class="row d-none d-lg-block">
          <div class="col-12">
            <img src="../assets/Ico/roadmap_img.png" alt="" class="img-fluid" loading="lazy">
          </div>
        </div>
        <div class="row pt-3">
          <div class="offset-lg-1 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Inventiveness and Assurance</h6>
              </div>

              <p class="text-white ms-2">Our blockchain professionals evaluate your vision to validate your notion's
                desirability, feasibility, and viability.</p>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Landing Page Design</h6>
              </div>

              <p class="text-white ms-2">We compose an adorable and enlightening landing page to introduce your concept
                to the target audience. Our specialists take the dynamic upkeep of UI/UX throughout ICO website
                development.</p>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Token Development</h6>
              </div>

              <p class="text-white ms-2">Our blockchain engineers create a token on your chosen benchmark – ERC20 token
                development or any other measure.</p>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-2">
            <div class="roadmap_box">
              <div class="d-flex"> <span class="me-2"><img src="../assets/Ico/shape_star.png" alt="" class="img-fluid"
                    loading="lazy"></span>
                <h6 class="text-white">Post ICO Marketing</h6>
              </div>

              <p class="text-white ms-2">Using multi-channel marketing, we help you stand out from the crowd and capture
                the attention of investors. We cover everything from community building to PR, SEO, and social media
                marketing</p>
            </div>
          </div>
          <div class="col-lg-2">

          </div>
        </div>
      </div>
    </section>
    <!-- roadmap start-->




    <!-- A Mission-driven Approach slider start -->
    <section>
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 ">
            <div class="ico_heading p_none">
              <AllHeadings text3="A Mission - Driven Approach " text4="To ICO Development "></AllHeadings>
            </div>
          </div>
          <div class="col-lg-4">
            <p class="text-white">To fuel your ICO journey, we provide every service related to ICO under one roof as a
              trusted ICO development company.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pt-4">
            <IcoSlider_b></IcoSlider_b>
          </div>
        </div>
      </div>
    </section>
    <!-- A Mission-driven Approach slider end -->




    <!-- Industries We Serve start -->
    <section class="bg_white position-relative">

      <div class="container">

        <div class="row justify-content-center">
          <div class="col-lg-6 text-center">

            <BlackHeading head="Industries" span_text="We Serve"></BlackHeading>
          </div>
        </div>
        <div class="row set_z_index">

          <div class="col-lg-4 col-md-6" v-for="(data_a, index) in data_a" :key="index">
            <div class="industries_ico">
              <img :src="require(`@/assets/Ico/${data_a.images}`)" alt="" class="img-fluid normal_image" loading="lazy">

              <img :src="require(`@/assets/Ico/hover/${data_a.images1}`)" alt="" class="img-fluid hover_image"
                loading="lazy">

              <h5>{{ data_a.text }}</h5>
            </div>
          </div>
        </div>
      </div>


    </section>
    <!-- Industries We Serve end -->


    <!-- faq start-->
    <section id="faq">
      <div class="container">
        <div class="row align-items-center justify-content-center row_reverse">
          <div class="col-lg-7">
            <div>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      1. How To Launch Your Own ICO ?
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>To launch your own ICO in no time, conduct extensive research and choose the right ICO launch
                        services company. Such companies will offer both pre and POST-ICO launch services that are quick
                        and budget-friendly to get your ICO up to speed among existing competitors. Their services will
                        also gain credibility among potential global investors for your project.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      2. What is ICO Development in the Real World ?
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>ICO development in the real world works just like the standard ICO but is used by actual
                        physical endeavors. As ICOs are unregulated, traditional businesses adopt ICO to raise funds for
                        their business quickly and with reduced costs. Also, ICO helps lessen the hassles of real-world
                        regulatory frameworks, which are strict compared to a digital company.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      3. What Token Development Companies Do ?
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Token development companies help you build a credible and profitable ICO to raise funds for
                        your business. They take care of your ICO, from development to deployment of tokens for which
                        the services are real-quick and cost-effective. They also customize your tokens according to
                        your personal preferences.</p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      4. How Do I Determine Which ICOs Are Good?
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>Just make sure to do your homework. Because IOCs are barely regulated, you need to be way more
                        careful than you'd be when investing in an IPO. Read the white paper, research the team members,
                        and make sure they have a history in cryptocurrency.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 px-lg-4">
            <div class="row">
              <div class="col-12">
                <div class="ico_heading text-lg-end">
                  <AllHeadings text3="Frequently Asked " text4="Questions "></AllHeadings>
                </div>
              </div>
              <div class="col-12 text-center">

                <img src="../assets/Ico/girl.png" alt="" class="img-fluid" loading="lazy">
              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
    <!-- faq end-->

    <LbmFooter />
  </div>
</template>

  
<script>
import Section_Heading from '@/components/Section_Heading.vue';
import AllButton from '@/components/AllButton.vue'
import AllHeadings from '@/components/AllHeadings.vue';
import IcoSlider_a from '@/components/IcoSlider_a.vue';
import IcoSlider_b from '@/components/IcoSlider_b.vue';
import BlackHeading from '@/components/BlackHeading.vue';
import NavBar from '@/components/NavBar.vue';
import LbmFooter from '@/components/LbmFooter.vue';

export default {
  name: 'HomeView',
  components: {
    Section_Heading,
    AllButton,
    AllHeadings,
    IcoSlider_a,
    IcoSlider_b,
    BlackHeading,
    NavBar,
    LbmFooter
  },
  data() {
    return {

      data_a: [
        {
          images: "education.png",
          images1: "blue2.png",
          text: "Education"
        },
        {
          images: "finance.png",
          images1: "blue3.png",
          text: "Finance"
        },
        {
          images: "Retail.png",
          images1: "blue5.png",
          text: "Retail"
        },
        {
          images: "healthcare.png",
          images1: "blue4.png",
          text: "Healthcare"
        },
        {
          images: "accounting-&-Finance.png",
          images1: "blue1.png",
          text: "Accounting and Finance"
        },
        {
          images: "Voting.png",
          images1: "blue6.png",
          text: "Voting"
        }
      ]


    }
  }
}
</script>

  
<style scoped>
section {
  padding: 75px 0;
}

/* ico home css  start */



.caption {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}



.main-banner {
  position: relative;
}

/* ico home css  end */

/* long term success start */
.ico_heading span {
  display: block;
}

/* long term success end */

/* Industries We Serve start */
section.bg_white {
  background-color: white;
}

.industries_ico {
  text-align: center;
  padding: 25px 10px;
  margin: 10px 0;
  border-bottom: 5px solid transparent;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.industries_ico h5 {
  margin-top: 15px;
}

.industries_ico:hover {
  background-color: #F6FAFF;
  border-bottom: 5px solid #1774EF;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
}

.hover_image {
  display: none;
}

.industries_ico:hover .normal_image {
  display: none;
}

.industries_ico:hover .hover_image {
  display: block;
  margin: 0 auto;
}


.set_z_index {
  position: relative;
  z-index: 9999;
}


/* Industries We Serve end */
/* *****faq******* */
.accordion-item {
  color: white;
  background-color: #181616;
  border: none;
  margin: 15px 0;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../assets/Ico/plus.png) !important;

}

.accordion-button::after {
  background-image: url(../assets/Ico/minus.png);
}

.accordion-button {
  color: white;
  background-color: transparent;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}



/* *****faq end******* */


.set_p p {
  text-align: center;
}

/* media query */

section.home_ico {
  position: relative;
}
section.home_ico{
  background-image: url(../assets/video/ico_video.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
}
</style>
