<template>
    <div>
        <NavBar/>

        <section class="contact_home">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-8">
                        <PrivacyHead data="Contact" data2=" Us"/>
                    </div>
                </div>
            </div>
        </section>

        <section class="connection position-relative">
           
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 mb-5 mb-lg-0">
                        <div class="connection_main text-center text-lg-start">
                            <Section_Heading text1="Let's Get" text2=" Connected" text3="Fill up the form and our team will get back to you within24 hours."/>
                            <div class="contact_img mb-5">
                                <img src="@/assets/img/meeting.png" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12">
                        <div class="custom_border position-relative">
                        <div class="custom_border1 position-relative">
                            <span class="border-data"></span>
                            <span class="border-data1"></span>
                            <div class="contact_detail p-5">
                                <form>
                                    
                                    <div class="form-outline mb-4 text-start">
                                        <label class="form-label fw-light text-white" for="form2Example1">Your Name</label>
                                        <input type="text" name="text" id="form2Example1" class="form-control" placeholder="Name" required/>   
                                    </div>
                                    <div class="form-outline mb-4 text-start">
                                        <label class="form-label fw-light text-white" for="form2Example1">Mail</label>
                                        <input type="email" name="email" id="form2Example1" class="form-control" placeholder="&#9993; E-mail" required/>   
                                    </div>
                                    <span class="fw-light text-white"> Message</span>

                                    <div class="form-floating mt-2 mb-5">
                                        <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                        <label for="floatingTextarea2">Message</label>
                                    </div>

                                    <div class="text-center"><AllButton text="Send Message"/></div>
                                </form>
                            </div>
                        </div>
                        </div>
                        </div>
                </div>

                <div class="row justify-content-center align-items-center mt-5">
                    <div class="col-lg-4 col-md-4 mb-4 mb-lg-0" v-for="(location, index) in location_data" :key="index">
                        <div class="location-data d-flex justify-content-center align-items-center gap-3">
                            <span class="iconify" data-icon="carbon:location-filled"></span>
                            <p class="text-white m-0">{{location.para}}</p>
                        </div>
                    </div>
                </div>

            </div>               
        </section>

        <LbmFooter/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import PrivacyHead from '@/components/PrivacyHead.vue';
import Section_Heading from '@/components/Section_Heading.vue';
import AllButton from '@/components/AllButton.vue';

export default {
  name: 'ContactUs',
  components: {
    NavBar,
    PrivacyHead,
    LbmFooter,
    Section_Heading,
    AllButton
},
data(){
            return{
                location_data:[
                    {
                        para:"GR Tower, 3rd Floor, Phase 8-A, Industrial Area , Sahibzada Ajit Singh Nagar, (Mohali) Punjab 140308",
                    },
                    {
                        para:"7404 king George Blvd suite 200 surrey British Columbia V3W 1N6 Canada",
                    },
                    {
                        para:"Arakere Bannerghatta Road, Arekere, Bengalaru, karnataka - 560076",
                    },
                ]
            }
}
}
</script>

<style scoped>
    section.contact_home{
    background-image: url(@/assets/img/gallery_banner1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0;
    background-position: 100% 100%;
    margin-top: 75px;
    }
    .contact_detail{
        background-color: #333232;
    }
    .form-control {
    border: 1px solid #565555;
    background: transparent;
    padding: 12px;
    color:#fff !important;
}
.form-floating>label {
    color: #fff;
}

.form-control:focus {
    color: #fff !important;
    background-color: unset !important;
    border-color:#565555 !important;
    outline: unset !important;
    box-shadow: unset !important;
}
.custom_border:before, .custom_border:after {
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    width: 23%;
    height: 23%;
}
.custom_border:before {
    border-bottom: 0;
    top: -15px;
    left: -15px;
    border-right: transparent;
    border-color: #0964f3;
    border-image-slice: 1;
}
.custom_border:after {
    border-top: 0;
    bottom: -15px;
    right: -15px;
    border-left: transparent;
    border-color:#0964f3;
    border-image-slice: 1;
}
.border-data {
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    border-top: 3px solid #0964f3;
    border-right: 3px solid #0964f3;
    width: 23%;
    height: 23%;
    top: -15px;
    right: -15px;
    border-left: unset;
    border-bottom: unset;
}
.border-data1 {
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    border-bottom: 3px solid #0964f3;
    border-left: 3px solid #0964f3;
    width: 23%;
    height: 23%;
    bottom: -15px;
    left: -15px;
    border-right: unset;
    border-top: unset;
}
.location-data p{
    font-size: 13px;
}
svg.iconify.iconify--carbon {
    width: 55px;
    height: 55px;
    color: #0964f3;
}


@media(min-width: 320px) and (max-width:580px) {
    .custom_border:before, .custom_border:after, .border-data, .border-data1{
        display: none;
    }
}
section{
    padding: 40px 0;
}
</style>