<template>
<div class="">
    <div class="custom_btn"><button class="home_btn text-nowrap">{{text}}</button></div>
</div>
</template>
<script>
export default {
  name: 'AllButton',
  props:{
    text:String
  }
}
</script>
<style scoped>
.home_btn {
    background-image: url(@/assets/img/active.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 55px;
    color: #fff;
    border: unset !important;
    background-color: #000;
}
.home_btn:hover{
    background-image: url(@/assets/img/hover.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 55px;
    color:#186eea;
    background-color:#fff;
}
@media (max-width:991px) {
  .custom_btn {
    text-align: center;
}
.home_btn, .home_btn:hover {
    padding: 8px 16px;
}
}
</style>