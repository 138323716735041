<template>
<div>
  <NavBar />

  <section class="home p-0">
    <div class="main-banner">
      <video autoplay="" muted="" loop="" id="bg-video" playsinline>
        <source src="@/assets/video/home_banner.mp4" type="video/mp4" loading="lazy">
      </video>
    </div>
    <div class="video_caption">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-6">
          <div class="home_header">
            <Section_Heading text1="Turn Your Ideas Into" text2="An Enterprise" text3="Welcome the incredible of blockchain technology which is changing the    landscape of businesses across the globe.">
            </Section_Heading>
            <AllButton text="Get Started" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 col-md-6">
          <div>
            <AllHeadings text3="Blockchain Services" text4=" We Offer " />
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="about_data">
            <p class="text-white fs-6">We have a highly skilled team of blockchain developers
              who are experts in building a variety of applications with smart technology networks.</p>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-10 col-sm-8 mt-5">
          <OtherSlider />
        </div>
      </div>
    </div>
  </section>

  <section class="awards">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-3 col-md-12 col-sm-12">
          <AllHeadings text3="Awards &amp" text4=" Achievements" text5="We have a highly skilled team of blockchain developers who are experts in building a variety of applications with smart technology networks." />
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 text-end">
          <div class="two_circle"><img src="@/assets/img/circle.png" class="img-fluid" loading="lazy"></div>
        </div>
        <div class="col-lg-6">
          <HomeSlider></HomeSlider>
        </div>

      </div>
    </div>
  </section>

  <section class="platform">
    <div class="container">
      <div class="row align-items-center justify-content-center row_reverse">
        <div class="col-lg-6">
          <div class="text-center ">
            <video autoplay="" muted="" loop="" id="image_round" playsinline>
              <source src="@/assets/video/circle_round.mp4" type="video/mp4" loading="lazy">
            </video>
          </div>
        </div>
        <div class="col-lg-6">
          <AllHeadings text3="Platforms We Specialize" text4=" In When It Comes To Blockchain Development!" text5="We develop custom blockchain solutions and work on all significant blockchain and smart contract platforms." />
        </div>
      </div>
    </div>
  </section>

  <section class="enterprise">
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-6">
          <AllHeadings text3="Why" text4=" Choose Us?" text5="LBM Blockchain solutions are one of the best blockchain 
              development industries in the market." />
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-4">
        <div class="col-lg-4 col-md-6 mt-3" v-for="(statement, index) in card" :key="index">
          <div class="enterprise_items d-flex gap-3 align-items-center justify-content-center">
            <div>
              <h3 class="enterprise_head fw-bold blue_color">{{statement.data}}</h3>
            </div>
            <div><img src="@/assets/img/line.png" class="img-fluid" loading="lazy"></div>
            <div>
              <span class="text-white fs-5">{{statement.data1}}</span>
              <p class="text-muted enterprise_content mt-2">{{statement.data2}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="enterprise_block">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center">
          <AllHeadings text3="Enterprise Blockchain" text4=" Solutions" />
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-4">
        <div class="col-lg-12">

          <div class="first_slider">
            <PriseSlider />
          </div>
          <PriseSlider1 />

        </div>

      </div>
    </div>

  </section>

  <section class="work">
    <div class="container">
      <div class="row justify-content-center align-items-center ">
        <div class="col-lg-12">
          <div class="work_data ">
            <AllHeadings text3="ARE YOU READY TO JUMP INTO THE FUTURE WHERE SKY IS THE LIMIT ?" />
            <div class="row justify-content-center align-items-center flex-column-reverse flex-lg-row">
              <div class="col-lg-5 col-md-6 ">
                <div class="work_content">
                  <p class="text-white my-4">Get in touch with our support team and give wings to your business</p>
                  <form>
                    <div class="mb-lg-5 mb-3 d-lg-flex gap-lg-4 ">

                      <input type="text" class="form-control mb-lg-0 mb-3" id="exampleInputPassword1" placeholder="Name">
                      <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Phone">
                    </div>
                    <div class="mb-lg-5 mb-3">
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email-Id">

                    </div>
                    <AllButton text="Join Us" />
                  </form>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 text-end">
                <img class="img-fluid work_img" alt="Vue logo" src="@/assets/img/work.png" loading="lazy">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="coin_faq">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-12">
          <AllHeadings text3="Frequently Asked" text4=" Questions"/>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-lg-7">
          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
      <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Why is blockchain a trustworthy approach? 
      </button>
    </span>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Blockchain can be delegated on multiple bases. Its affinity with additional enterprise applications is due to its open-source nature. Its security. As it was inclined to online transactions, the developers have paid certain awareness to keep up the swiftness when it arrives to its security.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        What is an Ethereum consortium?
      </button>
    </span>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Ethereum is the Open Blockchain Forum for creating decentralized apps, aka DApps or Smart Contracts. Using this platform, you can build applications faster and more efficiently because it has large developers & supporters.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        How many different Blockchains are there?
      </button>
    </span>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Public blockchains, private blockchains, consortium blockchains, and hybrid blockchains are among the four types of blockchain networks.</p> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        What is a blockchain business model?
      </button>
    </span>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">A blockchain business model constructs a value-based ecosystem encompassing blockchain technology, fusing diverse specialized prototypes and business modules across the three layers.</p> 
      </div>
    </div>
    
  </div>
</div>
        </div>
      </div>
    </section>
  <section class="activity">
    <div class="container">
      <div class="row text-center align-items-center justify-content-center">
        <div class="col-lg-12">
          <AllHeadings text3="Activity" text4=" Videos" />
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-lg-12">
          <YoutubeSet></YoutubeSet>
        </div>
      </div>
    </div>
  </section>

  <section class="blog">
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-12">
          <AllHeadings text3="Our" text4=" Blogs" />
        </div>
      </div>
      <div class="row justify-content-center align-items-center gap-lg-4 mt-5">
        <div class="col-lg-3 col-md-4 text-center text-lg-start mb-3 mb-sm-start" v-for="(statement3, index) in card3" :key="index">
          <div class="main_blog">
            <div class="blog_img">
              <img :src="require(`@/assets/img/${statement3.img}.png`)" alt="" class="img-fluid" loading="lazy">
            </div>
            <div class="blog_content d-lg-flex gap-3 mt-3">
              <div class="blog_up d-inline-block text-center">
                <span class="text-white  fw-semibold">{{statement3.data4 }}<br class="d-none d-lg-block">{{ statement3.data5 }}
                </span>
              </div>
              <div class="blog_data">
                <span class="blue_color d-block fs-6 fw-bold">{{statement3.data1}}</span>
                <span class="text-white fs-6">{{statement3.data2}}</span>
                <p class="text-white mt-2">{{statement3.data3}}</p>
                <a href="#">Read More...</a>
              </div>
            </div>
          </div>
        </div>
        <div class="custome_width d-flex justify-content-center">
          <AllButton text="View All" />
        </div>
      </div>
    </div>
  </section>

<ModalPage></ModalPage>



  <LbmFooter />
</div>
</template>

<script>


// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import LbmFooter from '@/components/LbmFooter.vue';
import AllButton from '@/components/AllButton.vue';
import Section_Heading from '@/components/Section_Heading.vue';
import AllHeadings from '@/components/AllHeadings.vue';
import OtherSlider from '../components/OtherSlider.vue';
import PriseSlider from '@/components/PriseSlider.vue';
import PriseSlider1 from '@/components/PriseSlider1.vue';
import YoutubeSet from '../components/YoutubeSet.vue';
import HomeSlider from '@/components/HomeSlider.vue';
import ModalPage from '@/components/ModalPage.vue';

export default {
  name: 'HomeView',
  components: {
    NavBar,
    LbmFooter,
    AllButton,
    Section_Heading,
    AllHeadings,
    OtherSlider,
    PriseSlider,
    PriseSlider1,
    YoutubeSet,
    HomeSlider,
    ModalPage
  },
  // setup() {
  //   return {
  //     v$: useVuelidate(),
  //   };
  // },
  // mounted() {
  //   this.my_modal = new Modal(this.$refs.exampleModal);
  //   this.my_modal.show();
  // },
  
  
  // methods: {
  //   countryChange(selected_country) {
  //     console.log("HERE");
  //     this.country = selected_country;
  //     this.country.number = selected_country.number;
  //   },

  //   showModal() {
  //     this.my_modal.show();
  //   },

  //   hideModal() {
  //     this.my_modal.hide();
  //   },
  //   resetForm() {
  //     this.country = {
  //       number: "+91",
  //       flag: "🇮🇳",
  //       code: "IN",
  //     };
  //     this.phonenumber = "";
  //     this.name = "";
  //     this.v$.$reset(); // reset validation
  //   },

  //   async formSubmit() {
  //     const result = await this.v$.$validate();
  //     if (!result) {
  //       // notify user form is invalid
  //       return;
  //     }

  //     let form_data = {
  //       leaddata: {
  //         name: this.name,
  //         phonenumber: this.phonenumber,
  //         country_code: this.country.number,
  //         status: "2",
  //       },
  //     };
  //     this.loading = true;

  //     let response = await ApiClass.postRequest(
  //       "Leads/addlead",
  //       false,
  //       form_data,
  //       null,
  //       null,
  //       "https://login.lbmsolutions.in/"
  //     );

  //     this.loading = false;
  //     if (response?.data) {
  //       this.hideModal();
  //       if (response.data.status == "success") {
  //         this.resetForm();
  //         this.$swal({
  //           position: "center",
  //           icon: "success",
  //           title: response.data.msg,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });

  //         return;
  //       }

  //       if (response.data.status == "error") {
  //         this.$swal({
  //           position: "center",
  //           icon: "error",
  //           title: response.data.msg,
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });

  //         return;
  //       }
  //     }

  //     this.loading = false;
  //   },
  // },
  // validations() {
  //   return {
  //     name: {
  //       required: helpers.withMessage("Name is Required", required),
  //     },
  //     phonenumber: {
  //       required: helpers.withMessage("Number is Required", required),
  //     },
  //   };
  // },
  data() {
    return {
      // country: {
      //   number: "+91",
      //   flag: "🇮🇳",
      //   code: "IN",
      // },
      // CountrySelect: CountryCodejson.CountryCode,
      // ACountries: DCountries.sort(
      //   (a, b) =>
      //     parseFloat(a.number.substring(1)) - parseFloat(b.number.substring(1))
      // ),
      // name: "",
      // phonenumber: "",
      // loading: false,
      // my_timeout: null,
      // my_modal: null,
      card: [{
          data: "01",
          data1: "Prototype And Assess Blockchain Opportunities",
          data2: "Develop prototypes based on our Design Thinking-based framework for evaluating blockchain opportunities.",
        },

        {
          data: "02",
          data1: "Developing A Blockchain Solution and Testing It",
          data2: "Test for performance, security, and scale of the blockchain solution on the chosen platform with technology and process limitations.",
        },

        {
          data: "03",
          data1: "Create a blockchain network and implement it",
          data2: "A blockchain solution will be implemented across the network and updates and changes will be authorized.",
        },

        {
          data: "04",
          data1: "Assess Blockchain Solution by piloting use-cases",
          data2: "Propose blockchain use cases, design them, and implement them. Evaluate the solution with the client.",
        },

        {
          data: "05",
          data1: "Networking and integrating partners",
          data2: "Integrate partner systems with the blockchain network and onboard partner systems. Facilitate consensus-building and governance.",
        }
      ],
      card1: [{
          img: "tech1",
          data: "My SQL"
        },
        {
          img: "tech2",
          data: "HTML5"
        },
        {
          img: "tech3",
          data: "JQUERY"
        },
        {
          img: "tech4",
          data: "Node JS"
        },
        {
          img: "tech5",
          data: "SQLITE"
        },
      ],
      card2: [{
          img: "tech6",
          data: "Android 3"
        },
        {
          img: "tech7",
          data: "CSS 3"
        },
        {
          img: "tech8",
          data: "Bootstrap"
        },
        {
          img: "tech9",
          data: "MongoDB",
        },
        {
          img: "tech10",
          data: "PHP"
        },
      ],
      card3: [{
          img: "blog1",
          data4: "01 ",
          data5: "Jan",
          data1: "News from our blog",
          data2: "Lorem Ipsum Dolor Sit.",
          data3: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam  dolor sit amet, consetetur sadipscing elit"
        },
        {
          img: "blog2",
          data4: "02 ",
          data5: "Jan",
          data1: "News from our blog",
          data2: "Lorem Ipsum Dolor Sit.",
          data3: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam  dolor sit amet, consetetur sadipscing elit"
        },
        {
          img: "blog3",
          data4: "03 ",
          data5: "Jan",
          data1: "News from our blog",
          data2: "Lorem Ipsum Dolor Sit.",
          data3: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam  dolor sit amet, consetetur sadipscing elit"
        }
      ]

    }

  }

}
</script>

<style scoped>
.home_header {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

section.home .main-banner {
  position: relative;
}

section.home #bg-video {
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

section.home {
  position: relative;
}

section.home::before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background-color: #000000b3;
  z-index: 9;
}

.work_data {
  background-image: url(@/assets/img/layer.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}

.form-control {
  background-color: #122B53 !important;
  border: 1px solid #ced4da3d !important;
  color: #f0f0f0 !important;
}

.form-control:focus {
  box-shadow: unset !important;
}

.blog_up[data-v-9ea40744] {
  background-color: #1D1B1B;
  height: fit-content;
  padding: 4px 15px;
  border-radius: 8px;
  border: 1px solid #cccccc24;
}

.blog_content p {
  font-size: 12px;
  overflow: hidden;
  width: 197px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

.custom_data {
  gap: 90px;
}

.two_circle {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.work_data {
  padding: 50px 30px;
}

video#image_round {
  height: auto;
  width: 80%;
  mix-blend-mode: lighten;
}
section{
  padding: 60px 0;
}
/* Media Query */

@media(min-width: 320px) and (max-width: 1400px) {
  section {
    padding-top: 50px !important;
    padding-bottom: unset !important;
  }
  section.home.p-0 {
    padding-top: 0px !important;
}
}

@media(min-width: 320px) and (max-width: 991px) {
  .home_header {
    width: 100%;
    padding-top: 100px;
  }

  section.home #bg-video[data-v-9ea40744] {

    min-height: 60vh;
  }

 

  .row_reverse {
    flex-direction: column-reverse;
  }
}

@media (max-width:991px) {
  .about_bg {
    display: none !important;
  }

  .custom_data {
    gap: 45px !important;
  }
}

@media(min-width: 992px) and (max-width:1199px) {
  .custom_data {
    gap: 40px;
  }
}

@media (max-width:767px) {
  .about_data p {
    font-size: 15px !important;
    text-align: center;
  }

  .enterprise_items span {
    font-size: 16px !important;
  }

  .enterprise_items p {
    font-size: 14px !important;
  }

  .work_content p {
    font-size: 12px;
    text-align: center;
  }

  section.work form,
  .blog_img {
    text-align: center;
  }
}

@media (min-width:1400px) {
  .two_circle {
    display: block;
  }
}

 /* Modal Css Start  */

.modal {
  z-index: 9999;
}

.talk_text{
    color: #000;
    text-align: center;
    margin: 0;
    padding-top: 25px;
    letter-spacing: 2px;
    font-weight: 700;
}
.modal-body h5 {
  color: #000 !important;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.modal-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #f7f7f7;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    position: relative;
    box-shadow: 0 0 15px rgb(255 255 255 / 15%);
}

.input-group {
    box-shadow: 1px 1px 29px #0000001c !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0;
  color: var(--white);
  background: var(--talk-text);
  border: 0;
  border-radius: 0.25rem;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.btn-close:focus {
  box-shadow: none;
}

.input-group .form-control {
    padding-left: 10px;
    border-radius: 0;
    background-color: #fff !important;
    color: #000 !important;
    border: unset !important;
    border-left: 1px solid #ccc !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
input#number-field {
    border-left: unset !important;
}

.input-group-text:before {
  position: absolute;
  content: "";
  top: 0;
  left: 80px;
  right: 0;
  margin: auto;
  width: 1px;
  z-index: 999;
  height: 25px;

  bottom: 0;
}

.input-group-text {
  position: relative;
  background-color: #0964f3;
  color: #000;
  border: none;
}
button.btn-close{
    position: absolute;
    right: -7px;
    top: -10px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 50px #0964f3;
z-index: 99;
}
.btn-close:hover {
   opacity: unset !important;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  box-shadow: none;
}

.form-control:focus {
  color: var(--white);
}

.modal_logo {
  position: relative;
}

/* .modal_logo::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 275px;
        background-repeat: no-repeat;
        height: 42px;
        background-image: url(@/assets/img/award.jpg);
        z-index: -1;
    } */

.btn.btn-primary {
  background-color: #0964f3 !important;
  border-color: #0964f3 !important;
  color: #fff !important;
  min-width: 250px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 17px;
  text-transform: capitalize;
  transition: all 0.5s ease;
}
button.btn-close img {
    filter: invert(1);
}

.btn.btn-primary:focus {
  box-shadow: none;
}

.mobile-box {
  transform: translate(140px, 0);
}

.row.inner-row {
  transform: translate(118px, 0);
}

.country-drop {
  padding: 0;
  padding-left: 0px;
  padding-left: 6px;
}

.input-group-text.country-drop {
  position: relative;
}

.input-group-text.country-drop::before {
  display: none !important;
}

.input-group .input-group-text.country-drop {
  padding: 0;
  background-color: #fff !important;
}

.input-group .input-group-text.country-drop .dropdown {
  padding: 0;
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
}

.input-group .input-group-text.country-drop .dropdown #dropdownMenuButton1 {
  background-color: #fff !important;
  color: var(--white);
  border: none;
}

.input-group
  .input-group-text.country-drop
  .dropdown
  #dropdownMenuButton1:focus {
  box-shadow: none;
}

.dropdown-menu {
  max-height: 245px !important;
  overflow-y: scroll;
  z-index: 1;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .row.inner-row {
    transform: none;
  }

  .mobile-box {
    transform: none;
    margin-bottom: 30px;
  }
}

.set_colour {
  background-color: #fff !important;
  border-radius: unset !important;
  border-left: 1px solid #ccc !important;
}
.modal_logo img{
  position: absolute;
  top: -233px;
  width: 50%;
}

/* / model _box contant  */
.model_contant {
  transform: unset !important;
}


img.model_img.img-fluid{
    position: absolute !important;
    top: -226px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 0;
}
.modal.fade .modal-dialog {
  transform: translate(0, 50px)  !important;
}
.input-group .input-group-text.country-drop .dropdown #dropdownMenuButton1 {
    color: #000 !important;
}
svg.iconify {
    color: #fff;
}
 /* model _box contant / */

 /* Modal Css End / */
</style>
