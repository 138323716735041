<template>

<div>

<li class="my-4"><span class="d-flex align-items-center "><span><img src="@/assets/images/rhoumbas.png" alt="" class="img-fluid me-2"></span><p class="text-white mb-0 fs-5 ms-2 fw-bold">{{text_a}}</p></span>
<p class="text-muted fs-6 fs_500">{{text_b}}</p>



</li>




</div>


</template>

<script>
export default{
  name:"ListDetail",
  props:{
text_a:String,
text_b:String
  },
  

}
</script>

<style scoped>
li{
  list-style: none;
}
</style>