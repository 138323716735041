<template>
<swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :breakpoints="{
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      '1024': {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    }" :modules="modules" class="mySwiper">

  <swiper-slide>
    <div class="parent_div text-center">
      <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_a.png" alt="" class="img-fluid">
      </div>

    </div>
    <span class="fw-bold mt-3">White paper creation<br/>
      and design</span>
    </div>

  </swiper-slide>

  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_b.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">Lisiting<br/> Services</span>
    </div>
  </div>
  </swiper-slide>

  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_c.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3"> Landing page<br/> design</span>
    </div>
  </div>
  </swiper-slide>

  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_d.png" alt="" class="img-fluid">
      </div>

    </div>
    <span class="fw-bold mt-3">Light paper creation<br/> and design</span>
    </div>
  </swiper-slide>

  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_e.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">ICO fundraising <br/>dashboard</span>
    </div>
  </div>

  </swiper-slide>

  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_f.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">Token or coin<br/> development</span>
    </div>
  </div>
  </swiper-slide>



  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_g.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">Press <br/>release</span>
    </div>
  </div>
  </swiper-slide>
  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_h.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">Multi-channel <br/>marketing</span>
    </div>
  </div>
  </swiper-slide>
  <swiper-slide>
    <div class="parent_div text-center">
    <div class="main_div">
      <div>
        <img src="@/assets/Ico/slider1_i.png" alt="" class="img-fluid">
      </div>
      <span class="fw-bold mt-3">Bounty <br/> management</span>
    </div>
  </div>
  </swiper-slide>

</swiper>
</template>

<script>
  // Import Swiper Vue.js components
  import {
    Swiper,
    SwiperSlide
  } from "swiper/vue";
  
  // Import Swiper styles
  import "swiper/css";
  
  import "swiper/css/pagination";
  
  // import required modules
  import {
    Autoplay,
    Pagination
  } from "swiper";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Pagination, Autoplay],
      };
    },
  };
  </script>
<style scoped>
  .parent_div {
    background-color: #FFFFFF;
    padding: 30px 10px;
    border-radius: 5px;
}
</style>