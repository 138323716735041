<template>
    <div>
        <NavBar/>
        <section class="coin_home ">
            <!-- <div class="main-banner">
                <video autoplay="" muted="" loop="" id="bg-video" playsinline>
                    <source src="@/assets/video/coin_banner.mp4" type="video/mp4" loading="lazy">
                </video>
            </div> -->
            <div class="video_caption">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-7">
                        <div class="home_header">
                        <Section_Heading text1="Pioneered Digital Currencies For The " text2="Crypto Market!" text3="Cryptocurrency Development services offered by LBM Blockchain Solutions are enhanced with breakthrough security features that allow cutting-edge prospects. With secure and transparent tokenization, the investment pattern has changed.">
                        </Section_Heading>
                        <AllButton text="Explore More"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="crypto_coin">
            <div class="container">
                <div class="row align-items-center coin_bg p-lg-5 p-5 flex-column-reverse flex-lg-row">
                        <div class="col-lg-6 col-md-12">
                            <AllHeadings text3="Being Aware Of" text4=" Crypto Coins" text5="CryptoCoins are a decentralized digital currency that can be used as a medium of exchange. Based on Blockchain Technology, CryptoCoins are 100% decentralized, making them immune to a central authority, which builds trust, as no intermediary can manipulate your transactions. "/>
                            <p class="fs-6 text-white crypto_coin_content">Get a comprehensive insight on crypto CoinsICO development with our experts, as we are the leading Crypto Coin Development Services Provider.</p>
                            <AllButton text="Explore More"/>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="text-lg-end text-center mb-5 mb-lg-0">
                                <img src="@/assets/img/coin.png" class="img-fluid" loading="lazy">
                            </div>
                        </div>

                    
                </div>
            </div>
        </section>

        <section class="traits">
            <div class="container">
                <div class="row align-items-center justify-content-center text-center">
                    <div class="col-lg-12">
                        <AllHeadings text3="Traits" text4=" Of Crypto Coins"/>
                    </div>
                </div>
                <div class="row align-items-center mt-lg-5 mt-4">
                    <div class="col-lg-3 col-md-6" v-for="(data_t, index) in traits_data" :key="index">
                        <div class="traits_coin mb-4 mb-lg-0">
                            <div class="sub_div">
                                <div class="text-center">
                                    <img :src="require(`@/assets/playearn_elements/svg_files/${data_t.img}.svg`)" alt="" class="img-fluid svg_icon p-3" loading="lazy"/>
                                    <img :src="require(`@/assets/playearn_elements/svg_files/${data_t.img1}.svg`)" alt="" class="img-fluid svg_hover  p-3" loading="lazy"/>
                                </div>
                                <div class="text-center mt-3">
                                    <span class="fs-5 text-white fw-semibold">{{
                                   data_t.head
                                    }}</span>
                                    <p class="text-white mt-3">{{data_t.para }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section class="coin_invest">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="coin_content position-relative d-flex align-items-center justify-content-between flex-wrap">
                        <div class="col-lg-4 col-md-5 col-sm-3">
                            <AllHeadings text3="Your Investment Is Just A" text4=" Click Away!"/>
                
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-6">
                            <div class="text-start coin-circle_img"><img src="@/assets/img/coin_circle.png" class="img-fluid" loading="lazy"></div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6" v-for="(invest, index) in invest_data" :key="index">
                            <div class="invest_data">
                                <h1 class="blue_color fw-bold">{{invest.head}}</h1>
                                <span class="fs-6 text-white fw-semibold">{{invest.text}}</span>
                                <p class="text-white fw-light">{{invest.para}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="coin_types">
            <div class="container">
                <div class="row text-center align-items-center justify-content-center">
                    <div class="col-lg-6">
                        <AllHeadings text3="Crypto Coins Of Different" text4=" Types" />
                    </div>
                </div>
                <div class="row align-items-center justify-content-center mt-lg-5 mt-4">
                    <div class="col-10 ">
                        <div class="coin_type_main d-flex justify-content-center flex-wrap gap-4 ">
                            <div class="typemain_content d-flex  align-items-center gap-2" v-for="(coin, index) in coin_data" :key="index">
                                <div class="cointype_img"><img :src="require(`@/assets/img/${coin.img}.png`)" alt="" loading="lazy"></div>
                                <span class="text-white">{{coin.head}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="coin_work position-relative">
            <div class="container">
                <div class="work_main">
           
              
                <div class="row align-items-center justify-content-center flex-column-reverse flex-lg-row set_z_index">
                    <div class="col-lg-7 col-md-12">
                        <div class="work_items">
                            <BlackHeading head="How Do Crypto" span_text=" Wallets Work?" />
                            <p class="text-black">After buying your coins, you can store them on a physical medium/service. You can customize your digital wallets at your convenience by collaborating with our Cryptocurrency Coin Development Services. Some wallets are made for single transactions, while others are designed for multiple usages. These wallets fall into the following categories:</p>
                            <ul>
                                <li>
                                    <img src="@/assets/playearn_elements/svg_files/star.svg" class="img-fluid pe-2" loading="lazy">
                                    <span class="fw-bold fs-6">Online Wallets</span>
                                    <p class="ps-4">Setup is the fastest, but there is also the least security.</p>
                                </li>
                                <li> 
                                    <img src="@/assets/playearn_elements/svg_files/star.svg" class="img-fluid pe-2" loading="lazy">
                                    <span class="fw-bold fs-6">Software Wallets</span>
                                    <p class="ps-4">Data security is better because it's in the form of a mobile app.</p>
                                </li>
                                <li>
                                    <img src="@/assets/playearn_elements/svg_files/star.svg" class="img-fluid pe-2" loading="lazy">
                                    <span class="fw-bold fs-6">Hardware Wallets</span>
                                    <p class="ps-4">As a portable device, it is the safest and is linked to a cryptographic key.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12">
                        <div><img src="@/assets/img/coin_work.png" class="img-fluid" loading="lazy"></div>
                    </div>
                </div>
            </div>
            </div>
        </section>


        <section class="coin_faq">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-12">
          <AllHeadings text3="Frequently Asked" text4=" Questions"/>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-5">
        <div class="col-lg-7">
          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingOne">
      <button class="accordion-button mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        What are Crypto Coins?
      </button>
    </span>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Crypto Coins are merely a digital medium of trade for secured transactions.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingTwo">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        How to get Crypto Coins?
      </button>
    </span>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Individuals can start buying Crypto Coins from different crypto exchanges. For successful transactions, you can consistently look up LBM's Cryptocurrency Coin Development Services.</p> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingThree">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        How well can Cryptocurrencies be used?
      </button>
    </span>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">Cryptocurrencies are widely used because they are based on blockchain technologies. Cryptocurrencies assist in rapid and efficient trades globally. Blockchain enables improved security with translucence, immutability, and unspecified users for a thriving business.</p> 
      </div>
    </div>
    
  </div>
  <div class="accordion-item">
    <span class="accordion-header fs-5 fw-bold text-white" id="headingFour">
      <button class="accordion-button mb-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        What is a stablecoin?
      </button>
    </span>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p class="text-white">A stablecoin, like gold or fiat currency, is a reserve asset. It strives to lessen the volatility of cryptocurrencies corresponding to Bitcoin, operating as a bridge between the two worlds.</p> 
      </div>
    </div>
    
  </div>
</div>
        </div>
      </div>
    </section>

        <LbmFooter/>
    </div>
</template>
<script>
    import NavBar from '@/components/NavBar.vue'
    import Section_Heading from '@/components/Section_Heading.vue';
    import AllButton from '@/components/AllButton.vue';
    import AllHeadings from '@/components/AllHeadings.vue';
    import LbmFooter from '@/components/LbmFooter.vue'
import BlackHeading from '@/components/BlackHeading.vue';
    export default {
  name: 'MarKeting',
  components: {
    NavBar,
    Section_Heading,
    AllButton,
    AllHeadings,
    LbmFooter,
    BlackHeading
},
data() {
    return {
        traits_data: [
        {
          img: "virtual",
          img1: "virtual_h",
          head: "100% Virtual",
          para: "Blockchain is used to facilitate the entire cryptographic transaction.",
        },
        {
          img: "minimal",
          img1: "minimal_h",
          head: "Minimal Fee",
          para: "The output is maximized with the minor input, thereby increasing the feasibility.",

        },
        {
          img: "decenter",
          img1: "decenter_h",
          head: "Fully Decentralized",
          para: "Your transactions remain completely secure since there is no intermediary involved.",

        },
        {
          img: "location",
          img1: "location_h",
          head: "Location Independent",
          para: "You don't need to worry about your location in the Crypto World.",

        },
      ],
      invest_data: [
      {
          head: "01.",
          text:"Create an account now",
          para: "Open your transaction account.",

        },
        {
          head: "02.",
          text:"Raise Funds",
          para: "Deposit your funds into the account.",

        },
        {
          head: "03.",
          text:"Start Buying",
          para: "Get started with our experts by choosing a suitable deal.",

        },
      ],
      coin_data:[
                    {
                      img:"coin1",
                      head:"Bitcoin" 
                    },
                    {
                      img:"coin2",
                      head:"Ethereum Classic" 
                    },
                    {
                      img:"coin3",
                      head:"Polkadot" 
                    },
                    {
                      img:"coin4",
                      head:"Bitcoin Cash" 
                    },
                    {
                      img:"coin5",
                      head:"Tron" 
                    },
                    {
                      img:"coin6",
                      head:"Stellar-Lumen" 
                    },
                    {
                      img:"coin7",
                      head:"ZCash" 
                    },
                    {
                      img:"coin8",
                      head:"Ethereum Classic" 
                    },
                    {
                      img:"coin9",
                      head:"Litecoin", 
                    },
                  ],

    };
  },
    }
</script>

<style scoped>
    section.coin_home .home_header{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 68%;
    }
    section.coin_home .main-banner {
        position: relative;
    }
    
    section.coin_home #bg-video {
        min-width: 100%;
        min-height: 100vh;
        max-width: 100%;
        max-height: 100vh;
        object-fit: cover;
        z-index: -1;
    }
    section.coin_home .video_caption{
        position: absolute;
        background-color:#000000b3;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }


    .coin_bg {
        background: #201E1E;
        border-radius: 15px;
    }
    .traits_coin {
    height: 290px;
    padding: 15px;
    background: #222325;
    position: relative;
    }
    .svg_icon{
        width: 100px;
        height: 100px;
    }
    .traits_coin:after {
    content: "";
    position: absolute;
    border-width: 1px !important;
    border-top-style: solid;
    border-left-style: solid;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    top: 0;
    left: 0;
    border-right: transparent;
    -o-border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image-slice: 1;
    display: none;
}
.svg_hover {
    display: none;
}
.traits_coin:hover .svg_icon{
    display: none;
}
.traits_coin:hover .svg_hover {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
}
.traits_coin:hover::after{
    display: block;
}
.invest_data h1{
    font-size: 77px !important;
}
section.coin_invest{
    background:url(@/assets/img/mask_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size:100% 100%;
}
.typemain_content{
    background: #222020;
    padding: 8px;
    position: relative;
}
.typemain_content:after {
    content: "";
    position: absolute;
    border-width: 1px !important;
    border-top-style: solid;
    border-left-style: solid;
    width: 100%;
    height: 100%;
    border-bottom: 0;
    top: 0;
    left: 0;
    border-right: transparent;
    -o-border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image: linear-gradient(135deg, #0964f2, #00000000, #00000000);
    border-image-slice: 1;
}

.work_main {
    background-color:#ECF3FC;
    padding: 60px 140px;
}
.work_items p{
    font-size: 14px;
}



section{
  padding: 60px 0;
}

/* Media Query */

@media(min-width: 320px) and (max-width: 767px) {
  section.coin_home .home_header{
    width: 100%;
}
  p.crypto_coin_content{
  font-size: 14px !important;
  text-align: center;
}
section.coin_invest {
    display: none;
}
.work_items p{
    font-size: 10px;
    text-align: center;
}
ul li p{
  text-align: start !important;
}
.work_main{
    padding: 12px;
}
.coin_type_main{
    flex-wrap: unset !important;
    overflow-x: scroll;
}
.cointype_img img {
    width: 70px;
}
}
@media(min-width: 768px) and (max-width: 991px) {
.work_main{
    padding: 45px;
}
.invest_data h1{
    font-size: 60px !important;
}
.coin-circle_img img {
    max-width: 33%;
}
}
@media(min-width: 992px) and (max-width: 1199px) {
  .traits_coin{
    height: 260px !important;
}
.sub_div span {
    font-size: 16px !important;
}
.sub_div p {
    font-size: 13px !important;
}
.invest_data h1{
    font-size: 55px !important;
}
}
@media(min-width: 1200px) and (max-width: 1399px) {
  .invest_data h1{
    font-size: 65px !important;
}
}
@media(min-width: 768px) and (max-width: 1199px) {
.typemain_content {
    width: 220px;
    
}
.work_main{
    padding: 50px;
}
}
 

.set_z_index{
  position: relative;
  z-index: 9;
}
section.coin_home {
    position: relative;
}
section.coin_home{
  background-image: url(../assets/video/coin_banner.png);
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
}
  </style>