<template>
  <swiper :slidesPerView="1" :spaceBetween="10" :loop="true" :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }" :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }" :modules="modules" class="mySwiper">
      <swiper-slide v-for="(prise, index) in prise_data" :key="index">
          <div class="prise_slide">
        <div class="text-center"><img :src="require('@/assets/images/icons/'+prise.img+'.png')" alt="" class="img-fluid aaa"></div>
        <div class="text-center prise_head mt-3">
          <span class="fs-5 text-white fw-bold">{{prise.head}}</span>
          <p class="text-white mt-3">{{prise.para}}</p>
        </div>
        </div>
      </swiper-slide>
    </swiper>

  
  </template>
  <style scoped >
  .prise_slide:hover{
      background-color: #191d25;
      border-bottom: 4px solid #0964F3;
      padding: 45px 10px;
  
  }
  .prise_slide{
      border-bottom: 4px solid transparent;
      height:390px;
      padding: 45px 10px;
  }
  .prise_head p{
      font-size:14px;
  }
  </style>
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  
  
  import "swiper/css/free-mode";
  import "swiper/css/pagination";
  
  
  
  // import required modules
  import {Autoplay, FreeMode, Pagination, Navigation } from "swiper";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Autoplay, FreeMode, Pagination, Navigation],
      };
    },
    data(){
              return{
                  prise_data:[
                      {
                          img:"1",
                          head:"DeFi Smart Contract Consulting",
                          para:"Among the components of the NFT marketplace's growth is its streamlined DeFi smart contract creation process. This ensures a 100% secure transaction completion in a matter of seconds."
                      },
                      {
                          img:"2",
                          head:"DeFi Wallet Development",
                          para:"Your funds are completely under your control without the intervention of a third party. We provide our patrons with customized DeFi wallets with top-notch security features without compromising data security."
                      },
                      {
                          img:"3",
                          head:"DeFi ICO Development",
                          para:"Token creation, community management, and market analysis are a few of the services we offer for ICO development."
                      },
                      {
                          img:"4",
                          head:"Non-Fungible Development",
                          para:"For platforms with huge trading volumes, our Non-Fungible token development services are a perfect fit."
                      },



                      {
                          img:"5",
                          head:"DeFi Marketing Services",
                          para:"Defi's marketing services will help you gain a stronghold in the ecosystem. From drafting white papers to producing video content, we offer comprehensive DeFi marketing services."
                      },
                      {
                          img:"6",
                          head:"DeFi Tokenization Development",
                          para:"Designed to provide users with the best tokens in the industry, such as ERC20, ERC720, and ERC-223, you can also set your own rules on your platform so they can covenant their assets to the most profitable assets."
                      },
                    
                      
                  ]
              }
    }
  };
  </script>
  